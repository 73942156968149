import React, { useMemo, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import styled from 'styled-components';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import PhotoIcon from '@material-ui/icons/Photo';
import IconButton from '@material-ui/core/IconButton';
import { UserSquareIcon } from '../../icons';
import { AddIconButton } from './Button';
import ClearIcon from '@mui/icons-material/Clear';

type Props = {
  avatar: string,
  loading?: boolean,
  onClick: () => {},
  onClickDelete: () => void
  isDelete: boolean
};

export const EditableAvatar: React.VFC<Props> = (props) => {
  const [hover, setHover] = useState(false);
  const avatar = props.isDelete ? "" : props.avatar;
  const loading = props.loading ? props.loading : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <Div>
      {
        avatar ?
          <AvatarWrap
          style={{ position: 'relative', width: 'auto', height: 'auto' }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          >
          {hover && <IconButton
            size="small"
            style={{backgroundColor: "black" ,position: 'absolute', zIndex: 1, left: "40px", top: "-10px"}} onClick={()=>props.onClickDelete()}>
            <ClearIcon style={{color: "#FFFFFF"}}/>
          </IconButton>}
          {
          loading ?
            <StyledAvatar><CircularProgress /></StyledAvatar>
              :
            <StyledAvatar　src={avatar}　/>
          }
          </AvatarWrap>
        :
          <AvatarWrap
            style={{ position: 'relative', width: 'auto', height: 'auto' }}
          >
            {loading ? <StyledAvatar><CircularProgress /> </StyledAvatar> : <div style={{ position: 'absolute', left: '-57px'}}><UserSquareIcon/></div>}
          </AvatarWrap>
        }
      <div style={{marginTop: "120px"}}>
      <AddIconButton
        onClick={onClick}
        disabled={loading}
        text={'写真を追加する'}
      />
        </div>
    </Div>
  );
}

type EditableThumbnailProps = {
  title: string,
  avatar: string,
  required?: boolean,
  loading?: boolean,
  onClick: () => {},
  onClickDelete: () => void
  isDeleteIcon: boolean
};
export const EditableThumbnail: React.VFC<EditableThumbnailProps> = (props) => {
  const title = props.title;
  const avatar = props.avatar;
  const required = props.required ? props.required : false;
  const loading = props.loading ? props.loading : false;
  const onClick = props.onClick ? props.onClick : () => { };
  return (
    <Div>
      <Title data-required={required} variant="body1" gutterBottom>{title}</Title>
      <RoundedThumnail variant="rounded" src={avatar} onClick={onClick}>
        {loading ? <CircularProgress /> : <PhotoIcon />}
        {!loading && <StyledIconButton><StyledAddAPhotoIcon color='primary' /></StyledIconButton>}
      </RoundedThumnail>
    </Div>
  );
}

export const CircledAvatar = styled(Avatar)`
    margin:${props => props.theme.spacing(1)}px;
`;

export const SmallAvatar = styled(Avatar).attrs({
  variant: "square",
})`
    width: ${props => props.theme.spacing(14)}px;
    height: ${props => props.theme.spacing(15.5)}px;
    background-color: #E9E9F3;
    .MuiAvatar-fallback{
      width: 100%
    }
`;

const Div = styled.div`
    display:flex;
    flex-wrap: wrap;
    flex-direction:column;
    @media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
        align-items: center;
    }
`;


const Title = styled(Typography)`
    margin:${props => props.theme.spacing(4)}px ${props => props.theme.spacing(2)}px 0;
    font-weight:700;
    width:calc(240px - ${props => props.theme.spacing(2)}px * 3);
    &[data-required="true"] {
        &::after{
          content:'*';
          margin-left:${props => props.theme.spacing(2)}px;
          font-size:14px;
          color:red;
        }
    }
`;
const StyledAvatar = styled(Avatar).attrs({
  variant: "square"
})`
    position: absolute;
    height: 132px;
    width: 120px;
    left: -61px;
`;


const RoundedThumnail = styled(Avatar)`
position:relative;
margin:${props => props.theme.spacing(2)}px;
height: 126px;
width: 160px;
border-radius: 16px;
background: ${props => props.theme.palette.gray.gray01};
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){

}
`;

const StyledIconButton = styled(IconButton)`
background: #ffffff;
position: absolute;
width: 38px;
height: 38px;
bottom: 8px;
right: 10px;
background: #FFFFFF;
box-shadow: 0px 2px 10px rgba(92, 92, 92, 0.5);
`;

const StyledAddAPhotoIcon = styled(AddAPhotoIcon)`
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
margin: auto;
width: 23.11px;
height: 23.11px;
@media screen and (min-width: ${props => props.theme.breakpoints.values.sm}px){
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 21.11px;
  height: 21.11px;
}
`;


const AvatarWrap = styled.div`
position:relative;
margin:${props => props.theme.spacing(2)}px;
height: 120px;
width: 120px;
@media screen and (max-width: ${props => props.theme.breakpoints.values.sm}px){
  margin:0 ${props => props.theme.spacing(2)}px;
}
`;
