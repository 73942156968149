export const handlePathChange = (path: string, query: URLSearchParams) => {
  // parseする
  //パターンに応じた対応を行う
  const pathArray = path.split('/');
  let newPath;
  switch (pathArray[1]) {
    case 'users':
      newPath = '/users';
      break;
    case 'create-workplaceCompany':
      newPath = '/workplaceCompanies';
    break;
    // recordType === supportPlanの時の場合
    case 'preview':
      const recordType = query.get('recordType');
      if(recordType === 'supportPlan'){
        newPath = '/users';
      } else {
        newPath = '/eachRecord';
      }
    break;
    case 'workplaceCompanyRecordPreview':
      newPath = '/workplaceCompanyRecord';
    break;
    case 'businessLogsPreview':
      newPath = '/businessLogs';
    break;
    case 'businessLogs':
      if(pathArray[2] === 'edit'){
        newPath = '/workplaceCompanyRecord';
      }
    break;
    case 'edit-workRecords':
      newPath = '/workRecords';
    break;
    case 'organization':
      if(pathArray[2] === 'usageRecords'){
        newPath = '/organization/usageRecords';
      } else if(pathArray[2] === 'edit-supportRecords'){
        newPath = '/organization/supportRecords';
      } 
    break;
    default:
      break;
  }
  return newPath;
}