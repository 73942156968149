import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeBSubtractingRewardItem } from 'common-types/firebase/firestore/typeBOrganization/settings';

export type StateVacantPosition = {
  isVacancyOfStaff: boolean, 
  position: string, 
  startAtMillis: number
};

export type StateTypeBSubtractingRewardItem = Omit<TypeBSubtractingRewardItem, 'vacantPosition' | 'createAt' | 'updateAt'> & { 
  vacantPosition: StateVacantPosition,
  createAtMillis: number, 
  updateAtMillis: number 
};
const initialState: StateTypeBSubtractingRewardItem = {
  isInstalledByLocalGovernment: false,
  vacantPosition: {
    isVacancyOfStaff: false,
    position: '',
    startAtMillis: 0,
  },
  isNotAbolishedPhysicalRestraint: false,
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const typeBSubtractingRewardItemSlice = createSlice({
  name: 'typeBSubtractingRewardItem',
  initialState,
  reducers: {
    setTypeBSubtractingRewardItem: (_state: StateTypeBSubtractingRewardItem, action: PayloadAction<StateTypeBSubtractingRewardItem>) => {
      return action.payload;
    },
    resetTypeBSubtractingRewardItem: () => {
      return initialState;
    }
  }
});

export const { setTypeBSubtractingRewardItem, resetTypeBSubtractingRewardItem } = typeBSubtractingRewardItemSlice.actions;
export const typeBSubtractingRewardItemReducer = typeBSubtractingRewardItemSlice.reducer;