import { createTheme } from "@material-ui/core";
/**
 * material ui
 */
export const theme = createTheme({
  palette: {
    primary: {
      main: "#4963EC",
      contrastText: "#fff",
    },
    secondary: {
      main: "#4B4B4B",
      contrastText: "#FFFFFF",
    },
    error: {
      main: "rgb(244, 67, 54)",
      light: "#FFC7C6",
    },
    info: {
      main: "#D8F6FF",
      light: "#D8F6FF",
    },
    warning: {
      main: "#FFE9CA",
      light: "#FFE9CA",
    },
    black: {
      textColor: "#2B2B2B",
    },
    gray: {
      gray01: `#E9E9F3`,
    },
    text: {
      primary: "#000000",
      secondary: "#3C3C76",
    },
    mode: "light",
  },
  typography: {
    fontSize: 14,
    fontFamily: ["Noto Sans JP", "sans-serif"].join(","),
  },
  drawerWidth: "240px",
  //tableHeight: "950px",
  gutter: { pc: "64px", sp: "56px" },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});

export default theme
