import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from '../lib/dayjs';

const interviewYearAndMonthSlice = createSlice({
  name: 'interviewYearAndMonth',
  initialState: dayjs().format('YYYY-MM'),
  reducers: {
    setInterviewYearAndMonthSlice: (_state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
    resetInterviewYearAndMonthSlice: () => {
      return '';
    }
  }
});

export const { setInterviewYearAndMonthSlice, resetInterviewYearAndMonthSlice } = interviewYearAndMonthSlice.actions;
export const interviewYearAndMonthReducer = interviewYearAndMonthSlice.reducer;
