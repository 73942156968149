import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReadNews } from 'common-types/firebase/firestore/organizations/readNews';
import firebase from 'firebase';

export type StateReadNews = Omit<ReadNews, 'createAt' | 'updateAt'> & {createAtMillis: number, updateAtMillis: number};

export const initialState: StateReadNews = {
  newsId: '',
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: '',
};

const readNewsSlice = createSlice({
  name: 'readNews',
  initialState: [initialState],
  reducers: {
    setReadNews: (_state: StateReadNews[], action: PayloadAction<StateReadNews[]>) => {
      return action.payload;
    },
    resetReadNews: () => {
      return [initialState];
    }
  }
});

export const { setReadNews, resetReadNews } = readNewsSlice.actions;
export const readNewsReducer = readNewsSlice.reducer;

export const readNewsConverter = {
  toFirestore(news: StateReadNews): firebase.firestore.DocumentData {
    let readNewsData = {
      newsId: news.newsId,
      createAt: news.createAtMillis ? firebase.firestore.Timestamp.fromMillis(news.createAtMillis) : firebase.firestore.Timestamp.now(),
      updateAt: firebase.firestore.Timestamp.now(),
      createdBy: news.createdBy,
      updatedBy: news.updatedBy,
    } as firebase.firestore.DocumentData;
    return readNewsData;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): StateReadNews {
    const data = snapshot.data(options)!;
    const newsData = {
      newsId: data.newsId,
      createAtMillis: data.createAt ? data.createAt.toMillis() : null,
      updateAtMillis: data.updateAt ? data.updateAt.toMillis() : null,
      createdBy: data.createdBy,
      updatedBy: data.updatedBy,
    };
    return newsData;
  }
}