import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Assessments, BasicSheet, FaceSheet, WorkingSheet } from 'common-types/firebase/firestore/organizations/assessments';
import firebase from 'firebase';

type Excludes = 'assessedAt' | 'createAt' | 'updateAt' | 'facesheet' | 'basicsheet' | 'workingsheet';

export type StateAssessments = Omit<Assessments, Excludes> &
{
  /**
   * 聞き取り日
   */
  createAtMillis: number | null;
  updateAtMillis: number | null;
  assessmentId: string;
  facesheet: StateFaceSheet;
  basicsheet: StateBasicSheet;
  workingsheet: StateWorkSheet;
};

export type StateFaceSheet = Omit<FaceSheet, Excludes> &
{
  /**
   * 聞き取り日
   */
  assessedAtMillis: number | null;
};

export type StateBasicSheet = Omit<BasicSheet, Excludes> &
{
  /**
   * 聞き取り日
   */
  assessedAtMillis: number | null;
};

export type StateWorkSheet = Omit<WorkingSheet, Excludes> &
{
  /**
   * 聞き取り日
   */
  assessedAtMillis: number | null;
};

export const initFaceSheetObject: StateFaceSheet = {
  assessedAtMillis: null,
  assessedBy: '',
  userName: '',
  userNameKana: '',
  birthday: '',
  address: '',
  phoneNumber: '',
  emergencyContact: '',
  relationship: '',
  disabilityClassification: '',
  transportation: [],
  transportationOther: '',
  supportOrganization: '',
  supportOrganizationWorker: '',
  consultationSupportOffice: '',
  consultationSupportOfficeWorker: '',
  houseSituation: -1,
  houseSituationOthers: '',
  medicalConsultationStatus: [],
  userRequest: '',
  userParentsRequest: '',
  challengesAndDirection: '',
  family: [],
  lifeSchedule: '',
  lifeScheduleRemarks: '',
  mentalAndPhysicalConditions: [],
  usageDisabilityWelfareServices: [],
  lifeHistory: [],
};

export const initBasicSheetObject: StateBasicSheet = {
  assessedAtMillis: null,
  assessedBy: '',
  lifeAndFamily: '',
  lifeAndFamilyMemo: '',
  maintenaningGoodHealth: '',
  maintenaningGoodHealthMemo: '',
  socialLife: '',
  socialLifeMemo: '',
  others: '',
  othersMemo: '',
  listenerFindings: '',
  supportDirection: '',
};

export const initWorkingSheet: StateWorkSheet = {
  assessedAtMillis: null,
  assessedBy: '',
  healthManagement1: -1,
  healthManagement2: -1,
  healthManagement3: -1,
  lifeManagement1: -1,
  lifeManagement2: -1,
  lifeManagement3: -1,
  lifeManagement4: -1,
  lifeManagement5: -1,
  communication1: -1,
  communication2: -1,
  communication3: -1,
  communication4: -1,
  workPreparation1: -1,
  workPreparation2: -1,
  workPreparation3: -1,
  workPreparation4: -1,
  workPreparation5: -1,
  basicWorkHabit1: -1,
  basicWorkHabit2: -1,
  basicWorkHabit3: -1,
  basicWorkHabit4: -1,
  occupationalAptitude1: -1,
  occupationalAptitude2: -1,
  occupationalAptitude3: -1,
  occupationalAptitude4: -1,
  occupationalAptitude5: -1,
  occupationalAptitude6: -1,
  healthManagement1Remarks: '',
  healthManagement2Remarks: '',
  healthManagement3Remarks: '',
  lifeManagement1Remarks: '',
  lifeManagement2Remarks: '',
  lifeManagement3Remarks: '',
  lifeManagement4Remarks: '',
  lifeManagement5Remarks: '',
  communication1Remarks: '',
  communication2Remarks: '',
  communication3Remarks: '',
  communication4Remarks: '',
  workPreparation1Remarks: '',
  workPreparation2Remarks: '',
  workPreparation3Remarks: '',
  workPreparation4Remarks: '',
  workPreparation5Remarks: '',
  basicWorkHabit1Remarks: '',
  basicWorkHabit2Remarks: '',
  basicWorkHabit3Remarks: '',
  basicWorkHabit4Remarks: '',
  occupationalAptitude1Remarks: '',
  occupationalAptitude2Remarks: '',
  occupationalAptitude3Remarks: '',
  occupationalAptitude4Remarks: '',
  occupationalAptitude5Remarks: '',
  occupationalAptitude6Remarks: '',
  workHistory: [
    {
      duration: '',
      organization: '',
      jobDescription: '',
      workingCondition: '',
      remarks: ''
    }
  ],
  desiredJob: '',
  necessaryWorkPreparation: '',
  suitableJob: '',
  findings: '',
  direction: '',
};

export const initStateObject: StateAssessments = {
  userId: '',
  facesheet: initFaceSheetObject,
  basicsheet: initBasicSheetObject,
  workingsheet: initWorkingSheet,
  approvedBy: '',
  isApproved: false,
  version: 0,
  createAtMillis: null,
  updateAtMillis: null,
  isDeleted: false,
  createdBy: '',
  updatedBy: '',
  assessmentId: ''
};

const initialState: StateAssessments[] = [];

const assessmentsSlice = createSlice({
  name: 'assessments',
  initialState,
  reducers: {
    setAssessments: (_state: StateAssessments[], action: PayloadAction<StateAssessments[]>) => {
      return action.payload.sort((first, second) => first.version - second.version);
    },
    resetAssessments: () => {
      return initialState;
    }
  }
});

export const assessmentsConverter = {
  toFirestore(assessments: StateAssessments): firebase.firestore.DocumentData {
    const defaultDate = firebase.firestore.Timestamp.now();
    let assessment = {
      userId: assessments.userId,
      facesheet: {...assessments.facesheet, assessedAt: assessments.facesheet.assessedAtMillis
          ? firebase.firestore.Timestamp.fromMillis(assessments.facesheet.assessedAtMillis)
          : defaultDate
        },
      basicsheet: {...assessments.basicsheet, assessedAt: assessments.basicsheet.assessedAtMillis
          ? firebase.firestore.Timestamp.fromMillis(assessments.basicsheet.assessedAtMillis)
          : defaultDate
        },
      workingsheet: {...assessments.workingsheet, assessedAt: assessments.workingsheet.assessedAtMillis
          ? firebase.firestore.Timestamp.fromMillis(assessments.workingsheet.assessedAtMillis)
          : defaultDate
        },
      approvedBy: assessments.approvedBy,
      isApproved: assessments.isApproved,
      version: assessments.version,
      createAt: assessments.createAtMillis ? firebase.firestore.Timestamp.fromMillis(assessments.createAtMillis) : firebase.firestore.Timestamp.now(),
      updateAt: firebase.firestore.Timestamp.now(),
      createdBy: assessments.createdBy,
      updatedBy: assessments.updatedBy,
      isDeleted: assessments.isDeleted
    } as firebase.firestore.DocumentData;
    delete assessment.facesheet.assessedAtMillis;
    delete assessment.basicsheet.assessedAtMillis;
    delete assessment.workingsheet.assessedAtMillis;

    return assessment;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): StateAssessments {
    const data = snapshot.data(options)!;
    const assessment = {
      userId: data.userId,
      facesheet: data.facesheet
        ? {...data.facesheet, assessedAtMillis: data.facesheet.assessedAt
            ? data.facesheet.assessedAt.toMillis()
            : null
          } 
        : null,
      basicsheet: data.basicsheet
        ? {...data.basicsheet, assessedAtMillis: data.basicsheet.assessedAt
            ? data.basicsheet.assessedAt.toMillis()
            : null
          }
        : null,
      workingsheet: data.workingsheet
        ? {...data.workingsheet, assessedAtMillis: data.workingsheet.assessedAt
            ? data.workingsheet.assessedAt.toMillis()
            : null
          }
        : null,
      approvedBy: data.approvedBy,
      isApproved: data.isApproved,
      version: data.version,
      createAtMillis: data.createAt ? data.createAt.toMillis() : null,
      updateAtMillis: data.updateAt ? data.updateAt.toMillis() : null,
      createdBy: data.createdBy,
      updatedBy: data.updatedBy,
      isDeleted: data.isDeleted,
      assessmentId: snapshot.id
    };
    delete assessment.facesheet.assessedAt;
    delete assessment.basicsheet.assessedAt;
    delete assessment.workingsheet.assessedAt;
    return assessment;
  }
}

export const { setAssessments, resetAssessments } = assessmentsSlice.actions;
export const assessmentsReducer = assessmentsSlice.reducer;
