import { StateBeneficiaryCertificate } from "../redux/beneficiaryCertificate";
import { StateError, Message } from "../redux/error";
import { checkExpireDate } from "./checkExpireDate";

type PropsBeneficiaryCertificate = {
  userId: string,
  userName: string,
  beneficiaryCertificate: StateBeneficiaryCertificate | undefined,
}

export const checkBeneficiaryCertificate = (props: PropsBeneficiaryCertificate) => {
  let messages: Message[] = [];
  const { userId, userName, beneficiaryCertificate } = props;

  if(!beneficiaryCertificate) {
    messages.push({
      text:`${userName}さんの初回設定が完了していません。受給者証の詳細設定画面で保存するを押して初回設定を完了してください`,
      url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
      type: 'error',
      isInStateUser: true,
    })
    const error = messages.map(m => { return {mainMenu:'利用者情報', message: m} as StateError });
    return error;
  }
  if(!beneficiaryCertificate.createAtMillis) { // 初回利用者登録時
    return [] as StateError[];
  }
  messages = messages.concat(checkBeneficiaryCertificateItems(userId, userName, beneficiaryCertificate));
  const error = messages.map(m => { return {mainMenu:'利用者情報', message: m} as StateError });
  return error;
}

const checkBeneficiaryCertificateItems = (
  userId: string,
  userName: string,
  beneficiaryCertificate: StateBeneficiaryCertificate
) => {
  let messages = checkCommonBeneficiaryCertificateItems(userId, userName, beneficiaryCertificate);
  return messages;
}

const checkCommonBeneficiaryCertificateItems = (
  userId: string,
  userName: string,
  beneficiaryCertificate: StateBeneficiaryCertificate
) => {
  const messages: Message[] = [];
  const periodOfMaximuMonthlyBurdenExpireDate = checkExpireDate(beneficiaryCertificate.periodOfMaximuMonthlyBurden.endAtMillis)
  const periodOfMealProvisionSystemAdditionExpireDate = checkExpireDate(beneficiaryCertificate.periodOfMealProvisionSystemAddition.endAtMillis)
  const certificationValidityPeriodOfDisabilitySupportCategoryExpireDate = checkExpireDate(beneficiaryCertificate.certificationValidityPeriodOfDisabilitySupportCategory.endAtMillis)
  const supportDecisionPeriodforCareBenefitsExpireDate = checkExpireDate(beneficiaryCertificate.supportDecisionPeriodforCareBenefits.endAtMillis)
  const paymentPeriodOfPlanningSupportExpensesExpireDate = checkExpireDate(beneficiaryCertificate.paymentPeriodOfPlanningSupportExpenses.endAtMillis)
  const monitoringPeriodOfPlanningSupportExpensesExpireDate = checkExpireDate(beneficiaryCertificate.monitoringPeriodOfPlanningSupportExpenses.endAtMillis)

  if (periodOfMaximuMonthlyBurdenExpireDate) {
    messages.push({
      text:`${userName}さんの負担上限額の適用期間が${periodOfMaximuMonthlyBurdenExpireDate + 1}日後に切れます`,
      url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
      type: 'alert',
      isInStateUser: true,
    })
  };
  if (periodOfMealProvisionSystemAdditionExpireDate) {
    messages.push({
      text:`${userName}さんの食事提供体制加算の適用期間が${periodOfMealProvisionSystemAdditionExpireDate + 1}日後に切れます`,
      url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
      type: 'alert',
      isInStateUser: true,
    })
  };
  if (certificationValidityPeriodOfDisabilitySupportCategoryExpireDate) {
    messages.push({
      text:`${userName}さんの障害支援区分の認定有効期間が${certificationValidityPeriodOfDisabilitySupportCategoryExpireDate + 1}日後に切れます`,
      url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
      type: 'alert',
      isInStateUser: true,
    })
  };
  if (supportDecisionPeriodforCareBenefitsExpireDate) {
    messages.push({
      text:`${userName}さんの介護給付費の支援決定期間が${supportDecisionPeriodforCareBenefitsExpireDate + 1}日後に切れます`,
      url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
      type: 'alert',
      isInStateUser: true,
    })
  };
  if (paymentPeriodOfPlanningSupportExpensesExpireDate) {
    messages.push({
      text:`${userName}さんの計画相談支援給付費の支給期間が${paymentPeriodOfPlanningSupportExpensesExpireDate + 1}日後に切れます`,
      url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
      type: 'alert',
      isInStateUser: true,
    })
  };
  if (monitoringPeriodOfPlanningSupportExpensesExpireDate) {
    messages.push({
      text:`${userName}さんの計画相談支援給付費のモニタリング期間が${monitoringPeriodOfPlanningSupportExpensesExpireDate + 1}日後に切れます`,
      url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
      type: 'alert',
      isInStateUser: true,
    })
  };
  return messages;
}
