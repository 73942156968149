import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OtherOrganizationsInformationModel, Usages } from 'common-types/firebase/firestore/users/settings';

export type StarAndStopModel = {
  startAtMillis: number | null
  endAtMillis: number | null
}
export type SupportPlanStatusModel = {
  isNotCreated: boolean;
  startAtMillis: number | null;
}
export type IntensiveSupportModel = {
  isStarted: boolean;
  startAtMillis: number | null;
}

export type SupportForEmploymentTransition = {
  isAdded: boolean,
  startAtMillis: number | null
}

export type MunicipalSubsidyModel = {
  isTarget: boolean;
  amountOfSubsidy: number;
  unit: string;
  prefecture:string;
  city:string;
  cityCode: number;
};

export type SpecialCaseForUsageModel = {
  isSpecialCase: boolean;
  startAtMillis: number | null;
  endAtMillis: number | null
}

export type ManagementOrganizationModel = {
  hasUpperLimitManagementOrganization: boolean;
  hasManagementAuthentication: boolean;
  otherOrganizationsInformation: OtherOrganizationsInformationModel;
  managementResult: number;
};

export type StateUsages = Omit<Usages,
'createAt'|
'updateAt' |
'hasReceivedService' |
'hasReceivedCertification' |
'supportPlanStatus' |
'specialCaseForUsage'|
'supportForEmploymentTransition' |
'intensiveSupport' > & {
  createAtMillis: number | null,
  updateAtMillis:  number | null,
  hasReceivedService: StarAndStopModel
  hasReceivedCertification: StarAndStopModel
  supportPlanStatus: SupportPlanStatusModel,
  supportForEmploymentTransition: SupportForEmploymentTransition,
  specialCaseForUsage: SpecialCaseForUsageModel,
  intensiveSupport?: IntensiveSupportModel
};

  const starAndStopModelInitState = {
    startAtMillis: null,
    endAtMillis: null
  }

const initialState: StateUsages = {
  hasReceivedService: starAndStopModelInitState,
  isNotInitialAdditonTarget: false,
  hasReceivedCertification: starAndStopModelInitState,
  disabilityClassification: 0,
  contractPaymentAmount: {
    isDefault: true,
    numberOfDate: 0,
    businessEntryNumber: 0,
  },
  specialCaseForUsage: {
    isSpecialCase: false,
    startAtMillis: null,
    endAtMillis: null,
  },
  maximumBurden: 0,
  incomeClassification: undefined,
  municipalSubsidy: {
    isTarget: false,
    amountOfSubsidy: 0,
    unit: '',
    prefecture:'',
    city:'',
    cityCode: 0,
  },
  isATypeExemptionTarget: false,
  managementOrganization:{
    hasUpperLimitManagementOrganization: false,
    hasManagementAuthentication: false,
    otherOrganizationsInformation:[{
      organizationId: '',
      organizationName: '',
      totalAmount: 0,
      userBurden: 0,
      managedUserBurden: 0,
    }],
    managementResult: 0,
  },
  scheduledDateOfComing: {
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  },
  isAutoFilledWorkTime: false,
  defaultFoodService: false, // TODO: 選択肢を文字列で追加
  defaultTransferService: 'none',// TODO: 選択肢を文字列で追加
  defaultTransferWithSameSite: 'none',// TODO: 選択肢を文字列で追加
  supportPlanStatus: {
    isNotCreated: false,
    startAtMillis: 0,
  },
  supportForEmploymentTransition: {
    isAdded: false,
    startAtMillis: 0,
  },
  intensiveSupport: {
    isStarted: false,
    startAtMillis: 0,
  },
  createAtMillis: null,
  updateAtMillis: null,
  maximumAmountOfAdjustmentOwnOffice: null,
  createdBy: '',
  updatedBy: ''
}

const usagesSlice = createSlice({
  name: 'usages',
  initialState,
  reducers: {
    setUsages: (_state: StateUsages, action: PayloadAction<StateUsages>) => {
      return action.payload;
    },
    resetUsages: () => {
      return initialState;
    }
  }
});

export const { setUsages, resetUsages } = usagesSlice.actions;
export const usagesReducer = usagesSlice.reducer;
