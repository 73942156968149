import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "common-types/firebase/firestore/users";
import { StateQualificationModel } from "./beneficiaryCertificate";
import { StateUserProfile } from "./profile";

export type StateUser = Omit<User, "createAt" | "updateAt"> & {
  createAtMillis: number | null;
  updateAtMillis: number | null;
  userId: string;
  settingStep: number | null;
  hasReceivedCertification: {
    startAtMillis: number | null;
    endAtMillis: number | null;
  };
  hasReceivedService: {
    startAtMillis: number | null;
    endAtMillis: number | null;
  };
  beneficiaryCertificateDetail: {
    createAtMillis: number | null;
    periodOfMaximuMonthlyBurden: StateQualificationModel;
    periodOfMealProvisionSystemAddition: StateQualificationModel;
    certificationValidityPeriodOfDisabilitySupportCategory: StateQualificationModel;
    supportDecisionPeriodforCareBenefits: StateQualificationModel;
    paymentPeriodOfPlanningSupportExpenses: StateQualificationModel;
    monitoringPeriodOfPlanningSupportExpenses: StateQualificationModel;
  };
  profile: {
    prefecture: string;
    city: string;
    admissionDate: string;
  };
};

const stateQualificationModelInit = {
  hasQualification: false,
  startAtMillis: null,
  endAtMillis: null,
};

// TODO: createdByを入れる
export const initialState: StateUser[] = [
  {
    organizationId: "",
    email: "",
    createAtMillis: null,
    updateAtMillis: null,
    createdBy: "",
    updatedBy: "",
    isDeleted: false,
    userId: "",
    photoUrl: "",
    nameKanji: "",
    nameKana: "",
    settingStep: null,
    beneficiaryNumber: "",
    hasReceivedCertification: {
      startAtMillis: null,
      endAtMillis: null,
    },
    hasReceivedService: {
      startAtMillis: null,
      endAtMillis: null,
    },
    beneficiaryCertificateDetail: {
      createAtMillis: null,
      periodOfMaximuMonthlyBurden: stateQualificationModelInit,
      periodOfMealProvisionSystemAddition: stateQualificationModelInit,
      certificationValidityPeriodOfDisabilitySupportCategory:
        stateQualificationModelInit,
      supportDecisionPeriodforCareBenefits: stateQualificationModelInit,
      paymentPeriodOfPlanningSupportExpenses: stateQualificationModelInit,
      monitoringPeriodOfPlanningSupportExpenses: stateQualificationModelInit,
    },
    profile: {
      prefecture: "",
      city: "",
      admissionDate: "",
    },
    serviceType: "aType",
  },
];

type UpdatedProfile = {
  prefecture: string;
  city: string;
  admissionDate?: string;
  userId: string;
};
const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (_state: StateUser[], action: PayloadAction<StateUser[]>) => {
      return action.payload;
    },
    resetUsers: () => {
      return initialState;
    },
    updateProfile: (
      state: StateUser[],
      action: PayloadAction<UpdatedProfile>
    ) => {
      const updatedProfile = action.payload;

      const target = state.find((t) => t.userId === action.payload.userId);
      const targets = state.filter((t) => t.userId !== action.payload.userId);
      if (!target) {
        return state;
      }
      return [
        ...targets,
        {
          ...target,
          profile: {
            prefecture: updatedProfile.prefecture,
            city: updatedProfile.city,
            admissionDate: updatedProfile.admissionDate,
          },
        },
      ] as StateUser[];
    },
  },
});

export const { setUsers, resetUsers, updateProfile } = usersSlice.actions;
export const usersReducer = usersSlice.reducer;
