import { jpFormat } from 'dayjs-jp-format'
import objectSupport from 'dayjs/plugin/objectSupport';
import duration from 'dayjs/plugin/duration';

const dayjs = require('dayjs');
require('dayjs/locale/ja');

dayjs.locale('ja');
dayjs.extend(require('dayjs/plugin/customParseFormat'));
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/isBetween'));
dayjs.extend(require('dayjs/plugin/isSameOrAfter'));
dayjs.extend(require('dayjs/plugin/isSameOrBefore'));
dayjs.tz.setDefault('Asia/Tokyo');
dayjs.extend(jpFormat)
dayjs.extend(objectSupport);
dayjs.extend(duration);

export default dayjs;
