import { StateError, Message } from "../redux/error";
import {
  StateSubtractingRewardItem,
} from "../redux/subtractingRewardItem";
import { StateSubtractingRewardItem2024 } from "../redux/subtractingRewardItem2024";
import { StateTransferSubtractingRewardItem } from "../redux/transferSubtractingRewardItem";
import { StateTypeBSubtractingRewardItem } from "../redux/typeBSubtractingRewardItem";
import { StateTypeBSubtractingRewardItem2024 } from "../redux/typeBSubtractingRewardItem2024";

type PropsSubtractingRewardItem = {
  subtractingRewardItem: StateSubtractingRewardItem | undefined,
}
type PropsSubtractingRewardItem2024 = {
  subtractingRewardItem: StateSubtractingRewardItem2024 | undefined,
}
type PropsTypeBSubtractingRewardItem = {
  subtractingRewardItem: StateTypeBSubtractingRewardItem | undefined;
};
type PropsTypeBSubtractingRewardItem2024 = {
  subtractingRewardItem: StateTypeBSubtractingRewardItem2024 | undefined;
};

type PropsTransferSubtractingRewardItem = {
  subtractingRewardItem: StateTransferSubtractingRewardItem | undefined;
};

export const checkSubtractingRewardItem = (props: PropsSubtractingRewardItem) => {
  let messages: Message[] = [];
  const { subtractingRewardItem } = props;

  if (subtractingRewardItem) {
    messages = messages.concat(checkTypeASubtractingRewardItemItems(subtractingRewardItem));
  }
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error
}
export const checkSubtractingRewardItem2024 = (props: PropsSubtractingRewardItem2024) => {
  let messages: Message[] = [];
  const { subtractingRewardItem } = props;

  if(!subtractingRewardItem?.createAtMillis) {
    messages.push({
      text:`2024年度の減算報酬項目が登録されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    });
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  }
  messages = messages.concat(checkTypeASubtractingRewardItemItems(subtractingRewardItem));
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error
}

export const checkTypeBSubtractingRewardItem = (props: PropsTypeBSubtractingRewardItem) => {
  let messages: Message[] = [];
  const { subtractingRewardItem } = props;

  if (subtractingRewardItem) {
    messages = messages.concat(checkTypeBSubtractingRewardItemItems(subtractingRewardItem));
  }
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error
}
export const checkTypeBSubtractingRewardItem2024 = (props: PropsTypeBSubtractingRewardItem2024) => {
  let messages: Message[] = [];
  const { subtractingRewardItem } = props;

  if(!subtractingRewardItem?.createAtMillis) {
    messages.push({
      text:`2024年度の減算報酬項目が登録されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    });
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  }
  messages = messages.concat(checkTypeBSubtractingRewardItemItems(subtractingRewardItem));
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error
}

export const checkTransferSubtractingRewardItem = (props: PropsTransferSubtractingRewardItem) => {
  let messages: Message[] = [];
  const { subtractingRewardItem } = props;

  if(!subtractingRewardItem?.createAtMillis) {
    messages.push({
      text:`減算報酬項目が登録されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    });
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  }
  messages = messages.concat(checkTransferSubtractingRewardItemItems(subtractingRewardItem));
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error
}

const checkTypeASubtractingRewardItemItems = (subtractingRewardItem: StateSubtractingRewardItem) => {
  let messages = checkCommonSubtractingRewardItemItems(subtractingRewardItem);
  return messages;
}

const checkTypeBSubtractingRewardItemItems = (subtractingRewardItem: StateTypeBSubtractingRewardItem) => {
  let messages = checkCommonSubtractingRewardItemItems(subtractingRewardItem);
  return messages;
}

const checkTransferSubtractingRewardItemItems = (subtractingRewardItem: StateTransferSubtractingRewardItem) => {
  let messages = checkCommonSubtractingRewardItemItems(subtractingRewardItem);
  return messages;
}

const checkCommonSubtractingRewardItemItems = (subtractingRewardItem: StateSubtractingRewardItem | StateTypeBSubtractingRewardItem | StateTransferSubtractingRewardItem) => {
  const messages: Message[] = [];
  if (subtractingRewardItem.vacantPosition.isVacancyOfStaff && (
    !(subtractingRewardItem.vacantPosition.position && subtractingRewardItem.vacantPosition.startAtMillis)
  )) {
    messages.push({
      text:`減算対象項目：人員の欠員が選択されていますが減算適用開始月または欠員対象が保存されていません`,
      url: `organization?tab=rewards`,
      type: 'error',
    })
  }
  return messages;
}
