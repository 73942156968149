import React from "react";
import { Button as MuButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { AddIcon, ArrowIcon } from "../../icons";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { BodyText } from "./Text";
import { PropTypes } from "@mui/material";
import Badge from '@mui/material/Badge';

const useStyles = makeStyles((theme) => ({
  isAttendButton: {
    background: "#2B9B60",
    color: "#fff",
    "&:hover": {
      background: "rgb(30, 108, 67)",
    },
  },
}));

type ButtonProps = {
  disabled?: boolean;
  onClick: (() => void) | (() => Promise<void>) | undefined;
  color?: PropTypes.Color;
  style?: React.CSSProperties;
  isLoading?: boolean;
  value: string | React.ReactNode;
  type?: "button" | "submit" | "reset";
  variant?: "contained" | "outlined" | "text";
};
type HeaderButtonProps = Omit<ButtonProps, "onClick"> & {
  isActive: boolean;
  isError?: boolean;
  hasSubMenu: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

type AddIconButtonProps = {
  onClick: (() => void) | (() => Promise<void>);
  disabled?: boolean;
  text: string;
};
export const Button = (props: ButtonProps) => {
  return (
    <StyledButton
      disableElevation
      style={props.style}
      type={props.type}
      variant={props.variant ? props.variant : "contained"}
      color={props.color ? props.color : "primary"}
      disabled={props.disabled}
      startIcon={props.isLoading ? <CircularProgress size={20} /> : null}
      onClick={props.onClick}
    >
      {props.value}
    </StyledButton>
  );
};

type InlineBlockButton = ButtonProps & {
  icon?: React.ReactNode;
};

export const InlineBlockButton = (props: InlineBlockButton) => {
  return (
    <>
      {props.icon !== undefined ? (
        <StyledInlineBlockButton
          disableElevation
          style={props.style}
          type={props.type}
          variant={props.variant ? props.variant : "contained"}
          color={props.color ? props.color : "primary"}
          disabled={props.disabled}
          startIcon={props.icon}
          onClick={props.onClick}
        >
          {props.value}
        </StyledInlineBlockButton>
      ) : (
        <StyledInlineBlockButton
          disableElevation
          style={props.style}
          type={props.type}
          variant={props.variant ? props.variant : "contained"}
          color={props.color ? props.color : "primary"}
          disabled={props.disabled}
          startIcon={props.isLoading ? <CircularProgress size={20} /> : null}
          onClick={props.onClick}
        >
          {props.value}
        </StyledInlineBlockButton>
      )}
    </>
  );
};

export const RoundedButton = (
  props: ButtonProps & {
    icon?: React.ReactNode;
  }
) => {
  return (
    <StyledRoundedButton
      disableElevation
      style={{
        ...props.style,
        background: props.variant === "contained" ? "#3C3C76" : "#FFFFFF",
        color: props.variant === "contained" ? "#FFFFFF" : "#4B4B4B",
      }}
      type={props.type}
      variant={props.variant ? props.variant : "contained"}
      color={"primary"}
      disabled={props.disabled}
      startIcon={
        props.isLoading ? (
          <CircularProgress size={20} />
        ) : props.icon ? (
          props.icon
        ) : null
      }
      onClick={props.onClick}
    >
      {props.value}
    </StyledRoundedButton>
  );
};

type WithIconButtonProps = ButtonProps & {
  icon: React.ReactNode;
  isFlat?: boolean;
};

export const WithStartIconButton = (props: WithIconButtonProps) => {
  return (
    <>
      {props.isFlat ? (
        <StyledFlatButton
          disableElevation
          style={props.style}
          type={props.type}
          variant={props.variant ? props.variant : "contained"}
          color={props.color}
          disabled={props.disabled}
          onClick={props.onClick}
          startIcon={
            props.isLoading ? <CircularProgress size={20} /> : props.icon
          }
        >
          {props.value}
        </StyledFlatButton>
      ) : (
        <StyledButton
          disableElevation
          style={props.style}
          type={props.type}
          variant={props.variant ? props.variant : "contained"}
          color={props.color}
          disabled={props.disabled}
          onClick={props.onClick}
          startIcon={
            props.isLoading ? <CircularProgress size={20} /> : props.icon
          }
        >
          {props.value}
        </StyledButton>
      )}
    </>
  );
};

export const WithEndIconButton = (props: WithIconButtonProps) => {
  return (
    <StyledTextButton
      disableElevation
      style={props.style}
      type={props.type}
      variant={props.variant ? props.variant : "contained"}
      color={props.color || "primary"}
      disabled={props.disabled}
      onClick={props.onClick}
      endIcon={props.icon}
    >
      {props.value}
    </StyledTextButton>
  );
};

type BackButtonProps = {
  disabled: boolean;
  onClick: (() => void) | (() => Promise<void>);
  color: "primary" | "secondary" | undefined;
  style: React.CSSProperties | undefined;
  value: string;
};

export const BackIconButton = (props: BackButtonProps) => {
  return (
    <IconButtonWrapper>
      <IconButton onClick={props.onClick}>
        <ArrowBackIcon />
      </IconButton>
    </IconButtonWrapper>
  );
};
type LinkButtonProps = {
  onClick: (() => void) | (() => Promise<void>);
  text: string;
  fontSize?: number;
};
export const LinkButton = (props: LinkButtonProps) => {
  return (
    <StyledLinkButton onClick={props.onClick} fontSize={props.fontSize}>
      {props.text}
    </StyledLinkButton>
  );
};

const useHeaderStyles = makeStyles((theme) => ({
  endIcon: {
    marginLeft: 4,
    "& $svg": {
      fill: "black",
    },
  },
}));

export const HeaderMenuButton = React.memo((props: HeaderButtonProps) => {
  const classes = useHeaderStyles();
  return (
    <StyledHederButton
      classes={{ endIcon: classes.endIcon }}
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "100%",
      }}
      type={props.type}
      variant="text"
      disabled={props.disabled}
      onClick={props.onClick}
      isActive={props.isActive}
      endIcon={
        props.hasSubMenu && (
          <ArrowIcon color={props.isActive ? "#FFFFFF" : "inherit"} />
        )
      }
    >
      {props.value}
      {props.isError ? <StyledFiberManualRecordIcon /> : <></>}
    </StyledHederButton>
  );
});

export const AddIconButton: React.VFC<AddIconButtonProps> = (props) => {
  const onClick = () => {
    if (!props.disabled) {
      props.onClick();
    }
  };
  return (
    <Div onClick={onClick}>
      <AddIcon />
      <Text style={{ fontWeight: "bold", color: "#3C3C76", cursor: "pointer" }}>
        {props.text}
      </Text>
    </Div>
  );
};

export const SmallButton = (props: ButtonProps) => {
  return (
    <StyledSmallButton
      disableElevation
      style={props.style}
      type={props.type}
      variant={props.variant ? props.variant : "contained"}
      color={props.color ? props.color : "primary"}
      disabled={props.disabled}
      startIcon={props.isLoading ? <CircularProgress size={20} /> : null}
      onClick={props.onClick}
    >
      {props.value}
    </StyledSmallButton>
  );
};
export const AttendaceButton = React.memo((props: ButtonProps) => {
  return (
    <StyledBigButton
      style={props.style}
      type={props.type}
      color={props.color}
      variant="contained"
      disabled={props.disabled}
      onClick={props.onClick}
      disableElevation
    >
      {props.value}
    </StyledBigButton>
  );
});

export const UserAttendaceButton = React.memo(
  (
    props: Omit<ButtonProps, "color"> & {
      startIcon: React.ReactNode;
      color?: "primary" | "secondary" | "default" | "inherit";
    }
  ) => {
    const classes = useStyles();
    return (
      <UserButtom
        startIcon={props.startIcon}
        style={props.style}
        type={props.type}
        className={props.color ? props.color : classes.isAttendButton}
        color={props.color}
        variant={props.variant}
        disabled={props.disabled}
        onClick={props.onClick}
        disableElevation
      >
        {props.value}
      </UserButtom>
    );
  }
);

const StyledButton = styled(MuButton).attrs({})`
  min-width: 212px;
  max-width: 230px;
  font-weight: bold;
`;
const StyledFlatButton = styled(MuButton).attrs({})`
  font-weight: bold;
`;
type CustomLinkButtonProps = {
  fontSize?: number;
};
const StyledLinkButton = styled(BodyText).attrs({})<CustomLinkButtonProps>`
  text-decoration-line: underline;
  color: #3c3c76;
  cursor: pointer;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "14px")};
`;
const StyledInlineBlockButton = styled(MuButton).attrs({})`
  width: auto;
  font-size: 100%;
  font-weight: bold;
  padding: 1px 5px;
  margin: 0 3px;
  .MuiButton-startIcon {
    margin-right: 0px;
  }
`;

const StyledTextButton = styled(MuButton).attrs({})`
  width: auto;
  font-weight: bold;
`;

const StyledRoundedButton = styled(MuButton).attrs({
  color: "primary",
})`
  font-weight: bold;
  border-radius: 100px;
  border: 1px solid #d0d2d5;
`;

const StyledSmallButton = styled(MuButton).attrs({})`
  border: 2px solid #3f5b77;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: bold;
  height: 56px;
  width: 110px;
  font-size: 12px;
  padding: 4px;
`;

const IconButtonWrapper = styled.div``;

const UserButtom = styled(MuButton)`
  border-radius: 30px;
  height: 50px;
  min-width: 140px;
  font-size: 20px;
  font-weight: 700;
`;

type CustomProps = {
  isActive?: boolean;
};
const StyledHederButton = styled(MuButton).attrs({})<CustomProps>`
  min-width: 72px;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 100px;
  margin: 0 1px;
  background-color: ${(props) =>
    props.isActive && props.theme.palette.primary.main};
  color: ${(props) =>
    props.isActive && props.theme.palette.primary.contrastText};
  &:hover {
    background-color: ${(props) =>
      props.isActive && props.theme.palette.primary.main};
    color: ${(props) =>
      props.isActive ? props.theme.palette.primary.contrastText : "black"};
  }
`;
const StyledFiberManualRecordIcon = styled(FiberManualRecordIcon)`
  color: #FF7374;
  width: 12px;
  height: 12px;
  margin: 0 0 auto;
`;
const Div = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Text = styled(Typography).attrs({
  variant: "body1",
})`
  font-weight: 500;
  font-family: Noto Sans;
  font-style: normal;
  font-size: 14px;
  color: ${(props) => props.theme.palette.primary.main};
  margin-left: ${(props) => props.theme.spacing(1)}px;
`;

const StyledBigButton = styled(MuButton).attrs({})`
  width: 156px;
  height: 96px;
  border-radius: 20px;
  font-weight: bold;
  &:hover {
    background-color: opacity(0);
  }
`;

