import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { useTypedSelector } from '../../redux';
import ButtonGroup from '@mui/material/ButtonGroup';
import { HeaderMenuButton } from '../atoms/Button';
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { HeaderSubMenus } from '../molecules/MenuList';
import { termsUrl, privacyPolicyUrl } from '../../config';
import { RakucoTitleLogo } from '../../icons';
import { IconMenu} from '../molecules/MenuList';
import { BoldTitle } from "../atoms/Text";
import Button from "@mui/material/Button";
import { ErrorAlertDialog } from "../molecules/ErrorAlertDialog";

type SimpleAppBarProps = {
  logined: boolean,
  menus: Array<{value: string, label: string, subMenus?: Array<{label: string, path: string}>}>;
  role: "" | "owner" | "user" | "operator" | "manager";
}
export const AppFrame = (props: any) => {
  const logined = useTypedSelector((state) => state.logined);
  const memberData = useTypedSelector((state) => state.memberData);
  const selectedOrganization = useTypedSelector((state) => state.selectedOrganization);
  const selectedOrganizationId = selectedOrganization.organizationId;
  const role = memberData.roles.find((doc) => doc.organizationId === selectedOrganizationId)?.role;
  const onClickTerms = () => {
    window.open(termsUrl, '_blank');
  }
  const onClickPrivacyPolicy = () => {
    window.open(privacyPolicyUrl, '_blank');
  }
  const ownerOrgMenu = [
    {
      label: '事業所基本情報',
      path: `/organization`
    },
    {
      label: '就労先企業情報',
      path: `/workplaceCompanies`
    },
    {
      label: '作業情報',
      path: `/workItems`
    },
    {
      label: '職員情報',
      path: `/members`
    },
    {
      label: 'カード登録',
      path: `/register-card`
    },
    {
      label: '初期設定',
      path: `/initialSetting`
    },
  ];
  const managerOrgMenu = [
    {
      label: '事業所基本情報',
      path: `/organization`
    },
    {
      label: '就労先企業情報',
      path: `/workplaceCompanies`
    },
    {
      label: '作業情報',
      path: `/workItems`
    },
    {
      label: '職員情報',
      path: `/members`
    },
    {
      label: '初期設定',
      path: `/initialSetting`
    },
  ];

  const operatorOrgMenu = [
    {
      label: '就労先企業情報',
      path: `/workplaceCompanies`
    },
    {
      label: '作業情報',
      path: `/workItems`
    },
    {
      label: '職員情報',
      path: `/members`
    },
  ];


  const menus =(role: "" | "owner" | "user" | "operator" | "manager") =>{
    if(['', 'user'].find((doc) => doc === role)){
      return []
    } else {
    return [
    {
      value: 'usageRecords',
      label: '実績',
    },
    {
      value: 'businessLogs',
      label: '業務日誌',
    },
    {
      value: 'record',
      label: '記録' ,
      subMenus: [
        {
        label: '支援記録',
        path: `/organization/supportRecords`
      },
      {
        label: '作業記録',
        path: `/workRecords?tab=daily`
      },
    ]
    },
    {
      value: 'users',
      label: '利用者情報',
      path: `/users`
    },
    {
      value: 'organization',
      label: '事業所設定',
      subMenus: (role === 'owner') ? ownerOrgMenu : (role === 'manager') ? managerOrgMenu : operatorOrgMenu,
    },
    {
      value: 'sheets',
      label: '帳票出力',
      subMenus: (role !== 'operator')?[
        {
        label: '請求書類',
        path: `/benefitRecord`
        },
        {
          label: '各種記録',
          path: `/eachRecord`
        },
        {
          label: '施設外就労実施報告書',
          path: `/workplaceCompanyRecord`
        },
      ] :
      [
        {
          label: '各種記録',
          path: `/eachRecord`
        },
        {
          label: '施設外就労実施報告書',
          path: `/workplaceCompanyRecord`
        },
      ]
    },
    {
      value: 'groups',
      label: 'グループ設定',
      path: `/groups`
    },
    {
      value: 'help',
      label: 'ヘルプ',
      subMenus:[
        {
          label: '操作マニュアル',
          path: `manual`
        },
        {
          label: 'お問い合わせ',
          path: `form`
        },
      ]
    },
    {
      value: 'create-link',
      label: '打刻URL',
    },
  ]
  }
  }

  return (
    <>
    <SimpleAppBar
      logined={logined}
      menus={role ? menus(role) : []}
      role={role ? role : ""}
    />
      {logined ?
        <Wrap
        logined={logined}
        >
          <Content>{props.children}</Content>
          <TermArea>
              <TermsDiv onClick={()=>onClickTerms()}>利用規約</TermsDiv>
              <PrivacyPolicyDiv onClick={()=>onClickPrivacyPolicy()}>プライバシーポリシー</PrivacyPolicyDiv>
          </TermArea>
          <CopyRight>{'@Curip'}</CopyRight>
        </Wrap> :
        <Wrap
        logined={logined}>
          <Content>{props.children}</Content>
          <TermArea>
              <TermsDiv onClick={()=>onClickTerms()}>利用規約</TermsDiv>
              <PrivacyPolicyDiv onClick={()=>onClickPrivacyPolicy()}>プライバシーポリシー</PrivacyPolicyDiv>
          </TermArea>
          <CopyRight>{'@Curip'}</CopyRight>
        </Wrap>

      }
    </>
  );
};
const SimpleAppBar:React.VFC<SimpleAppBarProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { role, menus, logined } = props;
  const [errorAlertDialogOpen, setErrorAlertDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [subMenus, setSubMenus] = useState<Array<{label: string, path: string}>| null>(null);
  const [activeMenu, setActiveMenu] = useState<string>('');
  const error = useTypedSelector(state => state.error);
  const topAlert = useTypedSelector(state => state.topAlert);
  const open = Boolean(anchorEl);
  const isMemberAccount = useMemo(() => {
    return ((role === 'owner'|| role === 'manager'|| role === 'operator') && logined) ? true : false
  }, [logined, role]);

  const alertMessages = useMemo(() => {
      if (location.pathname ==="/benefitRecord"){
        return error.filter(
          (e) => e.mainMenu === "帳票出力" && e.message.type === "alert"
        );
      }
      if (location.pathname ==="/users"){
        return error.filter(
          (e) => e.mainMenu === "利用者情報" && e.message.type === "alert"
        );
      }
      if (location.pathname ==="/register-card"){
        return error.filter(
          (e) =>
            e.mainMenu === "事業所設定" &&
            e.subMenu === "カード登録" &&
            e.message.type === "alert"
        );
      }
      if (location.pathname ==="/"){
        return error.filter((e) => e.mainMenu === "実績" && e.message.type === "alert");
      }
      if (location.pathname ==="/organization"){
        return error.filter((e) => e.mainMenu === "事業所設定" && e.message.type === "alert");
      }
      return []
  }, [error, location.pathname])

  useEffect(() => {
    /**
     * パスの見直しに伴いロジック修正を行う予定
     */
  const pathArray = location.pathname.split('/');
  for (let index = 0; index < menus.length; index++) {
    const menu = menus[index];
    if(menu.subMenus){
      let currentMenu
      if(pathArray.length === 2){
          if(pathArray[1]=== 'workRecords'){
            currentMenu = menu.subMenus.find((subMenu) => subMenu.path === `/workRecords?tab=daily`);
          } else {
            currentMenu = menu.subMenus.find((subMenu) => subMenu.path === `/${pathArray[1]}`);
          }
      } else if (pathArray.length === 3){
        if(pathArray[1]=== 'organization'){
          currentMenu = menu.subMenus.find((subMenu) => subMenu.path === `/organization/${pathArray[2]}`);
        } else {
          currentMenu = menu.subMenus.find((subMenu) => subMenu.path === `/${pathArray[2]}`);
        }
      }
      if(currentMenu){
        setActiveMenu(menu.value);
        break;
      }
    } else {
      if(pathArray.length === 2){
        if(menu.value === `${pathArray[1]}`){
          setActiveMenu(menu.value);
          break;
        }
      } else if (pathArray.length === 3){
        if(menu.value === `${pathArray[2]}`){
          setActiveMenu(menu.value);
          break;
        }
      } else if((pathArray.length === 4 && pathArray[2] === 'usageRecords')) {
        if(menu.value === `${pathArray[2]}`){
          setActiveMenu(menu.value);
          break;
        }
      }
    }
  }
  },[location, menus]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, value: string) => {
      /**
     * パスの見直しに伴いロジック修正を行う予定
     */
    const tempSubMenus = menus.find((menu) => menu.value === value)?.subMenus;
    setSubMenus(tempSubMenus ? tempSubMenus : null);
    if(tempSubMenus){
      setAnchorEl(event.currentTarget);
    } else {
      if(value==='businessLogs' || value==='create-link' || value === 'users' || value === 'groups'){
      history.push(`/${value}`);
      } else {
        history.push(`/organization/${value}`)
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <StyledAppBar elevation={0} style={{ background: "white" }}>
        <StyledToolbar>
          <AppLogo>
            <RakucoTitleLogo />
          </AppLogo>
          {isMemberAccount && (
            <BarContents>
              <IconMenu />
            </BarContents>
          )}
        </StyledToolbar>
        {isMemberAccount && (
          <StyledDiv>
            <Inner>
              <ButtonGroup
                variant="text"
                aria-label="outlined primary button group"
              >
                {menus &&
                  menus.map((menu, index) => {
                    return (
                      <div style={{ border: "none" }} key={index}>
                        <HeaderMenuButton
                          key={menu.label}
                          value={menu.label}
                          hasSubMenu={menu.subMenus ? true : false}
                          isActive={menu.value === activeMenu ? true : false}
                          isError={
                            error.filter((e) => e.mainMenu === menu.label)
                              .length
                              ? true
                              : menu.label === '事業所設定' && topAlert.length > 0 ? true : false
                          }
                          onClick={(e) => handleClick(e, menu.value)}
                        />
                      </div>
                    );
                  })}
              </ButtonGroup>
            </Inner>
            <HeaderSubMenus
              anchorEl={anchorEl}
              open={open}
              subMenus={subMenus}
              error={error}
              topAlert={topAlert}
              handleClose={handleClose}
            />
          </StyledDiv>
        )}
        {alertMessages.length > 0 && (
          <AlertBox>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "6px",
                paddingBottom: "6px",
              }}
            >
              <BoldTitle>
                利用者情報でまもなく期限が切れる項目があります。確認してください。
              </BoldTitle>
              <AlertButton
                onClick={()=>setErrorAlertDialogOpen(true)}
                variant={"contained"}
              >
                すぐに更新を行う
              </AlertButton>
            </div>
          </AlertBox>
        )}
        <ErrorAlertDialog
          type={"alert"}
          error={alertMessages}
          open={errorAlertDialogOpen}
          setOpen={setErrorAlertDialogOpen}
        />
      </StyledAppBar>
    </>
  );
};

const StyledAppBar = styled(AppBar)`
  @media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
    margin-left: ${(props) => props.theme.drawerWidth};
  }
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  align-items: center;
  background-color: #000626;
`;

const AlertBox = styled.div`
  color: black;
  background-color: #f3a73a;
  text-align: center;
`;

const StyledDiv = styled.div`
width: 100vw;
box-shadow: 0px 10px 10px 0px rgb(0 0 0 / 3%);
@media screen and (min-width: ${(props) =>
  props.theme.breakpoints.values.sm}px) {
  padding-top: 17.35px;
  padding-bottom: 13px;
  background: white;
}
`;

const Inner = styled.div`
margin:0 auto;
  @media screen and (min-width: 1200px){
    width: 984px;
  }

  @media screen and (min-width: 1400px){
    width: 1200px;
  }
`;

type CustomProps = {
  logined: boolean
}
const Content = styled.div`
  padding-top: calc(
    ${(props) => props.theme.spacing(2)}px
  );
  @media screen and (min-width: ${(props) =>
    props.theme.breakpoints.values.sm}px) {
   // max-width: calc(100% - ${(props) => props.theme.drawerWidth});
    max-width: calc(100% - 48px);
    margin-left: auto;
    margin-right: auto;
    padding-top: calc(
        ${(props) => props.theme.spacing(2)}px
    );
    min-height: calc(100vh - 95px);
    padding-bottom:80px;
  }
  @media screen and (min-width: 1200px){
    width: 984px;
   }

  @media screen and (min-width: 1400px){
    width: 1200px;
  }
`;

const BarContents = styled(Toolbar)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const AppLogo = styled.div`
display: flex;
justify-content: flex-start;
width: 100%;
`;



const Wrap = styled.div<CustomProps>`
width: 100%;
min-height: 100vh;
background: #F3F7FA;
`;

const TermArea = styled.div`
display:flex;
color: ${props => props.theme.palette.primary.main};
text-align:center;
font-size: 14px;
justify-content: center;
`;
const CopyRight = styled.div`
color: #B6B6B6;
font-size: 14px;
margin-top: ${props => props.theme.spacing(2)}px;
padding-bottom: ${props => props.theme.spacing(4)}px;
justify-content: center;
display:flex;
`;

const TermsDiv = styled.div`
padding-right: 28px;
border-right: 1px solid #817C7C;
font-style: Noto Sans;
cursor: pointer;
font-size: 15px;
color: ${props => props.theme.palette.text.secondary};
text-decoration-line: underline;
`;

const PrivacyPolicyDiv = styled.div`
padding-left: 28px;
cursor: pointer;
font-size: 15px;
color: ${props => props.theme.palette.text.secondary};
text-decoration-line: underline;
`;

const AlertButton = styled(Button)`
  background-color: black !important;
  color: white !important;
  font-weight: bold !important;
  margin-left: 16px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  box-shadow: none !important;
`;
