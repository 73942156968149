import { ServiceType } from "common-types/firebase/firestore/organizations";

export const getSelectedOrganization = (serviceType: ServiceType) => {
  switch (serviceType) {
    case 'aType':
      return 'organizations';
    case 'bType':
      return 'typeBOrganizations';
    case 'transfer':
      return 'transferOrganizations';
    default:
      console.error(`invalid serviceType in getSelectedOrganization:${serviceType}`);
      return '';
  }
};
