import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CircledAvatar } from "../atoms/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { logout } from "../../firebase/auth";
import { IconButton, Typography } from "@material-ui/core";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { formUrl, manualUrl } from "../../config";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, Divider } from "@mui/material";
import { setFirestoreReadNews } from "../../firebase/firestore/organizations/readNews";
import { useTypedSelector } from '../../redux';
import { StateError } from '../../redux/error';
import { useDispatch } from 'react-redux';
import { SimpleBackdrop } from '../molecules/Backdrop';
import { getFirestoreOrganization } from '../../firebase/firestore/organizations';
import { Organization, ServiceType } from 'common-types/firebase/firestore/organizations';
import { setSelectedOrganization } from '../../redux/selectedOrganization';
import { TypeBOrganization } from 'common-types/firebase/firestore/typeBOrganization';
import { getFirestoreTypeBOrganization } from '../../firebase/firestore/typeBOrganizations';
import { StopOrganizationIcon } from "../../icons";
import { getFirestoreTransferOrganization } from "../../firebase/firestore/transferOrganizations";
import { TransferOrganization } from "common-types/firebase/firestore/transferOrganization";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { TopAlert } from "../../redux/topAlert";

type IconMenuProps = {}
type HeaderSubMenuProps = {
  anchorEl: null | HTMLElement;
  subMenus: Array<{ label: string; path: string }> | null;
  open: boolean;
  error: StateError[];
  handleClose: () => void;
  topAlert: TopAlert[];
};

export const MenuList = (props: any) => {
  const logined = props.logined;
  const menus = props.menus;
  const [menusList, setMenusList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const history = useHistory();
  const moveToPage = (item: any, index: any) => {
    if (item.text === "ログアウト") {
      logout();
    } else if (item.path.match(/http/)) {
      window.alert("外部のサイトが起動します");
      window.open(item.path, "_blank");
    } else {
      history.push(item.path);
      setSelectedIndex(index);
    }
  };
  const getMenusData = async () => {
    setMenusList(menus(logined ? logined : false));
  };
  useEffect(() => {
    getMenusData();
  }, []);
  return (
    <List component="nav" aria-label="main mailbox folders">
      {menusList
        ? menusList.length > 0
          ? menusList.map((item: any, index: any) => (
              <StyledItem
                onClick={() => moveToPage(item, index)}
                key={index}
                button
                selected={index === selectedIndex}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <BoldText primary={item.text} />
              </StyledItem>
            ))
          : null
        : null}
    </List>
  );
};

type DisplayOrganization = {
  organizationId: string;
  organizationName:string;
  serviceType: ServiceType;
  status:  string;
}

export const IconMenu: React.VFC<IconMenuProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [orgAnchorEl, setOrgAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [newsAnchorEl, setNewsAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [organizationsList, setOrganizationsList] = useState<
    Array<DisplayOrganization>
  >([]);
  const memberData = useTypedSelector((state) => state.memberData);
  const roles = memberData.roles;
  const organization = useTypedSelector((state) => state.organization);
  const typeBOrganization = useTypedSelector((state) => state.typeBOrganization )
  const transferOrganization = useTypedSelector((state) => state.transferOrganization )
  const selectedOrganization = useTypedSelector((state) => state.selectedOrganization);
  const selectedOrganizationId = selectedOrganization.organizationId;
  const selectedServiceType = selectedOrganization.serviceType;
  const news = useTypedSelector((state) => state.news);
  const initRead = useTypedSelector((state) => state.initRead);
  const readNews = useTypedSelector((state) => state.readNews);
  const notReadNews = useMemo(() => {
    return news.filter((doc) => !(readNews.find((n) => n.newsId === doc.newsId)))  ;
  },[news, readNews]);
  const open = Boolean(anchorEl);
  const openOrgMenu = Boolean(orgAnchorEl);
  const openNewsMenu = Boolean(newsAnchorEl);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const makeOrganizationList = async() =>{
      const aTypeOrganizations = roles.filter((role) => role.serviceType === 'aType');
      const bTypeOrganizations = roles.filter((role) => role.serviceType === 'bType');
      const transferOrganizations = roles.filter((role) => role.serviceType === 'transfer');
      let aOrgs = [{
        organizationId: '',
        organizationName: '',
        serviceType: 'aType',
        status: '',
      }] as  DisplayOrganization[];
      let bOrgs = [{
        organizationId: '',
        organizationName: '',
        serviceType: 'bType',
        status: '',
      }] as  DisplayOrganization[];
      let tOrgs = [{
        organizationId: '',
        organizationName: '',
        serviceType: 'transfer',
        status: '',
      }] as  DisplayOrganization[];

      if(aTypeOrganizations){
        aOrgs = await Promise.all(aTypeOrganizations.map(async(aTypeOrganization) => {
          const organizationDocumentSnapshot  = await getFirestoreOrganization(aTypeOrganization.organizationId);
          const organizationData = organizationDocumentSnapshot.data() as Organization;
          return {
            organizationId: aTypeOrganization.organizationId,
            organizationName: organizationData?.organizationName ?? "",
            serviceType: 'aType',
            status: organizationData?.status ?? "inActive"
          } as  DisplayOrganization;
        }));
      }
      if(bTypeOrganizations){
        bOrgs = await Promise.all(bTypeOrganizations.map(async(bTypeOrganization) => {
          const organizationDocumentSnapshot  = await getFirestoreTypeBOrganization(bTypeOrganization.organizationId);
          const organizationData = organizationDocumentSnapshot.data() as TypeBOrganization;
          return {
            organizationId: bTypeOrganization.organizationId,
            organizationName: organizationData?.organizationName ?? "",
            serviceType: 'bType',
            status: organizationData?.status ?? "inActive"
          } as  DisplayOrganization;
        }));
      }
      if(transferOrganizations){
        tOrgs = await Promise.all(transferOrganizations.map(async(transferOrganization) => {
          const organizationDocumentSnapshot  = await getFirestoreTransferOrganization(transferOrganization.organizationId);
          const organizationData = organizationDocumentSnapshot.data() as TransferOrganization;
          return {
            organizationId: transferOrganization.organizationId,
            organizationName: organizationData?.organizationName ?? "",
            serviceType: 'transfer',
            status: organizationData?.status ?? "inActive"
          } as  DisplayOrganization;
        }));
      }
      setOrganizationsList([...aOrgs, ...bOrgs, ...tOrgs].filter((doc) => doc.organizationId !== ''));
    }
    makeOrganizationList();
  }, [roles]);

  const accountName = useMemo(() => {
    const selectedOrganization = selectedServiceType === 'aType' ? organization 
      : selectedServiceType === 'bType' ? typeBOrganization 
      : selectedServiceType === 'transfer' ? transferOrganization
      : null;
    const isActive = (selectedOrganization && !(selectedOrganization.status === 'inActive')) ? true : false;
    const organizationName = (selectedOrganization && selectedOrganization.organizationName) ? selectedOrganization.organizationName : `名称未設定`;
    return <Typography style={{ fontWeight: "bold", fontSize: 13, letterSpacing: 0.5 }}>{memberData.name}（{organizationName}）{!isActive && <OrganizationStatusWrap style={{color: 'white'}}><div style={{marginLeft: 12,marginRight: 2, height: 22}}><StopOrganizationIcon/></div>利用停止中</OrganizationStatusWrap>}</Typography>;
  },[memberData.name, organization, selectedServiceType, transferOrganization, typeBOrganization]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseOrgMenu = () => {
    setOrgAnchorEl(null);
  };
  const onClickLogout = () => {
    logout();
    history.push("/");
    setAnchorEl(null);
  };
  const handleSelectOrganizationMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setOrgAnchorEl(event.currentTarget);
  };
  const changeOrganizationId = (organizationId: string, serviceType: ServiceType, status: string) => {
    if(serviceType === "") return;
    if(status === 'inActive'){
      window.alert('指定の事業所のアカウントは停止されています。復旧する場合は窓口までご連絡ください');
      return;
    }
    if(selectedOrganizationId === organizationId){
      setOrgAnchorEl(null);
      return;
    }
    setIsLoading(true);
    dispatch(setSelectedOrganization({
      organizationId: organizationId,
      serviceType: serviceType
    }));
    setOrgAnchorEl(null);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  const handleClickNewsIcon = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setNewsAnchorEl(event.currentTarget);
  };

  const newsList = useCallback(() => {
    return (news.length > 0 && news[0].newsId) ? (
      news.map((doc, index) => {
        if (index < 3) {
          const newsId = doc.newsId;
          const isExistReadNews = readNews.find((n) => n.newsId === newsId);
          return (
            <div key={index}>
              <MenuItem
                key={index}
                onClick={() => {
                  if(!isExistReadNews){
                    setFirestoreReadNews(selectedOrganizationId, doc.newsId, memberData.memberId, selectedServiceType);
                  }
                  if(doc.url){
                    window.open(doc.url, "_blank");
                    setNewsAnchorEl(null);
                  }
                }}
              >
                <MenuItemContent>
                {isExistReadNews ? <StyledMenuTitle>{doc.title}</StyledMenuTitle> :
                <StyledTextBadge variant="dot"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  >
                  <StyledMenuTitle>{doc.title}</StyledMenuTitle>
                  </StyledTextBadge>}
                  <StyledCaption component="div">
                    {doc.description}
                  </StyledCaption>
                </MenuItemContent>
              </MenuItem>
              <Divider />
              {index === 2 && news.length > 3 && (
              <MenuItem onClick={() =>{
                setNewsAnchorEl(null);
                history.push('/news');
              }}>
               <MenuItemContent>
               <div style={{display: 'flex', alignItems: 'center'}}>
               <StyledMenuTitle component="div" style={{fontWeight: 500, marginBottom: 0}}>
                {'お知らせ一覧を見る'}
                </StyledMenuTitle>
                <OpenInNewIcon style={{marginLeft: 8, height: 20, color: 'rgb(90, 89, 89)'}}/>
                </div>
                </MenuItemContent>
                </MenuItem>
              )}
            </div>
          );
        }
      })
    ) : (
      <MenuItem>
        <MenuItemContent>
          <StyledMenuTitle>{"お知らせはありません"}</StyledMenuTitle>
        </MenuItemContent>
      </MenuItem>
    );
  }, [history, memberData.memberId, news, readNews, selectedOrganizationId, selectedServiceType]);

  return (
    <StyledDiv>
      <CircledAvatar
        style={{ cursor: "pointer" }}
        src={""}
        onClick={(e) => handleClick(e)}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ display: "flex", marginTop: 14 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <MenuItem
          onClick={() => {
            history.push("/accountSetting");
            handleClose();
          }}
        >
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>アカウント設定</ListItemText>
        </MenuItem>
        <MenuItem onClick={onClickLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>ログアウト</ListItemText>
        </MenuItem>
      </Menu>
      <Typography style={{ fontWeight: "bold", fontSize: 13, letterSpacing: 0.5 }}>{accountName}</Typography>
      <div
        onClick={(e) => handleSelectOrganizationMenu(e)}
        style={{ height: 24, cursor: "pointer" }}
      >
        <KeyboardArrowDownIcon />
      </div>
      <Menu
        id="organizations-menu"
        anchorEl={orgAnchorEl}
        open={openOrgMenu}
        onClose={handleCloseOrgMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ display: "flex", marginTop: 14 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
       >
        {organizationsList.map((doc, index) =>{
          const organizationName =  doc.organizationName
              ? doc.organizationName
              : '名称未設定';
          const isActive = doc.status === "active" ? true : false;
          return (
            <MenuItem
              style={{display: 'flex', justifyContent: 'space-between'}}
              onClick={()=>changeOrganizationId(doc.organizationId, doc.serviceType, doc.status)}
              key={index}
            >{organizationName}{!isActive && <OrganizationStatusWrap><div style={{marginLeft: 12,marginRight: 2, height: 22}}><StopOrganizationIcon/></div>利用停止中</OrganizationStatusWrap>}</MenuItem>
          );
        })}
      </Menu>
      <IconButton
        style={{ width: 40, height: 40, color: "white" }}
        onClick={(e) => handleClickNewsIcon(e)}
      >
        {(notReadNews.length > 0 && initRead) ? (
          <StyledBadge badgeContent={notReadNews.length}>
            <NotificationsIcon />
          </StyledBadge>
        ) : (
          <NotificationsIcon />
        )}
      </IconButton>
      <Menu
        id="news-menu"
        anchorEl={newsAnchorEl}
        open={openNewsMenu}
        onClose={() => setNewsAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ display: "flex", marginTop: 14 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <ListItem>
          <StyledMenuTitle style={{ margin: "0 auto 4px auto" }}>
            {"Rakucoからのお知らせ"}
          </StyledMenuTitle>
        </ListItem>
        <Divider style={{margin: '8px 0'}}/>
        {newsList()}
      </Menu>
      <SimpleBackdrop open={isLoading} message={"組織の切り替え中..."} />
    </StyledDiv>
  );
};

export const HeaderSubMenus: React.VFC<HeaderSubMenuProps> = (props) => {
  const history = useHistory();
  const anchorEl = props.anchorEl;
  const open = props.open;
  const subMenus = props.subMenus;
  const error = props.error;
  // 事業所情報に関するアラート
  const topAlert = props.topAlert;
  const handleClose = props.handleClose;
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
        style: {
          display: "flex",
          padding: "36px 32px",
          flexWrap: "wrap",
        },
      }}
      style={{ display: "flex", marginTop: 14 }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      TransitionProps={{
        style: {
          borderRadius: 12,
          boxShadow: "0px 4px 12px rgba(155, 148, 148, 0.2)",
        },
      }}
    >
      {subMenus &&
        subMenus.map((subMenu) => {
          const handleSubMenu = () => {
            if (subMenu.path === "manual") {
              window.open(manualUrl, "_blank");
            } else if (subMenu.path === "form") {
              window.open(formUrl, "_blank");
            } else {
              history.push(subMenu.path);
            }
            handleClose();
          };
          return (
            <React.Fragment
            key={subMenu.label}
            >
              <MenuItem onClick={handleSubMenu}>
                {subMenu.label}
                {error.filter((e) => e.subMenu === subMenu.label).length || (subMenu.path === '/organization' && topAlert.length > 0 ) ? (
                  <StyledFiberManualRecordIcon />
                ) : (
                  <></>
                )}
              </MenuItem>
            </React.Fragment>
          );
        })}
    </Menu>
  );
};

const BoldText = styled(ListItemText)`
  > * {
    font-size: 14px;
    font-weight: bold;
  }
`;
const Div = styled.div`
  display: flex;
  align-items: flex-end;
`;

const StyledDiv = styled(Div)`
  align-items: center;
`;
const StyledItem = styled(ListItem)``;

const StyledFiberManualRecordIcon = styled(FiberManualRecordIcon)`
  color: rgb(255, 115, 116);
  width: 12px;
  height: 12px;
  margin: 0 0 auto;
`;

const StyledBadge = styled(Badge)`
& .MuiBadge-badge {
  background:${props => props.theme.palette.primary.main};
}
`;
const MenuItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
`;
const StyledMenuTitle = styled(Typography).attrs({
  component: "div",
  variant: "body2",
})`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-line;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 4px;
  letter-spacing: 0.15px;
`;
const StyledCaption = styled(Typography).attrs({
  component: "div",
  variant: "body2",
})`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-line;
  font-size: 12px;
  line-height: 16px;
  color: #5a5959;
`;

const StyledTextBadge = styled(Badge)`
& .MuiBadge-dot {
  top: 10px;
  left: -8px;
  background:${props => props.theme.palette.primary.main};
}
`;

const OrganizationStatusWrap = styled.div`
color: #70757A;
font-size: 14px;
line-height: 32px;
letter-spacing: 0.04em;
font-weight: 600;
display: flex;
align-items: center;

`;
