import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Groups } from 'common-types/firebase/firestore/organizations/groups';
import firebase from 'firebase';

export type StateGroup = Omit<Groups, 'createAt' | 'updateAt'> & {createAtMillis: number |  null, updateAtMillis: number | null, groupId: string}
export const initialState: StateGroup[] = []
const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroups: (_state: StateGroup[], action: PayloadAction<StateGroup[]>) => {
      return action.payload;
    },
    resetGroups: () => {
      return initialState;
    }
  }
});

export const { setGroups, resetGroups } = groupsSlice.actions;
export const groupsReducer = groupsSlice.reducer;

export const groupsConverter = {
  toFirestore(groups: StateGroup): firebase.firestore.DocumentData {
    let group = {
      groupName: groups.groupName,
      userIds: groups.userIds,
      createAt: groups.createAtMillis ? firebase.firestore.Timestamp.fromMillis(groups.createAtMillis) : firebase.firestore.Timestamp.now(),
      updateAt: firebase.firestore.Timestamp.now(),
      createdBy: groups.createdBy,
      updatedBy: groups.updatedBy,
      isDeleted: groups.isDeleted
    } as firebase.firestore.DocumentData;

    return group;
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): StateGroup {
    const data = snapshot.data(options)!;
    const group = {
      groupName: data.groupName,
      userIds: data.userIds,
      createAtMillis: data.createAt ? data.createAt.toMillis() : null,
      updateAtMillis: data.updateAt ? data.updateAt.toMillis() : null,
      createdBy: data.createdBy,
      updatedBy: data.updatedBy,
      isDeleted: data.isDeleted,
      groupId: snapshot.id
    };
    return group;
  }
}

