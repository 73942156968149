import { ServiceType } from "common-types/firebase/firestore/organizations";

export const getSelectedUsage = (serviceType: ServiceType) => {
  switch (serviceType) {
    case 'aType':
      return 'usages';
    case 'bType':
      return 'typeBUsages';
    case 'transfer':
      return 'transferUsages';
    default:
      console.error(`invalid serviceType in getSelectedUsage:${serviceType}`);
      return '';
  }
};