import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkingHours } from 'common-types/firebase/firestore/organizations/settings';

export type StateWorkingHours = Omit<WorkingHours, "createAt" | "updateAt"> & {
  createAtMillis: number;
  updateAtMillis: number;
};

const initialState: StateWorkingHours = {
  unitTime: 0,
  workTime: {
    startTime: '00:00',
    endTime: '00:00'
  },
  breakTimes: [],
  hasDefaultSettingForWorkAndBreakEachDay: false,
  sunday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  monday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  tuesday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  wednesday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  thursday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  friday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  saturday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  autoInputUsers: [],
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const workingHoursSlice = createSlice({
  name: 'workingHours',
  initialState,
  reducers: {
    setWorkingHours: (_state: StateWorkingHours, action: PayloadAction<StateWorkingHours>) => {
      return action.payload;
    },
    resetWorkingHours: () => {
      return initialState;
    }
  }
});

export const { setWorkingHours, resetWorkingHours} = workingHoursSlice.actions;
export const workingHoursReducer = workingHoursSlice.reducer;
