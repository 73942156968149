import dayjs from "../lib/dayjs";
import { StateError, Message } from "../redux/error";
import { StateTransferUsageRecord } from "../redux/transferUsageRecords";
import { StateUsageRecord } from "../redux/usageRecords";
import {
  isAfterAttendanceRecordStartAtThanWorkRecordStartAt,
  isBeforeAttendanceRecordEndAtThanWorkRecordEndAt,
  getInvalidBreakTimeStartAtMillis,
  getInvalidBreakTimeEndAtMillis,
} from "../validation/usageRecord";

type PropsUsageRecord = {
  userId: string;
  userName: string;
  usageRecord: StateUsageRecord;
};

type PropsTypeBUsageRecord = {
  userId: string;
  userName: string;
  usageRecord: StateUsageRecord;
};

type PropsTransferUsageRecord = {
  userId: string;
  userName: string;
  usageRecord: StateTransferUsageRecord;
};

export const checkUsageRecord = (props: PropsUsageRecord) => {
  let messages: Message[] = [];
  const { userId, userName, usageRecord } = props;
  messages = messages.concat(
    checkTypeAUsageRecordItems(userId, userName, usageRecord)
  );
  const error = messages.map((m) => {
    return { mainMenu: "実績", message: m } as StateError;
  });
  return error;
};

export const checkTypeBUsageRecord = (props: PropsTypeBUsageRecord) => {
  let messages: Message[] = [];
  const { userId, userName, usageRecord } = props;
  messages = messages.concat(
    checkTypeBUsageRecordItems(userId, userName, usageRecord)
  );
  const error = messages.map((m) => {
    return { mainMenu: "実績", message: m } as StateError;
  });
  return error;
};

export const checkTransferUsageRecord = (props: PropsTransferUsageRecord) => {
  let messages: Message[] = [];
  const { userId, userName, usageRecord } = props;
  messages = messages.concat(
    checkTransferUsageRecordItems(userId, userName, usageRecord)
  );
  const error = messages.map((m) => {
    return { mainMenu: "実績", message: m } as StateError;
  });
  return error;
};

const checkTypeAUsageRecordItems = (
  userId: string,
  userName: string,
  usageRecord: StateUsageRecord
) => {
  let messages = checkCommonUsageRecordItems(userId, userName, usageRecord);
  return messages;
};

const checkTypeBUsageRecordItems = (
  userId: string,
  userName: string,
  usageRecord: StateUsageRecord
) => {
  let messages = checkCommonUsageRecordItems(userId, userName, usageRecord);
  return messages;
};

const checkTransferUsageRecordItems = (
  userId: string,
  userName: string,
  usageRecord: StateTransferUsageRecord
) => {
  let messages = checkCommonUsageRecordItems(userId, userName, usageRecord);
  return messages;
};

const checkCommonUsageRecordItems = (
  userId: string,
  userName: string,
  usageRecord: StateUsageRecord | StateTransferUsageRecord
) => {
  const messages: Message[] = [];
  if (!usageRecord.serviceProvisionStatus) {
    messages.push({
      text: `提供サービスが登録されていません（${userName}さん, ${dayjs(
        usageRecord.attendanceRecord.startAtMillis
      ).format("YYYY年M月D日")}）`,
      url: `usageRecords?tab=user&userId=${userId}&month=${dayjs(
        usageRecord?.attendanceRecord.startAtMillis
      ).format("YYYY/MM")}`,
      type: "error",
    });
  }
  if (!usageRecord.attendanceRecord.startAtMillis) {
    messages.push({
      text: `開始時間が登録されていません（${userName}さん, ${dayjs(
        usageRecord.attendanceRecord.startAtMillis
      ).format("YYYY年M月D日")}）`,
      url: `organization/usageRecords?tab=user&userId=${userId}&month=${dayjs(
        usageRecord?.attendanceRecord.startAtMillis
      ).format("YYYY/MM")}`,
      type: "error",
    });
  }
  if (
    [
      "comingToOffice",
      "supportForTraining",
      "workingOutsideOfOffice",
      "visitingToUser",
    ].includes(usageRecord.serviceProvisionStatus) &&
    !usageRecord.attendanceRecord.endAtMillis &&
    usageRecord.attendanceRecord.startAtMillis
  ) {
    if (
      (dayjs(usageRecord.attendanceRecord.startAtMillis).diff(
        dayjs(),
        "day"
      ) === 0 &&
        Number(dayjs().format("HH")) > 18) ||
      dayjs(usageRecord.attendanceRecord.startAtMillis).diff(dayjs(), "day") < 0
    ) {
      messages.push({
        text: `終了時間が登録されていません（${userName}さん, ${dayjs(
          usageRecord.attendanceRecord.startAtMillis
        ).format("YYYY年M月D日")}）`,
        url: `usageRecords?tab=user&userId=${userId}&month=${dayjs(
          usageRecord?.attendanceRecord.startAtMillis
        ).format("YYYY/MM")}`,
        type: "error",
      });
    }
  }
  if (!usageRecord.attendanceRecord.breakTime) {
    messages.push({
      text: `休憩時間が正しく保存されていません（${userName}さん, ${dayjs(
        usageRecord.attendanceRecord.startAtMillis
      ).format("YYYY年M月D日")}）`,
      url: `organization/usageRecords?tab=user&userId=${userId}&month=${dayjs(
        usageRecord?.attendanceRecord.startAtMillis
      ).format("YYYY/MM")}`,
      type: "error",
    });
  }
  if (!usageRecord.medicalCooperation) {
    messages.push({
      text: `医療連携体制情報が登録されていません（${userName}さん, ${dayjs(
        usageRecord.attendanceRecord.startAtMillis
      ).format("YYYY年M月D日")}）`,
      url: `organization/usageRecords?tab=user&userId=${userId}&month=${dayjs(
        usageRecord?.attendanceRecord.startAtMillis
      ).format("YYYY/MM")}`,
      type: "error",
    });
  }
  if (
    usageRecord.serviceProvisionStatus === "supportForExperienceUser" &&
    !usageRecord.supportForExperienceUserType
  ) {
    messages.push({
      text: `体験利用支援種別が登録されていません（${userName}さん, ${dayjs(
        usageRecord.attendanceRecord.startAtMillis
      ).format("YYYY年M月D日")}）`,
      url: `usageRecords?tab=user&userId=${userId}&month=${dayjs(
        usageRecord?.attendanceRecord.startAtMillis
      ).format("YYYY/MM")}`,
      type: "error",
    });
  }
  if (
    isAfterAttendanceRecordStartAtThanWorkRecordStartAt(usageRecord) ||
    isBeforeAttendanceRecordEndAtThanWorkRecordEndAt(usageRecord)
  ) {
    messages.push({
      text: `作業時間が時間内に設定されていません。（${userName}さん, ${dayjs(
        usageRecord.attendanceRecord.startAtMillis
      ).format("YYYY年M月D日")}）`,
      url: `usageRecords?tab=user&userId=${userId}&month=${dayjs(
        usageRecord?.attendanceRecord.startAtMillis
      ).format("YYYY/MM")}`,
      type: "error",
    });
  }
  const startAtBreaktimeIndexies =
    getInvalidBreakTimeStartAtMillis(usageRecord);
  const endAtBreaktimeIndexies = getInvalidBreakTimeEndAtMillis(usageRecord);
  if (
    startAtBreaktimeIndexies.length > 0 ||
    endAtBreaktimeIndexies.length > 0
  ) {
    messages.push({
      text: `休憩時間が作業時間内に設定されていません。（${userName}さん, ${dayjs(
        usageRecord.attendanceRecord.startAtMillis
      ).format("YYYY年M月D日")}）`,
      url: `usageRecords?tab=user&userId=${userId}&month=${dayjs(
        usageRecord?.attendanceRecord.startAtMillis
      ).format("YYYY/MM")}`,
      type: "error",
    });
  }
  if (!!usageRecord.note && usageRecord.note.length > 50) {
    messages.push({
      text: `備考は50文字以内で入力してください。（${userName}さん, ${dayjs(
        usageRecord.attendanceRecord.startAtMillis
      ).format("YYYY年M月D日")}）`,
      url: `usageRecords?tab=user&userId=${userId}&month=${dayjs(
        usageRecord?.attendanceRecord.startAtMillis
      ).format("YYYY/MM")}`,
      type: "error",
    });
  }
  if (usageRecord.serviceProvisionStatus==="supportForAbsence" && usageRecord.note.length === 0) {
    messages.push({
      text: `欠席時対応の理由が記載されていません。（${userName}さん, ${dayjs(
        usageRecord.attendanceRecord.startAtMillis
      ).format("YYYY年M月D日")}）`,
      url: `usageRecords?tab=user&userId=${userId}&month=${dayjs(
        usageRecord?.attendanceRecord.startAtMillis
      ).format("YYYY/MM")}`,
      type: "error",
    });
  }
  return messages;
};
