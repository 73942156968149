import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransferSubtractingRewardItem } from 'common-types/firebase/firestore/transferOrganization/settings';

export type StateVacantPosition = {
  isVacancyOfStaff: boolean, 
  position: string, 
  startAtMillis: number
};

export type StateTransferSubtractingRewardItem = Omit<TransferSubtractingRewardItem, 'vacantPosition' | 'createAt' | 'updateAt'> & { 
  vacantPosition: StateVacantPosition,
  createAtMillis: number, 
  updateAtMillis: number 
};
const initialState: StateTransferSubtractingRewardItem = {
  isInstalledByLocalGovernment: false,
  vacantPosition: {
    isVacancyOfStaff: false,
    position: '',
    startAtMillis: 0,
  },
  isNotAbolishedPhysicalRestraint: false,
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const transferSubtractingRewardItemSlice = createSlice({
  name: 'transferSubtractingRewardItem',
  initialState,
  reducers: {
    setTransferSubtractingRewardItem: (_state: StateTransferSubtractingRewardItem, action: PayloadAction<StateTransferSubtractingRewardItem>) => {
      return action.payload;
    },
    resetTransferSubtractingRewardItem: () => {
      return initialState;
    }
  }
});

export const { setTransferSubtractingRewardItem, resetTransferSubtractingRewardItem } = transferSubtractingRewardItemSlice.actions;
export const transferSubtractingRewardItemReducer = transferSubtractingRewardItemSlice.reducer;