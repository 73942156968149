import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InterviewRecord } from 'common-types/firebase/firestore/organizations/interviewRecords';

export type StateInterviewRecord = Omit<InterviewRecord, 'attendanceRecord' | 'interviewRecord' | 'createAt' | 'updateAt'> &
  { attendanceRecord: {startAtMillis: number, endAtMillis: number | null}, 
    interviewRecord: {startAtMillis: number | null, endAtMillis: number | null}, 
    createAtMillis: number, 
    updateAtMillis: number,
    interviewRecordId: string
  };
export const initialState: StateInterviewRecord[] = [{
  userId: '',
  attendanceRecord: {
    startAtMillis: 0,
    endAtMillis: null
  },
  serviceProvisionStatus: '',
  interviewRecord: {
    startAtMillis: null,
    endAtMillis: null,
  },
  interviewContent: '',
  recorderName: '',
  recorderId: '',
  supportRecordId: '',
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
  interviewRecordId: ''
}];

const interviewRecordsSlice = createSlice({
  name: 'interviewRecords',
  initialState,
  reducers: {
    setInterviewRecords: (_state: StateInterviewRecord[], action: PayloadAction<StateInterviewRecord[]>) => {
      return action.payload;
    },
    resetInterviewRecords: () => {
      return initialState;
    }
  }
});

export const { setInterviewRecords, resetInterviewRecords } = interviewRecordsSlice.actions;
export const interviewRecordsReducer = interviewRecordsSlice.reducer;