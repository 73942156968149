import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Invoice } from 'common-types/firebase/firestore/organizations/invoices';

export type StateInvoice = Omit<Invoice, 'targetMonth' | 'createAt' | 'updateAt'> &
{ targetMonthMillis: number | undefined, createAtMillis: number | undefined, updateAtMillis: number | undefined };
const initialState: StateInvoice[] = [{
  targetMonthMillis: undefined,
  fileType: 'recordSheetForServiceProvision',
  createAtMillis: undefined,
  updateAtMillis: undefined,
}];

const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setInvoices: (_state: StateInvoice[], action: PayloadAction<StateInvoice[]>) => {
      return action.payload;
    },
    resetInvoices: () => {
      return [{
        targetMonthMillis: undefined,
        fileType: 'recordSheetForServiceProvision',
        createAtMillis: undefined,
        updateAtMillis: undefined,
      }];
    }
  }
});

export const { setInvoices, resetInvoices } = invoicesSlice.actions;
export const invoicesReducer = invoicesSlice.reducer;