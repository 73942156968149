import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TransferUsageRecord } from "common-types/firebase/firestore/transferOrganization/usageRecords";
import { WorkRecord } from "./usageRecords";

export type AttendanceRecordBreakTime = {
  startAtMillis: number;
  endAtMillis: number | null;
};

export type StateTransferUsageRecord = Omit<
  TransferUsageRecord,
  "attendanceRecord" | "createAt" | "updateAt" | "workRecord"
> & {
  attendanceRecord: {
    startAtMillis: number;
    endAtMillis: number | null;
    breakTime: AttendanceRecordBreakTime[];
  };
  workRecord: WorkRecord;
  createAtMillis: number;
  updateAtMillis: number;
  usageRecordId: string;
  operatedBy?: "otr" | "member_front" | "user_front";
};
export const initialState: StateTransferUsageRecord = {
  userId: "",
  serviceProvisionStatus: "comingToOffice",
  attendanceRecord: {
    startAtMillis: 0,
    endAtMillis: null,
    breakTime: [],
  },
  visitSupport: "",
  medicalCooperation: {
    supportType: 0,
    wasTookLessonForSputumSuction: false,
  },
  note: "",
  workRecord: {
    wasWorked: false,
    startAtMillis: null,
    endAtMillis: null,
  },
  memo: "",
  supportForExperienceUserType: 1,
  isCommunityLifeSupportBase: false,
  wasTookSupportForCommuting: false,
  hasLocalCooperationMeeting: false,
  hasMentalHealthDischargeSupportFacility: 0,
  foodservice: false,
  transferService: "none",
  transferWithSameSite: "none",
  wasTookSupportForHouseLife: false,
  wasTookSupportForSocialLife: false,
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: "",
  updatedBy: "",
  isDeleted: false,
  usageRecordId: "",
};
const initialStateList: StateTransferUsageRecord[] = [initialState];

const transferUsageRecordsSlice = createSlice({
  name: "transferUsageRecords",
  initialState: initialStateList,
  reducers: {
    resetTransferUsageRecords: (): StateTransferUsageRecord[] => {
      return initialStateList;
    },
    setTransferUsageRecords: (
      _state: StateTransferUsageRecord[],
      action: PayloadAction<StateTransferUsageRecord[]>
    ) => {
      return action.payload;
    },
    updateTransferUsageRecord: (
      state: StateTransferUsageRecord[],
      action: PayloadAction<StateTransferUsageRecord[]>
    ) => {
      const newState = state.map((stateUsageRecord) => {
        const targetUsageRecord = action.payload.find(
          (usageRecord) => usageRecord.usageRecordId === stateUsageRecord.usageRecordId
        );
        if (targetUsageRecord) {
          return targetUsageRecord;
        }
        return stateUsageRecord;
      });
      action.payload.forEach((usageRecord) => {
        if (!state.find((stateUsageRecord) => stateUsageRecord.usageRecordId === usageRecord.usageRecordId)) {
          newState.push(usageRecord);
        }
      });
      return newState;
  },
}});

export const { setTransferUsageRecords, resetTransferUsageRecords, updateTransferUsageRecord } = transferUsageRecordsSlice.actions;
export const transferUsageRecordsReducer = transferUsageRecordsSlice.reducer;
