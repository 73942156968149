import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubtractingRewardItem } from 'common-types/firebase/firestore/organizations/settings';

export type StateVacantPosition = {
  isVacancyOfStaff: boolean, 
  position: string, 
  startAtMillis: number
};

export type StateSubtractingRewardItem = Omit<SubtractingRewardItem, 'vacantPosition' | 'createAt' | 'updateAt'> & { 
  vacantPosition: StateVacantPosition,
  createAtMillis: number, 
  updateAtMillis: number 
};
const initialState: StateSubtractingRewardItem = {
  isInstalledByLocalGovernment: false,
  vacantPosition: {
    isVacancyOfStaff: false,
    position: '',
    startAtMillis: 0,
  },
  isUnpublishedSelfAssessment: false,
  isNotAbolishedPhysicalRestraint: false,
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const subtractingRewardItemSlice = createSlice({
  name: 'subtractingRewardItem',
  initialState,
  reducers: {
    setSubtractingRewardItem: (_state: StateSubtractingRewardItem, action: PayloadAction<StateSubtractingRewardItem>) => {
      return action.payload;
    },
    resetSubtractingRewardItem: () => {
      return initialState;
    }
  }
});

export const { setSubtractingRewardItem, resetSubtractingRewardItem } = subtractingRewardItemSlice.actions;
export const subtractingRewardItemReducer = subtractingRewardItemSlice.reducer;