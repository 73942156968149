import { lazy, Suspense, useCallback, useEffect, useMemo, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useTypedSelector } from '../../redux';
import { useLocation, useHistory } from 'react-router-dom';

import LinearProgress from '@material-ui/core/LinearProgress';
import { handlePathChange } from '../../utils/handleChangeOrganiztion';
import { SimpleBackdrop } from '../molecules/Backdrop';
import React from 'react';

const LoginPage = lazy(() => import('./LoginPage'));
const OrganizationPage = lazy(() => import('./organizations/OrganizationPage'));
const UsersPage = lazy(()=> import('./users/UsersPage'));
const UserDetailPage = lazy(() => import('./users/UserDetailPage'));
const UserBasicInfoPage = lazy(()=> import('./users/UserBasicInfoPage'));
const MembersPage = lazy(() => import('./members/MembersPage'));
const WorkItemsPage = lazy(() => import('./organizations/workItems/WorkItemsPage'));
const UsageRecordsPage = lazy(() => import('./organizations/usageRecords/UsageRecordsPage'));
const UsageRecordsSummaryPage = lazy(() => import('./organizations/usageRecords/UsageRecordsSummaryPage'));
const SupportRecordsPage = lazy(() => import('./organizations/supportRecords/SupportRedcordsPage'));
const EditSupportRecordsPage = lazy(() => import('./organizations/supportRecords/EditSupportRecordsPage'));
const EditSupportPlanPage = lazy(() => import('./users/EditSupportPlanPage'));
const CreateSupportPlanPage = lazy(() => import('./users/CreateSupportPlanPage'));
const CreateAssessmentPage = lazy(() => import('./users/CreateAssessmentPage'));
const EditAssessmentPage = lazy(() => import('./users/EditAssessmentPage'));
const AssessmentPreviewPage = lazy(() => import('./users/AssessmentPreviewPage'));
const EditUsageRecordsPage = lazy(() => import('./organizations/usageRecords/EditUsageRecordsPage'));
const WorkRecordsPage = lazy(() => import('./organizations/workRecords/WorkRecordsPage'));
const EditWorkRecordsPage = lazy(() => import('./organizations/workRecords/EditWorkRecordsPage'));
const BusinessLogsPage = lazy(() => import('./organizations/businessLogs/BusinessLogsPage'));
const EditBusinessLogsPage = lazy(() => import('./organizations/businessLogs/EditBusinessLogsPage'));
const EditSupportPlanReviewPage = lazy(() => import('./users/EditSupportPlanReviewPage'));
const EditWorkplaceCompanyPage = lazy(() => import('./organizations/workplaceCompanies/EditWorkplaceCompanyPage'));
const SupportPlanReviewPage = lazy(() => import('./users/SupportPlanReviewPage'));
const WorkplaceCompaniesPage = lazy(() => import('./organizations/workplaceCompanies/WorkplaceCompaniesPage'));
const InitialSettingPage = lazy(() => import('./organizations/settings/InitialSettingPage'));
const RegisterCardPage = lazy(() => import('./organizations/RegisterCardPage'));
const CreateLinkPage = lazy(() => import('./organizations/CreateLinkPage'));
const OTRPage = lazy(() => import('./organizations/OTRPage'));
const EditInterviewPage = lazy(() => import('./users/EditInterviewRecordPage'));
const EachRecordPage = lazy(() => import('./organizations/EachRecordPage'));
const WorkPlaceCompaniesRecordPage = lazy(() => import('./organizations/workplaceCompanies/WorkPlaceCompaniesRecordPage'));
const BenefitRecordPage = lazy(() => import('./organizations/BenefitRecordPage'));
const PreviewPage = lazy(() => import('./PreviewPage'));
const BusinessLogsPreviewPage = lazy(() => import('./organizations/businessLogs/BusinessLogsPreviewPage'));
const WorkPlaceCompaniesRecordPreviewPage = lazy(() => import("./organizations/workplaceCompanies/WorkPlaceCompaniesRecordPreviewPage"));
const GroupEditPage = lazy(() => import('./organizations/groups/GroupEditPage'));
const AccountSettingPage = lazy(() => import('./members/AccountSettingPage'));
const NotFoundPage = lazy(() => import('./NotFoundPage'));
const NewsPage = lazy(() => import('./NewsPage'));

type Page = {
  path:string;
  component:JSX.Element;
  isPubulic:boolean;
}

export const pages: Page[] = [
  { path: "/", component: <UsageRecordsPage />, isPubulic: false },
  { path: "/login", component: <LoginPage />, isPubulic: true },
  { path: "/organization", component: <OrganizationPage />, isPubulic: false },
  {
    path: "/organization/usageRecords",
    component: <UsageRecordsPage />,
    isPubulic: false,
  },
  {
    path: "/organization/usageRecords/summary",
    component: <UsageRecordsSummaryPage />,
    isPubulic: false,
  },
  {
    path: "/organization/usageRecords/edit",
    component: <EditUsageRecordsPage />,
    isPubulic: false,
  },
  { path: "/workRecords", component: <WorkRecordsPage />, isPubulic: false },
  {
    path: "/edit-workRecords",
    component: <EditWorkRecordsPage />,
    isPubulic: false,
  },
  {
    path: "/organization/supportRecords",
    component: <SupportRecordsPage />,
    isPubulic: false,
  },
  {
    path: "/organization/edit-supportRecords",
    component: <EditSupportRecordsPage />,
    isPubulic: false,
  },
  { path: "/businessLogs", component: <BusinessLogsPage />, isPubulic: false },
  {
    path: "/businessLogs/edit",
    component: <EditBusinessLogsPage />,
    isPubulic: false,
  },
  {
    path: "/businessLogsPreview",
    component: <BusinessLogsPreviewPage />,
    isPubulic: false,
  },
  {
    path: "/workplaceCompanies",
    component: <WorkplaceCompaniesPage />,
    isPubulic: false,
  },
  {
    path: "/edit-workplaceCompany/:id",
    component: <EditWorkplaceCompanyPage />,
    isPubulic: false,
  },
  {
    path: "/create-workplaceCompany",
    component: <EditWorkplaceCompanyPage />,
    isPubulic: false,
  },
  { path: "/workItems", component: <WorkItemsPage />, isPubulic: false },
  { path: "/users", component: <UsersPage />, isPubulic: false },
  { path: "/users/:id", component: <UserDetailPage />, isPubulic: false },
  {
    path: "/users/:userId/newSupportPlans",
    component: <CreateSupportPlanPage />,
    isPubulic: false,
  },
  {
    path: "/users/:userId/newAssessments",
    component: <CreateAssessmentPage />,
    isPubulic: false,
  },
  {
    path: "/users/:userId/assessments/:assessmentId",
    component: <AssessmentPreviewPage />,
    isPubulic: false,
  },
  {
    path: "/users/:userId/supportPlans/:supportPlanId",
    component: <SupportPlanReviewPage />,
    isPubulic: false,
  },
  {
    path: "/users/:userId/supportPlans/:supportPlanId/edit",
    component: <EditSupportPlanPage />,
    isPubulic: false,
  },
  {
    path: "/users/:userId/assessments/:assessmentId/edit",
    component: <EditAssessmentPage />,
    isPubulic: false,
  },
  {
    path: "/users/:id/edit-interviews",
    component: <EditInterviewPage />,
    isPubulic: false,
  },
  {
    path: "/users/:userId/supportPlanReviews/:supportPlanReviewId/edit",
    component: <EditSupportPlanReviewPage />,
    isPubulic: false,
  },
  {
    path: "/users/:id/basicInfo",
    component: <UserBasicInfoPage />,
    isPubulic: false,
  },
  { path: "/create-user", component: <UserBasicInfoPage />, isPubulic: false },
  { path: "/members", component: <MembersPage />, isPubulic: false },
  {
    path: "/initialSetting",
    component: <InitialSettingPage />,
    isPubulic: false,
  },
  { path: "/register-card", component: <RegisterCardPage />, isPubulic: false },
  { path: "/create-link", component: <CreateLinkPage />, isPubulic: false },
  { path: "/otr", component: <OTRPage />, isPubulic: true },
  { path: "/eachRecord", component: <EachRecordPage />, isPubulic: false },
  { path: "/preview", component: <PreviewPage />, isPubulic: false },
  {
    path: "/workplaceCompanyRecord",
    component: <WorkPlaceCompaniesRecordPage />,
    isPubulic: false,
  },
  {
    path: "/workplaceCompanyRecordPreview",
    component: <WorkPlaceCompaniesRecordPreviewPage />,
    isPubulic: false,
  },
  {
    path: "/benefitRecord",
    component: <BenefitRecordPage />,
    isPubulic: false,
  },
  { path: "/groups", component: <GroupEditPage />, isPubulic: false },
  {
    path: "/accountSetting",
    component: <AccountSettingPage />,
    isPubulic: false,
  },
  {
    path: "/news",
    component: <NewsPage />,
    isPubulic: false,
  }
];

export const Pages = React.memo(() => {
  const logined = useTypedSelector((state) => state.logined);
  const initRead = useTypedSelector((state) => state.initRead);
  let renderFlgRef = useRef(false)
  const location = useLocation();
  const history = useHistory();
  const memberData = useTypedSelector((state) => state.memberData);
  const selectedOrganization = useTypedSelector((state) => state.selectedOrganization);
  const selectedOrganizationId = selectedOrganization.organizationId;
  
  const role = useMemo(() => {
    const selectedOrganizationId = selectedOrganization.organizationId;
    return memberData.roles.find((doc) => doc.organizationId === selectedOrganizationId)?.role;
  }, [memberData, selectedOrganization]);


  useEffect(() => {
    renderFlgRef.current = true;
  }, []);

  useEffect(() =>{
    if(renderFlgRef.current){
      renderFlgRef.current = false
    } else {
      const query = new URLSearchParams(location.search);
      const newPath = handlePathChange(location.pathname, query);
      if(newPath){
        history.push(newPath);
      }
    }
  },[selectedOrganizationId]);

  const checkAuth = useCallback((isPublic:boolean,page:any, logined: boolean) => {
    if(isPublic){
      return page;
    }else{
      if(logined){
        if(role){
          return (logined && ["owner", "operator", "manager"].includes(role)) ? page : <LoginPage/>;
        } else {
          return  <></>
        }
      } else {
        return <LoginPage/>
      }
    }
  }, [role]);
  return (
    <Switch>
      {initRead ? pages.map((item, index) => (
        <Route
          key={index}
          exact
          path={item.path}
          component={() => <Suspense fallback={<LinearProgress />}>{checkAuth(item.isPubulic, item.component, logined)}</Suspense>}
        />
      )) : <SimpleBackdrop open={true} message={'読み込み中..'}/>}
      <Route component={() => <NotFoundPage/>} />
    </Switch>
  );
});
