import { createSlice } from '@reduxjs/toolkit';

const initReadSlice = createSlice({
  name: 'initRead',
  initialState: false,

  reducers: {
    setRead: () => {
      return true;
    },
  }
});

export const { setRead } = initReadSlice.actions;
export const initReadReducer = initReadSlice.reducer;
