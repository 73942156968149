import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransferBasicReward } from 'common-types/firebase/firestore/transferOrganization/settings';

export type StateTransferBasicReward = Omit<TransferBasicReward, 'createAt' | 'updateAt'> & { 
  createAtMillis: number, 
  updateAtMillis: number 
};

const initialState: StateTransferBasicReward = {
  serviceType: 1,
  retentionRate: 0,
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const transferBasicRewardSlice = createSlice({
  name: 'transferBasicReward',
  initialState,
  reducers: {
    setTransferBasicReward: (_state: StateTransferBasicReward, action: PayloadAction<StateTransferBasicReward>) => {
      return action.payload;
    },
    resetTransferBasicReward: () => {
      return initialState;
    }
  }
});

export const { setTransferBasicReward, resetTransferBasicReward } = transferBasicRewardSlice.actions;
export const transferBasicRewardReducer = transferBasicRewardSlice.reducer;