import { zenkakuRegex } from ".";
import { StateTransferUsageRecord } from "../redux/transferUsageRecords";
import { StateUsageRecord } from "../redux/usageRecords";
import { ValidationModel } from "../types/validation";

export type usageRecordValidationModel = ValidationModel & {
  index: number;
  customeIndex?: number;
};

export const createValidation = (
  usageRecords: (StateUsageRecord | StateTransferUsageRecord)[]
): usageRecordValidationModel[] => {
  let messages: usageRecordValidationModel[] = [];
  usageRecords.forEach((u, index) => {
    if (isAfterAttendanceRecordStartAtThanWorkRecordStartAt(u)) {
      messages = [
        ...messages,
        {
          key: "workRecordStartAtMillis",
          message: "作業開始時間は開始時間よりも後に設定してください",
          index,
        },
      ];
    }
  });
  usageRecords.forEach((u, index) => {
    if (isBeforeAttendanceRecordEndAtThanWorkRecordEndAt(u)) {
      messages = [
        ...messages,
        {
          key: "workRecordEndAtMillis",
          message: "作業終了時間は終了時間よりも前に設定してください",
          index,
        },
      ];
    }
  });
  usageRecords.forEach((u, index) => {
    if (u.serviceProvisionStatus === "") {
      messages = [
        ...messages,
        {
          key: "serviceProvisionStatus",
          message: "必須です",
          index,
        },
      ];
    }
  });
  usageRecords.forEach((u, index) => {
    if (
      !isDisabledAttendaceRecord(u) &&
      u.attendanceRecord.startAtMillis === 0
    ) {
      messages = [
        ...messages,
        {
          key: "startAtMillis",
          message: "必須です",
          index,
        },
      ];
    }
  });
  usageRecords.forEach((u, index) => {
    u.attendanceRecord.breakTime.forEach((b, breakIndex) => {
      if (b.endAtMillis && b.startAtMillis > b.endAtMillis) {
        messages = [
          ...messages,
          {
            key: "breakTimeEndAtMillis",
            message: "休憩終了時間は休憩開始時間よりも後に設定してください",
            index,
            customeIndex: breakIndex,
          },
        ];
      }
    });
  });
  usageRecords.forEach((u, index) => {
    if (
      isDisplayWorkRecord(u) &&
      u.workRecord.wasWorked &&
      u.attendanceRecord.startAtMillis !== 0 &&
      u.attendanceRecord.endAtMillis &&
      u.attendanceRecord.startAtMillis > u.attendanceRecord.endAtMillis
    ) {
      messages = [
        ...messages,
        {
          key: "endAtMillis",
          message: "終了時間は開始時間よりも後に設定してください",
          index,
        },
      ];
    }
  });
  usageRecords.forEach((u, index) => {
    if (
      isDisplayWorkRecord(u) &&
      u.workRecord.wasWorked &&
      u.workRecord.startAtMillis !== 0 &&
      u.workRecord.endAtMillis &&
      u.workRecord.startAtMillis &&
      u.workRecord.startAtMillis > u.workRecord.endAtMillis
    ) {
      messages = [
        ...messages,
        {
          key: "workRecordEndAtMillis",
          message: "作業終了時間は作業開始時間よりも後に設定してください",
          index,
        },
      ];
    }
  });
  usageRecords.forEach((u, index) => {
    if (
      !isDisabledAttendaceRecord(u) &&
      u.attendanceRecord.startAtMillis !== 0 &&
      u.attendanceRecord.endAtMillis &&
      u.attendanceRecord.startAtMillis > u.attendanceRecord.endAtMillis
    ) {
      messages = [
        ...messages,
        {
          key: "endAtMillis",
          message: "終了時間は開始時間よりも後に設定してください",
          index,
        },
      ];
    }
  });
  usageRecords.forEach((u, index) => {
    if (u.serviceProvisionStatus === "supportForAbsence" && u.note === "") {
      messages = [
        ...messages,
        {
          key: "note",
          message: "欠席時対応の理由を記載してください",
          index,
        },
      ];
    }
  });
  usageRecords.forEach((u, index) => {
    if (u.note && !zenkakuRegex.test(u.note)) {
      messages = [
        ...messages,
        {
          key: "note",
          message: "全角で入力してください",
          index,
        },
      ];
    }
  });
  usageRecords.forEach((u, index) => {
    if (!isDisabledAttendaceRecord(u) && !!u.note && u.note.length > 50) {
      messages = [
        ...messages,
        {
          key: "note",
          message: "50文字以内で入力してください",
          index,
        },
      ];
    }
  });
  usageRecords.forEach((u, index) => {
    const breakIndexies = getInvalidBreakTimeStartAtMillis(u);
    breakIndexies.forEach((breakIndex) => {
      messages = [
        ...messages,
        {
          key: "breakTimeStartAtMillis",
          message: "休憩開始時間は作業開始時間よりも後に設定してください",
          index,
          customeIndex: breakIndex,
        },
      ];
    });
  });
  usageRecords.forEach((u, index) => {
    const breakIndexies = getInvalidBreakTimeEndAtMillis(u);
    breakIndexies.forEach((breakIndex) => {
      messages = [
        ...messages,
        {
          key: "breakTimeEndAtMillis",
          message: "休憩終了時間は作業終了時間よりも前に設定してください",
          index,
          customeIndex: breakIndex,
        },
      ];
    });
  });
  return messages;
};
export const isContain = (
  validationMessages: usageRecordValidationModel[],
  key: string,
  index: number,
  customeIndex?: number
) => {
  if (customeIndex) {
    return validationMessages.some(
      (v) =>
        v.index === index && v.key === key && v.customeIndex === customeIndex
    );
  }
  return validationMessages.some((v) => v.index === index && v.key === key);
};

export const getValidationMessage = (
  validationMessages: usageRecordValidationModel[],
  key: string,
  index: number,
  customeIndex?: number
) => {
  if (customeIndex) {
    const m = validationMessages.find(
      (v) =>
        v.index === index && v.key === key && v.customeIndex === customeIndex
    );
    return m?.message;
  }
  const m = validationMessages.find((v) => v.index === index && v.key === key);
  return m?.message;
};

// "通所" "comingToOffice"
// "訪問" "visitingToUser"
// "施設外就労" "workingOutsideOfOffice"
// "施設外支援" "supportForTraining"
// "体験利用支援" "supportForExperienceUser
// "欠席時対応" "supportForAbsence"
// "欠席（加算なし）" "absence"
export const isDisabledAttendaceRecord = (usageRecord: StateUsageRecord | StateTransferUsageRecord) => {
  return [
    "",
    "absence",
    "supportForAbsence",
    "supportForExperienceUser",
  ].includes(usageRecord.serviceProvisionStatus);
};

export const isDisplayWorkRecord = (usageRecord: StateUsageRecord | StateTransferUsageRecord) => {
  return [
    "comingToOffice",
    "workingOutsideOfOffice",
    "supportForTraining",
  ].includes(usageRecord.serviceProvisionStatus);
};

export const isAfterAttendanceRecordStartAtThanWorkRecordStartAt = (
  u: StateUsageRecord | StateTransferUsageRecord
) => {
  return (
    isDisplayWorkRecord(u) &&
    u.workRecord.wasWorked &&
    u.workRecord.startAtMillis !== 0 &&
    u.workRecord.startAtMillis &&
    u.attendanceRecord.startAtMillis !== 0 &&
    u.workRecord.startAtMillis < u.attendanceRecord.startAtMillis
  ); //作業開始時間 10 > 開始時間 12
};

export const isBeforeAttendanceRecordEndAtThanWorkRecordEndAt = (
  u: StateUsageRecord | StateTransferUsageRecord
) => {
  return (
    isDisplayWorkRecord(u) &&
    u.workRecord.wasWorked &&
    u.workRecord.endAtMillis !== 0 &&
    u.workRecord.endAtMillis &&
    u.attendanceRecord.endAtMillis &&
    u.attendanceRecord.endAtMillis !== 0 &&
    u.workRecord.endAtMillis > u.attendanceRecord.endAtMillis
  ); //作業終了時間 20 < 終了時間 19
};

export const getInvalidBreakTimeStartAtMillis = (
  usageRecord: StateUsageRecord | StateTransferUsageRecord
) => {
  let breakIndexies: number[] = [];
  usageRecord.attendanceRecord.breakTime.forEach((_breakTime, breakIndex) => {
    if (
      usageRecord.workRecord.wasWorked &&
      usageRecord.workRecord.startAtMillis &&
      usageRecord.workRecord.startAtMillis > _breakTime.startAtMillis
    ) {
      breakIndexies = [...breakIndexies, breakIndex];
    }
  });
  return breakIndexies;
};

export const getInvalidBreakTimeEndAtMillis = (
  usageRecord: StateUsageRecord | StateTransferUsageRecord
) => {
  let breakIndexies: number[] = [];
  usageRecord.attendanceRecord.breakTime.forEach((_breakTime, breakIndex) => {
    if (
      usageRecord.workRecord.wasWorked &&
      usageRecord.workRecord.endAtMillis &&
      _breakTime.endAtMillis &&
      usageRecord.workRecord.endAtMillis < _breakTime.endAtMillis
    ) {
      breakIndexies = [...breakIndexies, breakIndex];
    }
  });
  return breakIndexies;
};
