import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TopAlert = {
  message: string;
  type: 'dailybasicInformation' | 'rewards';
  path: string;
};


export const initialState: TopAlert[] = [];

const topAlertSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setTopAlert: (_state: TopAlert[], action: PayloadAction<TopAlert[]>) => {
      return action.payload;
    },
    resetTopAlert: () => {
      return initialState;
    },
  }
});

export const { setTopAlert, resetTopAlert } =
topAlertSlice.actions;
export const topAlertReducer = topAlertSlice.reducer;
