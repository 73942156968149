import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Billing } from 'common-types/firebase/firestore/organizations/billing';

export type StateBilling = Omit<Billing, 'createAt'> &
{ createAtMillis: number | undefined };
const initialState: StateBilling[] = [{
  targetMonth: '',
  billingDataId: '',
  downloadUrls: [],
  isWithLimitManagement: false,
  createAtMillis: undefined,
  createdBy: ''
}];

const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    setBilling: (_state: StateBilling[], action: PayloadAction<StateBilling[]>) => {
      return action.payload;
    },
    resetBilling: () => {
      return initialState;
    }
  }
});

export const { setBilling, resetBilling } = billingSlice.actions;
export const billingReducer = billingSlice.reducer;