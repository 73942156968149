import React, {
  Dispatch,
  SetStateAction,
} from "react";
import { SimpleDialog } from "../atoms/Dialog";
import { Button } from "../atoms/Button";
import styled from "styled-components";
import { StateError } from "../../redux/error";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { useLocation, useHistory } from "react-router-dom";

type Props = {
  error: StateError[];
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  type?: "error" | "alert";
};

export const ErrorAlertDialog: React.VFC<Props> = React.memo(({error, open, setOpen, type}) => {
  const location = useLocation();
  const path = location.pathname;
  const history = useHistory();

  const handleLink = (url?: string) => {
    if (!url) return;
    setOpen(false);
    setTimeout(() => {
      history.push(
        url.includes("usageRecords")
          ? path.includes("organization/")
            ? url
            : `/organization/${url}`
          : url
      );
    }, 100);
  }

  return (
          <SimpleDialog
            open={open}
            setClose={() => {
              setOpen(false);
            }}
            title={`${error.length}件の${type === 'alert' ? 'アラート' : 'エラー'}`}
            component={
              <StyledTable stickyHeader aria-label="sticky table">
                <TableBody>
                  {error.map((e, index) => {
                    return (
                      <StyledTableRow tabIndex={-1} key={index}>
                        <StyledTableCell key={`cell${index}`}>
                          {e.message.url ? (
                            <LinkText
                              onClick={() => {handleLink(e.message.url)}}
                            >
                              {e.message.text}
                            </LinkText>
                          ) : (
                            e.message.text
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </StyledTable>
            }
            buttomComponent={
              <FlexWrap>
                <DialogCloseButtonWrap>
                  <Button
                    variant="outlined"
                    onClick={() => setOpen(false)}
                    value={"閉じる"}
                  />
                </DialogCloseButtonWrap>
              </FlexWrap>
            }
          />
  );
});

const DialogCloseButtonWrap = styled.div`
  margin: 10px ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(6)}px
    ${(props) => props.theme.spacing(4)}px;
`;
const FlexWrap = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTable = styled(Table)`
  .MuiTableCell-stickyHeader {
    background: #fff;
    font-weight: bold;
  }
`;
const StyledTableRow = styled(TableRow)`
  display: flex;
  overflow: auto;
`;

const StyledTableCell = styled(TableCell)`
  width: 100%;
  font-size: 16px;
  letter-spacing: 0.15008px;
  padding: ${(props) => props.theme.spacing(1.5)}px 10px;
  color: #000;
`;

const LinkText = styled.a`
  font-size: 16px;
  letter-spacing: 0.15008px;
  padding: ${(props) => props.theme.spacing(1.5)}px 10px;
  color: #4963ec;
  cursor: pointer;
  "&:hover": {
    text-decoration: underline;
  }
`;
