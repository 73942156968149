import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportPlanReview } from 'common-types/firebase/firestore/organizations/supportPlanReviews';

export type StateSupportPlanReview = Omit<SupportPlanReview, 'summary' | 'meetingForReview' | 'createAt' | 'updateAt'> &
  {
    summary: {comment: string, reviewAtMillis: number | null, reviewedBy: string},
    meetingForReview: {dateAtMillis: number | null, note: string},
    createAtMillis: number | null,
    updateAtMillis: number | null,
    supportPlanReviewId: string
  };

export const initialServiceContents = {
  activityReview: {
    status: 0,
    comment: '',
  },
  personalReview: {
    status: 0,
    comment: '',
  },
  staffReview: {
    status: 0,
    comment: '',
  },
}
export const initStateObject = {
  isReviewed: false,
  longTermObjective: {
    activityReview: {
      status: 0,
      comment: '',
    },
    personalReview: {
      status: 0,
      comment: '',
    },
    staffReview: {
      status: 0,
      comment: '',
    },
  },
  shortTermObjective: {
    activityReview: {
      status: 0,
      comment: '',
    },
    personalReview: {
      status: 0,
      comment: '',
    },
    staffReview: {
      status: 0,
      comment: '',
    },
  },
  serviceContents: [initialServiceContents],
  summary: {
    comment: '',
    reviewAtMillis: null,
    reviewedBy: '',
  },
  meetingForReview: {
    dateAtMillis: null,
    note: '',
  },
  createAtMillis: null,
  updateAtMillis: null,
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
  supportPlanReviewId: '',
}
export const initialState: StateSupportPlanReview[] = [initStateObject];

const supportPlanReviewsSlice = createSlice({
  name: 'supportPlanReviews',
  initialState,
  reducers: {
    setSupportPlanReviews: (_state: StateSupportPlanReview[], action: PayloadAction<StateSupportPlanReview[]>) => {
      return action.payload;
    },
    resetSupportPlanReviews: () => {
      return initialState;
    }
  }
});

export const { setSupportPlanReviews, resetSupportPlanReviews } = supportPlanReviewsSlice.actions;
export const supportPlanReviewsReducer = supportPlanReviewsSlice.reducer;
