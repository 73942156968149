import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeBAddingRewardItem2024 } from 'common-types/firebase/firestore/typeBOrganization/settings';

export type StateWelfareSpecialist = {
  placementType: number,
  startAtMillis: number | null,
  endAtMillis: number | null
};
export type StatePeriod = {
  startAtMillis: number | null,
  endAtMillis: number | null,
};

export type StateTypeBAddingRewardItem2024 = Omit<TypeBAddingRewardItem2024,
  'welfareSpecialist' | 'instructorOfTargetWage' | 'createAt' | 'updateAt'> &
{
  welfareSpecialist: StateWelfareSpecialist,
  instructorOfTargetWage: StatePeriod,
  createAtMillis: number,
  updateAtMillis: number
};
const initialState: StateTypeBAddingRewardItem2024 = {
  staffingPlacement: 7.5,
  welfareSpecialist: {
    placementType: 0,
    startAtMillis: 0,
    endAtMillis: 0
  },
  hasSupportOfDisabilities: 0,
  hasSupportHigherBrainDysfunction: false,
  supportOfEmploymentTransition: {
    hasSupport: false,
    lastYearsResult: 0
  },
  supportOfSevereDisabilities: 0,
  instructorOfTargetWage: {
    startAtMillis: null,
    endAtMillis: null
  },
  isAchievedTargetWage: false,
  improvementOfStaffTreatment: 0,
  isSupportFacilityForDesignatedDisability: false,
  improvementOfStaffSpecificTreatment: 0,
  baseUpSupportOfStaff: false,
  improvementOfStaffTreatment202406: 0,
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const typeBAddingRewardItem2024Slice = createSlice({
  name: 'typeBAddingRewardItem2024',
  initialState,
  reducers: {
    setTypeBAddingRewardItem2024: (_state: StateTypeBAddingRewardItem2024, action: PayloadAction<StateTypeBAddingRewardItem2024>) => {
      return action.payload;
    },
    resetTypeBAddingRewardItem2024: () => {
      return initialState;
    }
  }
});

export const { setTypeBAddingRewardItem2024, resetTypeBAddingRewardItem2024 } = typeBAddingRewardItem2024Slice.actions;
export const typeBAddingRewardItem2024Reducer = typeBAddingRewardItem2024Slice.reducer;
