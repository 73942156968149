import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  OtherOrganizationsInformationModel,
  TransferUsages,
} from "common-types/firebase/firestore/users/settings";

export type StarAndStopModel = {
  startAtMillis: number | null;
  endAtMillis: number | null;
};
export type SupportPlanStatusModel = {
  isNotCreated: boolean;
  startAtMillis: number | null;
};

export type SupportForEmploymentTransition = {
  isAdded: boolean;
  startAtMillis: number | null;
};

export type LocalCooperationMeeting = {
  isAdded: boolean;
  startAtMillis: number | null;
};

export type ContractPaymentAmountModel = {
  isDefault: boolean;
  numberOfDate: number;
  businessEntryNumber: number;
};

export type MunicipalSubsidyModel = {
  isTarget: boolean;
  amountOfSubsidy: number;
  unit: string;
  prefecture: string;
  city: string;
  cityCode: number;
};

export type SpecialCaseForUsageModel = {
  isSpecialCase: boolean;
  startAtMillis: number | null;
  endAtMillis: number | null
}

export type ManagementOrganizationModel = {
  hasUpperLimitManagementOrganization: boolean;
  hasManagementAuthentication: boolean;
  otherOrganizationsInformation: OtherOrganizationsInformationModel;
  managementResult: number;
};

export type StateTransferUsages = Omit<
  TransferUsages,
  | "createAt"
  | "updateAt"
  | "hasReceivedService"
  | "hasReceivedCertification"
  | "supportPlanStatus"
  | 'specialCaseForUsage'
  | "supportForEmploymentTransition"
  | "localCooperationMeeting"
> & {
  createAtMillis: number | null;
  updateAtMillis: number | null;
  hasReceivedService: StarAndStopModel;
  hasReceivedCertification: StarAndStopModel;
  supportPlanStatus: SupportPlanStatusModel;
  supportForEmploymentTransition: SupportForEmploymentTransition;
  specialCaseForUsage: SpecialCaseForUsageModel,
  localCooperationMeeting: LocalCooperationMeeting;
};

const starAndStopModelInitState = {
  startAtMillis: null,
  endAtMillis: null,
};

const initialState: StateTransferUsages = {
  hasReceivedService: starAndStopModelInitState,
  isNotInitialAdditonTarget: false,
  excludeUsageDuration: false,
  hasReceivedCertification: starAndStopModelInitState,
  disabilityClassification: 0,
  contractPaymentAmount: {
    isDefault: true,
    numberOfDate: 0,
    businessEntryNumber: 0,
  },
  specialCaseForUsage: {
    isSpecialCase: false,
    startAtMillis: null,
    endAtMillis: null,
  },
  maximumBurden: 0,
  incomeClassification: undefined,
  municipalSubsidy: {
    isTarget: false,
    amountOfSubsidy: 0,
    unit: "",
    prefecture: "",
    city: "",
    cityCode: 0,
  },
  isATypeExemptionTarget: false,
  managementOrganization: {
    hasUpperLimitManagementOrganization: false,
    hasManagementAuthentication: false,
    otherOrganizationsInformation: [
      {
        organizationId: "",
        organizationName: "",
        totalAmount: 0,
        userBurden: 0,
        managedUserBurden: 0,
      },
    ],
    managementResult: 0,
  },
  scheduledDateOfComing: {
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  },
  isAutoFilledWorkTime: false,
  defaultFoodService: false, // TODO: 選択肢を文字列で追加
  defaultTransferService: "none", // TODO: 選択肢を文字列で追加
  defaultTransferWithSameSite: "none", // TODO: 選択肢を文字列で追加
  supportPlanStatus: {
    isNotCreated: false,
    startAtMillis: 0,
  },
  supportForEmploymentTransition: {
    isAdded: false,
    startAtMillis: 0,
  },
  localCooperationMeeting: {
    isAdded: false,
    startAtMillis: 0,
  },
  createAtMillis: null,
  updateAtMillis: null,
  maximumAmountOfAdjustmentOwnOffice: null,
  createdBy: "",
  updatedBy: "",
};

const transferUsagesSlice = createSlice({
  name: "transferUsages",
  initialState,
  reducers: {
    setTransferUsages: (_state: StateTransferUsages, action: PayloadAction<StateTransferUsages>) => {
      return action.payload;
    },
    resetTransferUsages: () => {
      return initialState;
    },
  },
});

export const { setTransferUsages, resetTransferUsages } = transferUsagesSlice.actions;
export const transferUsagesReducer = transferUsagesSlice.reducer;
