import { useEffect, useCallback } from 'react';
import { useAppDispatch } from '../redux';
import { auth, firestore } from './index';
import { login, logout } from '../redux/logined';
import { resetMemberData, setMemberData, StateMember } from '../redux/memberData';
import { StateRole, setRoles } from '../redux/roles';
import { useTypedSelector } from '../redux';
import { setUsages, resetUsages } from '../redux/usages';
import { setProfile, resetProfile, StateUserProfile } from '../redux/profile';
import { setBeneficiaryCertificate, resetBeneficiaryCertificate } from '../redux/beneficiaryCertificate';
import { resetWorkItems, setWorkItems, StateWorkItem } from '../redux/workItems';
import { StateUsageRecord, deleteUsageRecord, resetUsageRecords, updateUsageRecords } from '../redux/usageRecords';
import { StateSupportRecord, deleteSupportRecord, resetSupportRecords, updateSupportRecords } from '../redux/supportRecords';
import { StateSupportPlan, setSupportPlans } from '../redux/supportPlans';
import { StateWorkRecord, deleteWorkRecord, resetWorkRecords, updateWorkRecords } from '../redux/workRecords';
import { StateNewWorkingHour, setNewWorkingHours } from '../redux/newWorkingHours';
import { workingHoursRef } from './firestore/organizations/settings';
import { AddingRewardItem2024, BasicReward2024, NewWorkingHours, SubtractingRewardItem2024 } from 'common-types/firebase/firestore/organizations/settings';
import { setOrganization, resetOrganization } from '../redux/organization';
import { resetBusinessHours, setBusinessHours } from '../redux/businessHours';
import { resetWorkingHours, setWorkingHours } from '../redux/workingHours';
import { resetBasicReward, setBasicReward } from '../redux/basicReward';
import { resetSubtractingRewardItem, setSubtractingRewardItem } from '../redux/subtractingRewardItem';
import { resetAddingRewardItem, setAddingRewardItem } from '../redux/addingRewardItem';
import { StateInvoice, setInvoices } from '../redux/invoices';
import { setMembers } from '../redux/members';
import { setUsers, StateUser, updateProfile } from "../redux/users";
import { BeneficiaryCertificate, TransferUsages, TypeBUsages, Usages, UserProfile } from 'common-types/firebase/firestore/users/settings';
import { Organization } from 'common-types/firebase/firestore/organizations';
import { BusinessHours, WorkingHours, BasicReward, SubtractingRewardItem, AddingRewardItem, InitialSetting, StripeInfo } from 'common-types/firebase/firestore/organizations/settings';
import { SupportRecord } from 'common-types/firebase/firestore/organizations/supportRecords';
import { SupportPlan } from 'common-types/firebase/firestore/organizations/supportPlans';
import { WorkRecord } from 'common-types/firebase/firestore/organizations/workRecords';
import { UsageRecord } from 'common-types/firebase/firestore/organizations/usageRecords';
import { Invoice } from 'common-types/firebase/firestore/organizations/invoices';
import { resetWorkPlaceCompanies, setWorkPlaceCompanies, StateWorkPlaceCompany } from '../redux/workPlaceCompanies';
import { WorkPlaceCompany } from 'common-types/firebase/firestore/organizations/workPlaceCompanies';
import { WorkItem } from 'common-types/firebase/firestore/organizations/workItems';
import { deleteBusinessLog, resetBusinessLogs, setBusinessLogs, StateBusinessLog, updateBusinessLogs } from '../redux/businessLogs';
import { BusinessLog } from 'common-types/firebase/firestore/organizations/businessLogs';
import { Role } from '../../../common-types/firebase/firestore/roles';
import { Member } from 'common-types/firebase/firestore/members';
import { resetInitialSetting, setInitialSetting, StateInitialSetting } from '../redux/initialSetting';
import { resetStripeInfo, setStripeInfo } from '../redux/stripeInfo';
import { setInterviewRecords, StateInterviewRecord } from '../redux/interviewRecords';
import { InterviewRecord } from 'common-types/firebase/firestore/organizations/interviewRecords';
import { setSupportPlanReviews, StateSupportPlanReview } from '../redux/supportPlanReviews';
import { SupportPlanReview } from 'common-types/firebase/firestore/organizations/supportPlanReviews';
import { setTemporaryLink } from '../redux/temporaryLink';
import { ServiceType, User } from 'common-types/firebase/firestore/users';
import firebase from 'firebase/app';
import { updateError, appendError, StateError, Message } from '../redux/error';
import { setBilling, StateBilling } from '../redux/billing';
import { Billing } from 'common-types/firebase/firestore/organizations/billing';
import dayjs from '../lib/dayjs'
import { resetSelectedOrganization, setSelectedOrganization } from '../redux/selectedOrganization';
import { TypeBOrganization } from 'common-types/firebase/firestore/typeBOrganization';
import { resetTypeBOrganization, setTypeBOrganization } from '../redux/typeBOrganization';
import { resetTypeBBasicReward, setTypeBBasicReward } from '../redux/typeBBasicReward';
import { TypeBAddingRewardItem, TypeBAddingRewardItem2024, TypeBBasicReward, TypeBSubtractingRewardItem, TypeBSubtractingRewardItem2024 } from 'common-types/firebase/firestore/typeBOrganization/settings';
import { resetTypeBAddingRewardItem, setTypeBAddingRewardItem } from '../redux/typeBAddingRewardItem';
import { resetTypeBSubtractingRewardItem, setTypeBSubtractingRewardItem } from '../redux/typeBSubtractingRewardItem';
import { resetTypeBUsages, setTypeBUsages } from '../redux/typeBUsages';
import { assessmentsConverter, setAssessments } from '../redux/assessments';
import {
  checkOrganization,
  checkTransferOrganization,
  checkTypeBOrganization,
} from "../validateForRedux/organization";
import {
  checkBasicReward,
  checkBasicReward2024,
  checkTransferBasicReward,
  checkTypeBBasicReward,
} from "../validateForRedux/basicReward";
import { checkSubtractingRewardItem, checkSubtractingRewardItem2024, checkTransferSubtractingRewardItem, checkTypeBSubtractingRewardItem, checkTypeBSubtractingRewardItem2024 } from '../validateForRedux/subtractingRewardItem';
import { checkAddingRewardItem, checkAddingRewardItem2024, checkTransferAddingRewardItem, checkTypeBAddingRewardItem, checkTypeBAddingRewardItem2024 } from '../validateForRedux/addingRewardItem';
import { checkTransferUsageRecord, checkTypeBUsageRecord, checkUsageRecord } from '../validateForRedux/usageRecord';
import { checkTransferUsageRecords, checkTypeBUsageRecords, checkUsageRecords } from '../validateForRedux/usageRecords';
import { checkStateUser } from '../validateForRedux/user';
import { checkSupportPlan, checkSupportPlanAlert } from '../validateForRedux/supportPlan';
import { getUserNameByUserId } from '../utils';
import { checkProfile } from '../validateForRedux/profile';
import { checkUsage, checkTypeBUsage } from '../validateForRedux/usage';
import { checkBeneficiaryCertificate } from '../validateForRedux/beneficiaryCertificate';
import { checkStripeInfo } from '../validateForRedux/stripeInfo';
import { getFirestoreOrganization } from './firestore/organizations';
import { getFirestoreTypeBOrganization } from './firestore/typeBOrganizations';
import { logout as authLogout } from './auth';
import { setRead } from '../redux/initRead';
import { groupsConverter, setGroups } from '../redux/groups';
import { newsConverter, setNews } from '../redux/news';
import { readNewsConverter, setReadNews } from '../redux/readNews';
import { resetGroups } from '../redux/groups';
import { isAfterHasReceivedServiceEndDate } from '../utils/users';
import { sortSupportPlanSupportEndDateDesc } from '../utils/organizations/supportPlan';
import { checkSupportRecord } from "../validateForRedux/checkSupportRecord";
import { TransferOrganization } from 'common-types/firebase/firestore/transferOrganization';
import { resetTransferOrganization, setTransferOrganization } from '../redux/transferOrganization';
import { getFirestoreTransferOrganization } from './firestore/transferOrganizations';
import { getSelectedUsage } from '../utils/getSelectedUsage';
import { getSelectedOrganization } from '../utils/getSelectedOrganization';
import { resetTransferUsages, setTransferUsages } from '../redux/transferUsages';
import { TransferAddingRewardItem, TransferBasicReward, TransferSubtractingRewardItem } from 'common-types/firebase/firestore/transferOrganization/settings';
import { resetTransferSubtractingRewardItem, setTransferSubtractingRewardItem } from '../redux/transferSubtractingRewardItem';
import { resetTransferAddingRewardItem, setTransferAddingRewardItem } from '../redux/transferAddingRewardItem';
import { ArbitraryPeriod } from '../redux/userPeriod';
import { StateTransferUsageRecord, resetTransferUsageRecords, setTransferUsageRecords, updateTransferUsageRecord } from '../redux/transferUsageRecords';
import { TransferUsageRecord } from 'common-types/firebase/firestore/transferOrganization/usageRecords';
import { resetTransferBasicReward, setTransferBasicReward } from '../redux/transferBasicReward';
import { resetBasicReward2024, setBasicReward2024 } from '../redux/basicReward2024';
import { resetSubtractingRewardItem2024, setSubtractingRewardItem2024 } from '../redux/subtractingRewardItem2024';
import { resetTypeBSubtractingRewardItem2024, setTypeBSubtractingRewardItem2024 } from '../redux/typeBSubtractingRewardItem2024';
import { resetAddingRewardItem2024, setAddingRewardItem2024 } from '../redux/addingRewardItem2024';
import { resetTypeBAddingRewardItem2024, setTypeBAddingRewardItem2024 } from '../redux/typeBAddingRewardItem2024';
import { resetTopAlert, setTopAlert } from '../redux/topAlert';
import { checkTopAlert } from '../validateForRedux/topAlert';

//import { setUid } from '../modules/uid';
//import { firestore,analytics } from '../firebase';

const settings = 'settings';
const workPlaceCompanies = 'workPlaceCompanies';
const businessLogs = 'businessLogs';
const interviewRecords = 'interviewRecords';
const supportPlanReviews = 'supportPlanReviews';

export const FirebaseToRedux = () => {
  const dispatch = useAppDispatch();
  const userId = useTypedSelector((state) => state.userId);
  const logined = useTypedSelector((state) => state.logined)
  const businessLogYearMonth = useTypedSelector((state) => state.businessLogYearMonth);
  const workRecordYearAndMonth = useTypedSelector((state) => state.workRecordYearAndMonth);
  const userPeriod = useTypedSelector((state) => state.userPeriod);
  const usageRecordYearAndMonth = useTypedSelector((state) => state.usageRecordYearAndMonth);
  const supportRecordYearAndMonth = useTypedSelector((state) => state.supportRecordYearMonth);
  const memberData = useTypedSelector((state) => state.memberData);
  const selectedOrganization = useTypedSelector((state) => state.selectedOrganization);
  const selectedOrganizationId = selectedOrganization.organizationId;
  const selectedServiceType = selectedOrganization.serviceType;
  // 修正
  const organizationId = selectedOrganization.organizationId;
  const organization = useTypedSelector((state) => state.organization);
  const typeBOrganization = useTypedSelector((state) => state.typeBOrganization);
  const transferOrganization = useTypedSelector((state) => state.transferOrganization);
  const basicReward = useTypedSelector((state) => state.basicReward);
  const basicReward2024 = useTypedSelector((state) => state.basicReward2024);
  const typeBBasicReward = useTypedSelector((state) => state.typeBBasicReward);
  const transferBasicReward = useTypedSelector((state) => state.transferBasicReward);
  const subtractingRewardItem = useTypedSelector((state) => state.subtractingRewardItem);
  const subtractingRewardItem2024 = useTypedSelector((state) => state.subtractingRewardItem2024);
  const typeBSubtractingRewardItem = useTypedSelector((state) => state.typeBSubtractingRewardItem);
  const typeBSubtractingRewardItem2024 = useTypedSelector((state) => state.typeBSubtractingRewardItem2024);
  const transferSubtractingRewardItem = useTypedSelector((state) => state.transferSubtractingRewardItem);
  const addingRewardItem = useTypedSelector((state) => state.addingRewardItem);
  const addingRewardItem2024 = useTypedSelector((state) => state.addingRewardItem2024);
  const typeBAddingRewardItem = useTypedSelector((state) => state.typeBAddingRewardItem);
  const typeBAddingRewardItem2024 = useTypedSelector((state) => state.typeBAddingRewardItem2024);
  const transferAddingRewardItem = useTypedSelector((state) => state.transferAddingRewardItem);
  const usageRecords = useTypedSelector((state) => state.usageRecords);
  const transferUsageRecords = useTypedSelector((state) => state.transferUsageRecords);
  const users = useTypedSelector((state) => state.users);
  const profile = useTypedSelector((state) => state.profile);
  const usages = useTypedSelector((state) => state.usages);
  const typeBUsages = useTypedSelector((state) => state.typeBUsages);
  const transferUsages = useTypedSelector((state) => state.transferUsages);
  const beneficiaryCertificate = useTypedSelector((state) => state.beneficiaryCertificate);
  const supportPlans = useTypedSelector((state) => state.supportPlans);
  const error = useTypedSelector((state) => state.error);
  const stripeInfo = useTypedSelector((state) => state.stripeInfo);
  const supportRecords = useTypedSelector((state) => state.supportRecords);

  const observeProfileDocument = (
    docRef: firebase.firestore.DocumentReference,
    userId: string
  ) => {
    docRef.onSnapshot((snapshot) => {
      const profile = snapshot.data() as StateUserProfile
      const {prefecture, city, admissionDate } = profile
      dispatch(updateProfile({ prefecture, city, admissionDate , userId}))
    });
  };

  const observeUsers = useCallback((organizationId: string, serviceType: ServiceType) => {
    const usages = getSelectedUsage(serviceType);
    const usersRef = firestore.collection('users').where('organizationId', '==', organizationId).where('isDeleted', '==', false).orderBy('nameKana');
    usersRef.onSnapshot(async (querySnapshot) => {
      const list: StateUser[] = [];
      await Promise.all(querySnapshot.docs.map(async (doc) => {
        const UserSnapshot = doc.data() as User;
        const settingsCollectionRef = doc.ref.collection("settings");
        const beneficiaryCertificateDocumentData = await settingsCollectionRef
          .doc("beneficiaryCertificate")
          .get();
        const usagesDocumentData = await settingsCollectionRef
          .doc(usages)
          .get();
        observeProfileDocument(settingsCollectionRef.doc("profile"), doc.id);
        const profileDocument = await settingsCollectionRef
          .doc("profile")
          .get();
        const usagesDocumentSnapshot = usagesDocumentData.data();
        const beneficiaryCertificateDocumentSnapshot =
          beneficiaryCertificateDocumentData.data();
        const profileDocumentSnapshot = profileDocument.data();
        // MEMO: observeProfileDocument()にてprofileの変更を適用した後に上書きされるためexistsUsersが存在する場合はprofileに既存の値を保存するように
        const existsUsers = users.find((u) => u.userId === doc.id);

        list.push({
          createdBy: UserSnapshot.createdBy,
          updatedBy: UserSnapshot.updatedBy,
          organizationId: UserSnapshot.organizationId,
          isDeleted: false,
          email: UserSnapshot.email,
          createAtMillis: UserSnapshot.createAt
            ? UserSnapshot.createAt.toMillis()
            : null,
          updateAtMillis: UserSnapshot.updateAt
            ? UserSnapshot.updateAt.toMillis()
            : null,
          userId: doc.id,
          photoUrl: UserSnapshot.photoUrl ? UserSnapshot.photoUrl : "",
          nameKana: UserSnapshot.nameKana ? UserSnapshot.nameKana : "",
          nameKanji: UserSnapshot.nameKanji ? UserSnapshot.nameKanji : "",
          settingStep: usagesDocumentSnapshot
            ? beneficiaryCertificateDocumentSnapshot
              ? null
              : 2
            : 1,
          beneficiaryNumber: UserSnapshot.beneficiaryNumber,
          serviceType: UserSnapshot.serviceType,
          hasReceivedCertification: {
            startAtMillis: usagesDocumentSnapshot?.hasReceivedCertification
              .startAt
              ? usagesDocumentSnapshot.hasReceivedCertification.startAt.toMillis()
              : null,
            endAtMillis: usagesDocumentSnapshot?.hasReceivedCertification.endAt
              ? usagesDocumentSnapshot.hasReceivedCertification.endAt.toMillis()
              : null,
          },
          hasReceivedService: {
            startAtMillis: usagesDocumentSnapshot?.hasReceivedService.startAt
              ? usagesDocumentSnapshot.hasReceivedService.startAt.toMillis()
              : null,
            endAtMillis: usagesDocumentSnapshot?.hasReceivedService.endAt
              ? usagesDocumentSnapshot.hasReceivedService.endAt.toMillis()
              : null,
          },
          beneficiaryCertificateDetail: {
            createAtMillis: beneficiaryCertificateDocumentSnapshot?.createAt
              ? beneficiaryCertificateDocumentSnapshot.createAt.toMillis()
              : null,
            periodOfMaximuMonthlyBurden: {
              hasQualification:
                beneficiaryCertificateDocumentSnapshot
                  ?.periodOfMaximuMonthlyBurden.hasQualification,
              startAtMillis: beneficiaryCertificateDocumentSnapshot
                ?.periodOfMaximuMonthlyBurden.startAt
                ? beneficiaryCertificateDocumentSnapshot?.periodOfMaximuMonthlyBurden.startAt.toMillis()
                : null,
              endAtMillis: beneficiaryCertificateDocumentSnapshot
                ?.periodOfMaximuMonthlyBurden.endAt
                ? beneficiaryCertificateDocumentSnapshot?.periodOfMaximuMonthlyBurden.endAt.toMillis()
                : null,
            },
            periodOfMealProvisionSystemAddition: {
              hasQualification:
                beneficiaryCertificateDocumentSnapshot
                  ?.periodOfMealProvisionSystemAddition.hasQualification,
              startAtMillis: beneficiaryCertificateDocumentSnapshot
                ?.periodOfMealProvisionSystemAddition.startAt
                ? beneficiaryCertificateDocumentSnapshot?.periodOfMealProvisionSystemAddition.startAt.toMillis()
                : null,
              endAtMillis: beneficiaryCertificateDocumentSnapshot
                ?.periodOfMealProvisionSystemAddition.endAt
                ? beneficiaryCertificateDocumentSnapshot?.periodOfMealProvisionSystemAddition.endAt.toMillis()
                : null,
            },
            certificationValidityPeriodOfDisabilitySupportCategory: {
              hasQualification:
                beneficiaryCertificateDocumentSnapshot
                  ?.certificationValidityPeriodOfDisabilitySupportCategory
                  .hasQualification,
              startAtMillis: beneficiaryCertificateDocumentSnapshot
                ?.certificationValidityPeriodOfDisabilitySupportCategory.startAt
                ? beneficiaryCertificateDocumentSnapshot?.certificationValidityPeriodOfDisabilitySupportCategory.startAt.toMillis()
                : null,
              endAtMillis: beneficiaryCertificateDocumentSnapshot
                ?.certificationValidityPeriodOfDisabilitySupportCategory.endAt
                ? beneficiaryCertificateDocumentSnapshot?.certificationValidityPeriodOfDisabilitySupportCategory.endAt.toMillis()
                : null,
            },
            supportDecisionPeriodforCareBenefits: {
              hasQualification:
                beneficiaryCertificateDocumentSnapshot
                  ?.supportDecisionPeriodforCareBenefits.hasQualification,
              startAtMillis: beneficiaryCertificateDocumentSnapshot
                ?.supportDecisionPeriodforCareBenefits.startAt
                ? beneficiaryCertificateDocumentSnapshot?.supportDecisionPeriodforCareBenefits.startAt.toMillis()
                : null,
              endAtMillis: beneficiaryCertificateDocumentSnapshot
                ?.supportDecisionPeriodforCareBenefits.endAt
                ? beneficiaryCertificateDocumentSnapshot?.supportDecisionPeriodforCareBenefits.endAt.toMillis()
                : null,
            },
            paymentPeriodOfPlanningSupportExpenses: {
              hasQualification:
                beneficiaryCertificateDocumentSnapshot
                  ?.paymentPeriodOfPlanningSupportExpenses.hasQualification,
              startAtMillis: beneficiaryCertificateDocumentSnapshot
                ?.paymentPeriodOfPlanningSupportExpenses.startAt
                ? beneficiaryCertificateDocumentSnapshot?.paymentPeriodOfPlanningSupportExpenses.startAt.toMillis()
                : null,
              endAtMillis: beneficiaryCertificateDocumentSnapshot
                ?.paymentPeriodOfPlanningSupportExpenses.endAt
                ? beneficiaryCertificateDocumentSnapshot?.paymentPeriodOfPlanningSupportExpenses.endAt.toMillis()
                : null,
            },
            monitoringPeriodOfPlanningSupportExpenses: {
              hasQualification:
                beneficiaryCertificateDocumentSnapshot
                  ?.monitoringPeriodOfPlanningSupportExpenses.hasQualification,
              startAtMillis: beneficiaryCertificateDocumentSnapshot
                ?.monitoringPeriodOfPlanningSupportExpenses.startAt
                ? beneficiaryCertificateDocumentSnapshot?.monitoringPeriodOfPlanningSupportExpenses.startAt.toMillis()
                : null,
              endAtMillis: beneficiaryCertificateDocumentSnapshot
                ?.monitoringPeriodOfPlanningSupportExpenses.endAt
                ? beneficiaryCertificateDocumentSnapshot?.monitoringPeriodOfPlanningSupportExpenses.endAt.toMillis()
                : null,
            },
          },
          profile: existsUsers
            ? existsUsers.profile
            : {
                prefecture: profileDocumentSnapshot?.prefecture
                  ? profileDocumentSnapshot.prefecture
                  : "",
                city: profileDocumentSnapshot?.city
                  ? profileDocumentSnapshot.city
                  : "",
                admissionDate: profileDocumentSnapshot?.admissionDate
                  ? profileDocumentSnapshot.admissionDate
                  : "",
              },
        });
      }))
      dispatch(setUsers(list));
    });
  }, [dispatch]);

  const observeRoles = useCallback((organizationId: string) => {
    firestore.collection('roles').where('organizationIds', 'array-contains', organizationId).where('isDeleted', '==', false).onSnapshot((querySnapshot) => {
      const list: StateRole[] = [];
      querySnapshot.forEach(async (doc) => {
        if (doc.exists) {
          const roleSnapshot = doc.data() as Role;
          list.push({
            initialName: roleSnapshot.initialName ? roleSnapshot.initialName : '',
            types: roleSnapshot.types,
            organizationIds: roleSnapshot.organizationIds,
            email: roleSnapshot.email,
            roleId: doc.id,
            createAtMillis: roleSnapshot.createAt ? roleSnapshot.createAt.toMillis() : 0,
            updateAtMillis: roleSnapshot.updateAt ? roleSnapshot.updateAt.toMillis() : 0,
            createdBy: roleSnapshot.createdBy,
            updatedBy: roleSnapshot.updatedBy,
            isDeleted: roleSnapshot.isDeleted,
            userId: roleSnapshot.userId ? roleSnapshot.userId : '',
          });
        }
      });
      dispatch(setRoles(list));
    });
  }, [dispatch]);

  const observeMembers = useCallback((organizationId: string) => {
    firestore.collection('members').where('organizationIds', 'array-contains', organizationId).where('isDeleted', '==', false).onSnapshot((querySnapshot) => {
      const list: StateMember[] = [];
      querySnapshot.forEach(async (doc) => {
        if (doc.exists) {
          const memberSnapshot = doc.data() as Member;
          list.push({
            roles: memberSnapshot.roles,
            organizationIds: memberSnapshot.organizationIds,
            email: memberSnapshot.email,
            name: memberSnapshot.name,
            createAtMillis: memberSnapshot.createAt.toMillis(),
            updateAtMillis: memberSnapshot.updateAt.toMillis(),
            createdBy: memberSnapshot.createdBy,
            updatedBy: memberSnapshot.updatedBy,
            isDeleted: memberSnapshot.isDeleted,
            memberId: doc.id
          });
        }
      });
      dispatch(setMembers(list));
    });
  }, [dispatch, selectedOrganizationId]);

  const observeUsageRecords = useCallback((organizationId: string, usageRecordYearAndMonth: string, serviceType: ServiceType) => {
    dispatch(resetUsageRecords());
    dispatch(resetTransferUsageRecords());
    const selectedOrganization = getSelectedOrganization(serviceType);
    const startDate = dayjs(usageRecordYearAndMonth).startOf('month');
    const startAt = startDate.toDate();
    const endAt = dayjs(startDate).add(1, 'M').toDate();
    firestore.collection(selectedOrganization).doc(organizationId).collection('usageRecords').orderBy('attendanceRecord.startAt', 'asc').startAt(startAt).endBefore(endAt).onSnapshot((querySnapshot) => {
      if (serviceType==='aType' || serviceType==='bType') {
        const list: StateUsageRecord[] = [];
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added' || change.type === 'modified') {
            const doc = change.doc;
            const usageRecordsQuerySnapshot = doc.data() as UsageRecord;
            list.push({
              userId: usageRecordsQuerySnapshot.userId,
              serviceProvisionStatus: usageRecordsQuerySnapshot.serviceProvisionStatus,
              attendanceRecord: {
                startAtMillis: usageRecordsQuerySnapshot.attendanceRecord.startAt.toMillis(),
                endAtMillis: usageRecordsQuerySnapshot.attendanceRecord?.endAt ? usageRecordsQuerySnapshot.attendanceRecord?.endAt.toMillis() : null,
                breakTime: usageRecordsQuerySnapshot.attendanceRecord?.breakTime ? usageRecordsQuerySnapshot.attendanceRecord?.breakTime.map((n: {
                  startAt: firebase.firestore.Timestamp;
                  endAt: firebase.firestore.Timestamp | null;
                }) => {
                  return {
                    startAtMillis: n.startAt.toMillis(),
                    endAtMillis: n.endAt ? n.endAt.toMillis() : null,
                  }
                }) : []
              },
              // mealOffer: usageRecordsQuerySnapshot.mealOffer,
              foodservice: usageRecordsQuerySnapshot.foodservice,
              transferService: usageRecordsQuerySnapshot.transferService,
              transferWithSameSite: usageRecordsQuerySnapshot.transferWithSameSite,
              visitSupport: usageRecordsQuerySnapshot.visitSupport,
              medicalCooperation: usageRecordsQuerySnapshot.medicalCooperation,
              wasTookSupportForHouseLife: usageRecordsQuerySnapshot.wasTookSupportForHouseLife,
              wasTookSupportForSocialLife: usageRecordsQuerySnapshot.wasTookSupportForSocialLife,
              wasLocalSupportAdditional: usageRecordsQuerySnapshot.wasLocalSupportAdditional,
              wasPeerSupportAddtional: usageRecordsQuerySnapshot.wasPeerSupportAddtional,
              wasTookEmergencyReceiving: usageRecordsQuerySnapshot.wasTookEmergencyReceiving,
              wasTookIntensiveSupport: usageRecordsQuerySnapshot.wasTookIntensiveSupport,
              note: usageRecordsQuerySnapshot.note,
              memo: usageRecordsQuerySnapshot.memo,
              workRecord: {
                wasWorked: usageRecordsQuerySnapshot.workRecord.wasWorked,
                startAtMillis: usageRecordsQuerySnapshot.workRecord.startAt ? usageRecordsQuerySnapshot.workRecord.startAt.toMillis() : null,
                endAtMillis: usageRecordsQuerySnapshot.workRecord.endAt ? usageRecordsQuerySnapshot.workRecord.endAt.toMillis() : null,
              },
              createAtMillis: usageRecordsQuerySnapshot.createAt.toMillis(),
              updateAtMillis: usageRecordsQuerySnapshot.updateAt.toMillis(),
              createdBy: usageRecordsQuerySnapshot.createdBy,
              updatedBy: usageRecordsQuerySnapshot.updatedBy,
              isDeleted: usageRecordsQuerySnapshot.isDeleted,
              usageRecordId: doc.id,
              supportForExperienceUserType: usageRecordsQuerySnapshot.supportForExperienceUserType,
              isCommunityLifeSupportBase: usageRecordsQuerySnapshot.isCommunityLifeSupportBase,
              isRemoteWork: usageRecordsQuerySnapshot.isRemoteWork === undefined ? false : usageRecordsQuerySnapshot.isRemoteWork,
            });
          }
          if(change.type === 'removed') {
            dispatch(deleteUsageRecord(change.doc.id));
          }
        });
        dispatch(updateUsageRecords(list));
        dispatch(resetTransferUsageRecords());
      } else if (serviceType==='transfer') {
        const list: StateTransferUsageRecord[] = [];
        querySnapshot.forEach(async (doc) => {
          const usageRecordsQuerySnapshot = doc.data() as TransferUsageRecord;
          const {
            attendanceRecord,
            workRecord,
            createAt,
            updateAt,
            ...params
          } = usageRecordsQuerySnapshot;
          list.push({
            ...params,
            attendanceRecord: {
              startAtMillis: usageRecordsQuerySnapshot.attendanceRecord.startAt.toMillis(),
              endAtMillis: usageRecordsQuerySnapshot.attendanceRecord?.endAt ? usageRecordsQuerySnapshot.attendanceRecord?.endAt.toMillis() : null,
              breakTime: usageRecordsQuerySnapshot.attendanceRecord?.breakTime ? usageRecordsQuerySnapshot.attendanceRecord?.breakTime.map((n: {
                startAt: firebase.firestore.Timestamp;
                endAt: firebase.firestore.Timestamp | null;
              }) => {
                return {
                  startAtMillis: n.startAt.toMillis(),
                  endAtMillis: n.endAt ? n.endAt.toMillis() : null,
                }
              }) : []
            },
            workRecord: {
              wasWorked: usageRecordsQuerySnapshot.workRecord.wasWorked,
              startAtMillis: usageRecordsQuerySnapshot.workRecord.startAt ? usageRecordsQuerySnapshot.workRecord.startAt.toMillis() : null,
              endAtMillis: usageRecordsQuerySnapshot.workRecord.endAt ? usageRecordsQuerySnapshot.workRecord.endAt.toMillis() : null,
            },
            createAtMillis: usageRecordsQuerySnapshot.createAt.toMillis(),
            updateAtMillis: usageRecordsQuerySnapshot.updateAt.toMillis(),
            usageRecordId: doc.id,
          });
        });
        dispatch(updateTransferUsageRecord(list));
        dispatch(resetUsageRecords());
      } else {
        dispatch(resetUsageRecords());
        dispatch(resetTransferUsageRecords());
      }
    });
  }, [dispatch])

  const observeSupportPlans = useCallback((organizationId: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection('supportPlans').where('isDeleted', '==', false).onSnapshot((querySnapshot) => {
      const list: StateSupportPlan[] = [];
      querySnapshot.forEach(async (doc) => {
        const supportPlanSnapshot = doc.data() as SupportPlan;
        const statePrograms = supportPlanSnapshot.serviceContents.programs.map((p) => {
          return {
            scheduledTime: p.scheduledTime,
            content: p.content
          }
        }
        );
        const stateSupportPolicy = supportPlanSnapshot.serviceContents.supportPolicy.map((s) => {
          return {
            goal: s.goal,
            personalActivity: s.personalActivity,
            staffSupport: s.staffSupport,
            startAtMillis: s.startAt ? s.startAt.toMillis() : null,
            endAtMillis: s.endAt ? s.endAt.toMillis() : null,
          }
        }
        )
        list.push(
          {
            userId: supportPlanSnapshot.userId,
            planAtMillis: supportPlanSnapshot.planAt.toMillis(),
            beforePlanAtMillis: supportPlanSnapshot.beforePlanAt ? supportPlanSnapshot.beforePlanAt.toMillis() : null,
            supportDate: {
              startAtMillis: supportPlanSnapshot.supportDate.startAt.toMillis(),
              endAtMillis: supportPlanSnapshot.supportDate.endAt.toMillis()
            },
            approvedBy: supportPlanSnapshot.approvedBy,
            isApproved: supportPlanSnapshot.isApproved,
            personalInfomation: {
              personalHistory: supportPlanSnapshot.personalInfomation.personalHistory,
              userRequest: supportPlanSnapshot.personalInfomation.userRequest,
              IncomeInformation: supportPlanSnapshot.personalInfomation.IncomeInformation,
              challengeForActivities: supportPlanSnapshot.personalInfomation.challengeForActivities,
              healthCondition: supportPlanSnapshot.personalInfomation.healthCondition,
              medicalRiskForActivities: supportPlanSnapshot.personalInfomation.medicalRiskForActivities,
              livingConditions: supportPlanSnapshot.personalInfomation.livingConditions,
            },
            longTermObjective: {
              objective: supportPlanSnapshot.longTermObjective.objective,
              setAtMillis: supportPlanSnapshot.longTermObjective.setAt ? supportPlanSnapshot.longTermObjective.setAt.toMillis() : null,
              willArchiveAtMillis: supportPlanSnapshot.longTermObjective.willArchiveAt ? supportPlanSnapshot.longTermObjective.willArchiveAt.toMillis() : null,
            },
            shortTermObjective: {
              objective: supportPlanSnapshot.shortTermObjective.objective,
              setAtMillis: supportPlanSnapshot.shortTermObjective.setAt ? supportPlanSnapshot.shortTermObjective.setAt.toMillis() : null,
              willArchiveAtMillis: supportPlanSnapshot.shortTermObjective.willArchiveAt ? supportPlanSnapshot.shortTermObjective.willArchiveAt.toMillis() : null,
            },
            serviceContents: {
              supportPolicy: stateSupportPolicy,
              transferServiceType: supportPlanSnapshot.serviceContents.transferServiceType,
              programs: statePrograms
            },
            others: {
              notice: supportPlanSnapshot.others.notice,
              staffComment: supportPlanSnapshot.others.staffComment
            },
            meetingForSupport: {
              dateAtMillis: supportPlanSnapshot.meetingForSupport.dateAt ? supportPlanSnapshot.meetingForSupport.dateAt.toMillis() : null,
              participants: supportPlanSnapshot.meetingForSupport.participants,
              note: supportPlanSnapshot.meetingForSupport.note,
            },
            createAtMillis: supportPlanSnapshot.createAt.toMillis(),
            updateAtMillis: supportPlanSnapshot.updateAt.toMillis(),
            createdBy: supportPlanSnapshot.createdBy,
            updatedBy: supportPlanSnapshot.updatedBy,
            isDeleted: supportPlanSnapshot.isDeleted,
            supportPlanId: doc.id,
            attachedFiles: supportPlanSnapshot?.attachedFiles ?? [],
          }
        )
      })
      dispatch(setSupportPlans(list))
    });
  }, [dispatch]);

  const observeAssessments = useCallback((organizationId: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection('assessments').where('isDeleted', '==', false).withConverter(assessmentsConverter).onSnapshot(async (querySnapshot) => {
      const snapshot = querySnapshot.docs;
      dispatch(setAssessments(snapshot.map(doc => doc.data())));
    });
  },[dispatch]);
  const observeGroups = useCallback((organizationId: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection('groups').where('isDeleted', '==', false).orderBy('createAt', 'asc').withConverter(groupsConverter).onSnapshot(async (querySnapshot) => {
      if(querySnapshot.empty){
        dispatch(resetGroups());
      } else {
        const snapshot = querySnapshot.docs;
        dispatch(setGroups(snapshot.map(doc => doc.data())));
      }
    });
    return () => {};
  },[dispatch]);

  const observeSupportRecords = useCallback((organizationId: string, supportRecordYearAndMonth: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    const startDate = dayjs(supportRecordYearAndMonth).startOf('month');
    const startAt = startDate.toDate();
    const endAt = dayjs(startDate).add(1, 'M').toDate();
    dispatch(resetSupportRecords());
    firestore.collection(selectedOrganization).doc(organizationId).collection('supportRecords').orderBy('attendanceRecord.startAt', 'asc').startAt(startAt).endBefore(endAt).onSnapshot((querySnapshot) => {
      const list: StateSupportRecord[] = [];
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === 'added' || change.type === 'modified') {
          const doc = change.doc;
          const supportRecordSnapshot = doc.data() as SupportRecord;
          list.push({
            userId: supportRecordSnapshot.userId,
            usageRecordId: supportRecordSnapshot?.usageRecordId ? supportRecordSnapshot.usageRecordId : '',
            attendanceRecord: {
              startAtMillis: supportRecordSnapshot.attendanceRecord.startAt.toMillis(),
              endAtMillis: supportRecordSnapshot.attendanceRecord.endAt ? supportRecordSnapshot.attendanceRecord.endAt.toMillis() : null
            },
            serviceProvisionStatus: supportRecordSnapshot.serviceProvisionStatus,
            workPlaceCompanyId: supportRecordSnapshot.workPlaceCompanyId,
            userStatus: supportRecordSnapshot.userStatus,
            workIds: supportRecordSnapshot.workIds,
            absenceReason: supportRecordSnapshot.absenceReason,
            support: supportRecordSnapshot.support,
            staffReview: supportRecordSnapshot.staffReview,
            interview: supportRecordSnapshot.interview,
            interviewRecord: {
              startAtMillis: supportRecordSnapshot.interviewRecord?.startAt ? supportRecordSnapshot.interviewRecord.startAt.toMillis() : null,
              endAtMillis: supportRecordSnapshot.interviewRecord?.endAt ? supportRecordSnapshot.interviewRecord.endAt.toMillis() : null
            },
            interviewContent: supportRecordSnapshot?.interviewContent,
            other: supportRecordSnapshot.other,
            recorderName: supportRecordSnapshot.recorderName,
            recorderId: supportRecordSnapshot.recorderId,
            createAtMillis: supportRecordSnapshot.createAt.toMillis(),
            updateAtMillis: supportRecordSnapshot.updateAt.toMillis(),
            createdBy: supportRecordSnapshot.createdBy,
            updatedBy: supportRecordSnapshot.updatedBy,
            isDeleted: supportRecordSnapshot.isDeleted,
            supportRecordId: doc.id,
            supportMemberId: supportRecordSnapshot.supportMemberId,
          });
        }
        if(change?.type === 'removed') {
          dispatch(deleteSupportRecord(change.doc.id));
        }
      });
      dispatch(updateSupportRecords(list));
    });
  }, [dispatch])

  const observeWorkRecords = useCallback((organizationId: string, workRecordYearAndMonth: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    const startDate = dayjs(workRecordYearAndMonth).startOf('month');
    const startAt = startDate.toDate();
    const endAt = dayjs(startDate).add(1, 'M').toDate();
    dispatch(resetWorkRecords());
    firestore.collection(selectedOrganization).doc(organizationId).collection('workRecords').orderBy('attendanceRecord.startAt', 'asc').startAt(startAt).endBefore(endAt).onSnapshot((querySnapshot) => {
      const list: StateWorkRecord[] = [];
      querySnapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const workRecordSnapshot = doc.data() as WorkRecord;
        if(change.type === 'added' || change.type === 'modified') {
          list.push({
            userId: workRecordSnapshot.userId,
            usageRecordId: workRecordSnapshot?.usageRecordId ? workRecordSnapshot.usageRecordId : '',
            attendanceRecord: {
              startAtMillis: workRecordSnapshot.attendanceRecord?.startAt ? workRecordSnapshot.attendanceRecord.startAt.toMillis() : 0,
              endAtMillis: workRecordSnapshot.attendanceRecord?.endAt ? workRecordSnapshot.attendanceRecord.endAt.toMillis() : null
            },
            serviceProvisionStatus: workRecordSnapshot.serviceProvisionStatus,
            workIds: workRecordSnapshot.workIds,
            recorderId: workRecordSnapshot.recorderId,
            createAtMillis: workRecordSnapshot.createAt ? workRecordSnapshot.createAt.toMillis() : 0,
            updateAtMillis: workRecordSnapshot.updateAt ? workRecordSnapshot.updateAt.toMillis() : 0,
            createdBy: workRecordSnapshot.createdBy,
            updatedBy: workRecordSnapshot.updatedBy,
            isDeleted: workRecordSnapshot.isDeleted,
            workRecordId: doc.id
          });
        }
        if(change.type === 'removed') {
          dispatch(deleteWorkRecord(change.doc.id));
        }
      });
      dispatch(updateWorkRecords(list));
    });
  }, [dispatch])

  const observeOrganization = useCallback((
    organizationId: string, serviceType: ServiceType
  ) => {
    if (serviceType === 'aType') {
      firestore.collection('organizations').doc(organizationId).onSnapshot((doc) => {
        if (doc.exists) {
          const organizationQuerySnapshot = doc.data() as Organization;
          if(organizationQuerySnapshot.status === 'inActive'){
            authLogout();
            return;
          }
          const {
            createAt,
            updateAt,
            ...params
          } = organizationQuerySnapshot;
          dispatch(setOrganization({
            ...params,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
        } else {
          dispatch(resetOrganization());
        }
      });
    } else if (serviceType === 'bType') {
      firestore.collection('typeBOrganizations').doc(organizationId).onSnapshot((doc) => {
        if (doc.exists) {
          const organizationQuerySnapshot = doc.data() as TypeBOrganization;
          if(organizationQuerySnapshot.status === 'inActive'){
            authLogout();
            return;
          }
          const {
            createAt,
            updateAt,
            ...params
          } = organizationQuerySnapshot;
          dispatch(setTypeBOrganization({
            ...params,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
        } else {
          dispatch(resetTypeBOrganization());
        }
      });
    } else if (serviceType === 'transfer') {
      firestore.collection('transferOrganizations').doc(organizationId).onSnapshot((doc) => {
        if (doc.exists) {
          const organizationQuerySnapshot = doc.data() as TransferOrganization;
          if(organizationQuerySnapshot.status === 'inActive'){
            authLogout();
            return;
          }
          const {
            createAt,
            updateAt,
            ...params
          } = organizationQuerySnapshot;
          dispatch(setTransferOrganization({
            ...params,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
        } else {
          dispatch(resetTransferOrganization());
        }
      });
    } else {
      authLogout();
      return;
    }
  }, [dispatch]);

  const observeBusinessHours = useCallback(async (
    organizationId: string,
    serviceType: ServiceType
  ) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection('settings').doc('businessHours').onSnapshot((businessHoursDocumentData) => {
      if (businessHoursDocumentData.exists) {
        const businessHoursDocumentSnapshot = businessHoursDocumentData.data() as BusinessHours;
        const {
          createAt,
          updateAt,
          ...params
        } = businessHoursDocumentSnapshot;
        dispatch(setBusinessHours({
          ...params,
          createAtMillis: createAt.toMillis(),
          updateAtMillis: updateAt.toMillis(),
        }))
      } else {
        dispatch(resetBusinessHours());
      }
    });
  }, [dispatch])

  const observeWorkingHours = useCallback(async (
    organizationId: string,
    serviceType: ServiceType
  ) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection('settings').doc('workingHours').onSnapshot((workingHoursDocumentData) => {
      if (workingHoursDocumentData.exists) {
        const workingHoursDocumentSnapshot = workingHoursDocumentData.data() as WorkingHours;
        const {
          createAt,
          updateAt,
          ...params
        } = workingHoursDocumentSnapshot;
        dispatch(setWorkingHours({
          ...params,
          createAtMillis: createAt.toMillis(),
          updateAtMillis: updateAt.toMillis(),
        }));
      } else {
        dispatch(resetWorkingHours());
      }
    });
  }, [dispatch]);

  const observeNewWorkingHours = useCallback((organizationId: string, serviceType: ServiceType) => {
      workingHoursRef(organizationId, serviceType).orderBy('createAt', 'asc').onSnapshot((querySnapshot) => {
      const list: StateNewWorkingHour[] = [];
      querySnapshot.forEach(async (doc) => {
        const { createAt, updateAt, ...params} = doc.data() as NewWorkingHours;
        list.push(
          {
            id: doc.id,
            ...params,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
            isSetSundaySetting: params.sunday ? true : false,
            isSetMondaySetting: params.monday ? true : false,
            isSetTuesdaySetting: params.tuesday ? true : false,
            isSetWednesdaySetting: params.wednesday ? true : false,
            isSetThursdaySetting: params.thursday ? true : false,
            isSetFridaySetting: params.friday ? true : false,
            isSetSaturdaySetting: params.saturday ? true : false,
          }
        );
      });
      dispatch(setNewWorkingHours(list));
    });
  }, [dispatch])

  const observeBasicReward = useCallback((organizationId: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection('settings').doc('basicReward').onSnapshot((basicRewardDocumentData) => {
      if (basicRewardDocumentData.exists) {
        if (serviceType === 'aType') {
          const basicRewardDocumentSnapshot = basicRewardDocumentData.data() as BasicReward;
          const {
            createAt,
            updateAt,
            ...params
          } = basicRewardDocumentSnapshot;
          dispatch(setBasicReward({
            ...params,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
          dispatch(resetTypeBBasicReward());
          dispatch(resetTransferBasicReward());
        } else if (serviceType === 'bType') {
          const basicRewardDocumentSnapshot = basicRewardDocumentData.data() as TypeBBasicReward;
          const {
            createAt,
            updateAt,
            ...params
          } = basicRewardDocumentSnapshot;
          dispatch(setTypeBBasicReward({
            ...params,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
          dispatch(resetBasicReward());
          dispatch(resetTransferBasicReward());
        } else if (serviceType === 'transfer') {
          const basicRewardDocumentSnapshot = basicRewardDocumentData.data() as TransferBasicReward;
          const {
            createAt,
            updateAt,
            ...params
          } = basicRewardDocumentSnapshot;
          dispatch(setTransferBasicReward({
            ...params,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
          dispatch(resetBasicReward());
          dispatch(resetTypeBBasicReward());
        }
      } else {
        dispatch(resetBasicReward());
        dispatch(resetTypeBBasicReward());
        dispatch(resetTransferBasicReward());
      }
    });
    firestore.collection(selectedOrganization).doc(organizationId).collection('settings').doc('basicReward2024').onSnapshot((basicRewardDocumentData) => {
      if (basicRewardDocumentData.exists) {
        if (serviceType === 'aType') {
          const basicRewardDocumentSnapshot = basicRewardDocumentData.data() as BasicReward2024;
          const {
            createAt,
            updateAt,
            ...params
          } = basicRewardDocumentSnapshot;
          dispatch(setBasicReward2024({
            ...params,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
        } else if (serviceType === 'bType') {
          dispatch(resetBasicReward2024());
        } else if (serviceType === 'transfer') {
          dispatch(resetBasicReward2024());
        }
      } else {
        dispatch(resetBasicReward2024());
      }
    });
  }, [dispatch]);

  const observeSubtractingRewardItem = useCallback((organizationId: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection('settings').doc('subtractingRewardItem').onSnapshot((subtractingRewardItemDocumentData) => {
      if (subtractingRewardItemDocumentData.exists) {
        if (serviceType === 'aType') {
          const subtractingRewardItemDocumentSnapshot = subtractingRewardItemDocumentData.data() as SubtractingRewardItem;
          const {
            vacantPosition,
            createAt,
            updateAt,
            ...params
          } = subtractingRewardItemDocumentSnapshot;
          dispatch(setSubtractingRewardItem({
            ...params,
            vacantPosition: {
              isVacancyOfStaff: vacantPosition.isVacancyOfStaff,
              position: vacantPosition.position,
              startAtMillis: vacantPosition.startAt.toMillis(),
            },
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
          dispatch(resetTypeBSubtractingRewardItem());
          dispatch(resetTransferSubtractingRewardItem());
        } else if (serviceType === 'bType') {
          const subtractingRewardItemDocumentSnapshot = subtractingRewardItemDocumentData.data() as TypeBSubtractingRewardItem;
          const {
            vacantPosition,
            createAt,
            updateAt,
            ...params
          } = subtractingRewardItemDocumentSnapshot;
          dispatch(setTypeBSubtractingRewardItem({
            ...params,
            vacantPosition: {
              isVacancyOfStaff: vacantPosition.isVacancyOfStaff,
              position: vacantPosition.position,
              startAtMillis: vacantPosition.startAt.toMillis(),
            },
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
          dispatch(resetSubtractingRewardItem());
          dispatch(resetTransferSubtractingRewardItem());
        } else if (serviceType === 'transfer') {
          const subtractingRewardItemDocumentSnapshot = subtractingRewardItemDocumentData.data() as TransferSubtractingRewardItem;
          const {
            vacantPosition,
            createAt,
            updateAt,
            ...params
          } = subtractingRewardItemDocumentSnapshot;
          dispatch(setTransferSubtractingRewardItem({
            ...params,
            vacantPosition: {
              isVacancyOfStaff: vacantPosition.isVacancyOfStaff,
              position: vacantPosition.position,
              startAtMillis: vacantPosition.startAt.toMillis(),
            },
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
          dispatch(resetSubtractingRewardItem());
          dispatch(resetTypeBSubtractingRewardItem());
        }
      } else {
        dispatch(resetSubtractingRewardItem());
        dispatch(resetTypeBSubtractingRewardItem());
        dispatch(resetTransferSubtractingRewardItem());
      }
    });
    firestore.collection(selectedOrganization).doc(organizationId).collection('settings').doc('subtractingRewardItem2024').onSnapshot((subtractingRewardItemDocumentData) => {
      if (subtractingRewardItemDocumentData.exists) {
        if (serviceType === 'aType') {
          const subtractingRewardItemDocumentSnapshot = subtractingRewardItemDocumentData.data() as SubtractingRewardItem2024;
          const {
            vacantPosition,
            createAt,
            updateAt,
            ...params
          } = subtractingRewardItemDocumentSnapshot;
          dispatch(setSubtractingRewardItem2024({
            ...params,
            vacantPosition: {
              isVacancyOfStaff: vacantPosition.isVacancyOfStaff,
              position: vacantPosition.position,
              startAtMillis: vacantPosition.startAt.toMillis(),
            },
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
          dispatch(resetTypeBSubtractingRewardItem2024());
        } else if (serviceType === 'bType') {
          const subtractingRewardItemDocumentSnapshot = subtractingRewardItemDocumentData.data() as TypeBSubtractingRewardItem2024;
          const {
            vacantPosition,
            createAt,
            updateAt,
            ...params
          } = subtractingRewardItemDocumentSnapshot;
          dispatch(setTypeBSubtractingRewardItem2024({
            ...params,
            vacantPosition: {
              isVacancyOfStaff: vacantPosition.isVacancyOfStaff,
              position: vacantPosition.position,
              startAtMillis: vacantPosition.startAt.toMillis(),
            },
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
          }));
          dispatch(resetSubtractingRewardItem2024());
        } else if (serviceType === 'transfer') {
          dispatch(resetSubtractingRewardItem2024());
          dispatch(resetTypeBSubtractingRewardItem2024());
        }
      } else {
        dispatch(resetSubtractingRewardItem2024());
        dispatch(resetTypeBSubtractingRewardItem2024());
      }
    });
  }, [dispatch]);

  const observeAddingRewardItem = useCallback((organizationId: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection('settings').doc('addingRewardItem').onSnapshot((addingRewardItemDocumentData) => {
      if (addingRewardItemDocumentData.exists) {
        if(serviceType === 'aType') {
          const addingRewardItemDocumentSnapshot = addingRewardItemDocumentData.data() as AddingRewardItem;
          const {
            welfareSpecialist,
            instructorOfWageImprovement,
            createAt,
            updateAt,
            ...params
          } = addingRewardItemDocumentSnapshot;
          dispatch(setAddingRewardItem({
            ...params,
            welfareSpecialist: {
              placementType: welfareSpecialist.placementType,
              startAtMillis: welfareSpecialist.startAt ? welfareSpecialist.startAt.toMillis() : null,
              endAtMillis: welfareSpecialist.endAt ? welfareSpecialist.endAt.toMillis() : null
            },
            instructorOfWageImprovement: {
              startAtMillis: instructorOfWageImprovement.startAt ? instructorOfWageImprovement.startAt.toMillis() : null,
              endAtMillis: instructorOfWageImprovement.endAt ? instructorOfWageImprovement.endAt.toMillis() : null
            },
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis()
          }));
          dispatch(resetTypeBAddingRewardItem());
          dispatch(resetTransferAddingRewardItem());
        } else if (serviceType === 'bType') {
          const addingRewardItemDocumentSnapshot = addingRewardItemDocumentData.data() as TypeBAddingRewardItem;
          const {
            welfareSpecialist,
            instructorOfTargetWage,
            createAt,
            updateAt,
            ...params
          } = addingRewardItemDocumentSnapshot;
          dispatch(setTypeBAddingRewardItem({
            ...params,
            welfareSpecialist: {
              placementType: welfareSpecialist.placementType,
              startAtMillis: welfareSpecialist.startAt ? welfareSpecialist.startAt.toMillis() : null,
              endAtMillis: welfareSpecialist.endAt ? welfareSpecialist.endAt.toMillis() : null
            },
            instructorOfTargetWage: {
              startAtMillis: instructorOfTargetWage.startAt ? instructorOfTargetWage.startAt.toMillis() : null,
              endAtMillis: instructorOfTargetWage.endAt ? instructorOfTargetWage.endAt.toMillis() : null
            },
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis()
          }));
          dispatch(resetAddingRewardItem());
          dispatch(resetTransferAddingRewardItem());
        } else if (serviceType === 'transfer') {
          const addingRewardItemDocumentSnapshot = addingRewardItemDocumentData.data() as TransferAddingRewardItem;
          const {
            welfareSpecialist,
            createAt,
            updateAt,
            ...params
          } = addingRewardItemDocumentSnapshot;
          dispatch(setTransferAddingRewardItem({
            ...params,
            welfareSpecialist: {
              placementType: welfareSpecialist.placementType,
              startAtMillis: welfareSpecialist.startAt ? welfareSpecialist.startAt.toMillis() : null,
              endAtMillis: welfareSpecialist.endAt ? welfareSpecialist.endAt.toMillis() : null
            },
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis()
          }));
          dispatch(resetAddingRewardItem());
          dispatch(resetTypeBAddingRewardItem());
        }
      } else {
        dispatch(resetAddingRewardItem());
        dispatch(resetTypeBAddingRewardItem());
        dispatch(resetTransferAddingRewardItem());
      }
    });
    firestore.collection(selectedOrganization).doc(organizationId).collection('settings').doc('addingRewardItem2024').onSnapshot((addingRewardItemDocumentData) => {
      if (addingRewardItemDocumentData.exists) {
        if(serviceType === 'aType') {
          const addingRewardItemDocumentSnapshot = addingRewardItemDocumentData.data() as AddingRewardItem2024;
          const {
            welfareSpecialist,
            instructorOfWageImprovement,
            createAt,
            updateAt,
            ...params
          } = addingRewardItemDocumentSnapshot;
          dispatch(setAddingRewardItem2024({
            ...params,
            welfareSpecialist: {
              placementType: welfareSpecialist.placementType,
              startAtMillis: welfareSpecialist.startAt ? welfareSpecialist.startAt.toMillis() : null,
              endAtMillis: welfareSpecialist.endAt ? welfareSpecialist.endAt.toMillis() : null
            },
            instructorOfWageImprovement: {
              startAtMillis: instructorOfWageImprovement.startAt ? instructorOfWageImprovement.startAt.toMillis() : null,
              endAtMillis: instructorOfWageImprovement.endAt ? instructorOfWageImprovement.endAt.toMillis() : null
            },
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis()
          }));
          dispatch(resetTypeBAddingRewardItem2024());
        } else if (serviceType === 'bType') {
          const addingRewardItemDocumentSnapshot = addingRewardItemDocumentData.data() as TypeBAddingRewardItem2024;
          const {
            welfareSpecialist,
            instructorOfTargetWage,
            createAt,
            updateAt,
            ...params
          } = addingRewardItemDocumentSnapshot;
          dispatch(setTypeBAddingRewardItem2024({
            ...params,
            welfareSpecialist: {
              placementType: welfareSpecialist.placementType,
              startAtMillis: welfareSpecialist.startAt ? welfareSpecialist.startAt.toMillis() : null,
              endAtMillis: welfareSpecialist.endAt ? welfareSpecialist.endAt.toMillis() : null
            },
            instructorOfTargetWage: {
              startAtMillis: instructorOfTargetWage.startAt ? instructorOfTargetWage.startAt.toMillis() : null,
              endAtMillis: instructorOfTargetWage.endAt ? instructorOfTargetWage.endAt.toMillis() : null
            },
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis()
          }));
          dispatch(resetAddingRewardItem2024());
        } else if (serviceType === 'transfer') {
          dispatch(resetAddingRewardItem2024());
          dispatch(resetTypeBAddingRewardItem2024());
        }
      } else {
        dispatch(resetAddingRewardItem2024());
        dispatch(resetTypeBAddingRewardItem2024());
      }
    });
  }, [dispatch]);

  const dispatchFirestoreUsagesSetting = useCallback(async (
    userId: string,
    serviceType: ServiceType
  ) => {
    const selectedUsageType = getSelectedUsage(serviceType);
    firestore.collection('users').doc(userId).collection(settings).doc(selectedUsageType).onSnapshot((usagesDocumentData) => {
      if (usagesDocumentData.exists) {
        if(serviceType === 'aType') {
          const usagesDocumentSnapshot = usagesDocumentData.data() as Usages
          const {
            createAt,
            updateAt,
            hasReceivedService,
            hasReceivedCertification,
            supportPlanStatus,
            supportForEmploymentTransition,
            specialCaseForUsage,
            intensiveSupport,
            ...params
          } = usagesDocumentSnapshot;
          dispatch(setUsages({
            ...params,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
            hasReceivedService: {
              startAtMillis: hasReceivedService.startAt ? hasReceivedService.startAt.toMillis() : null,
              endAtMillis: hasReceivedService.endAt ? hasReceivedService.endAt.toMillis() : null,
            },
            hasReceivedCertification: {
              startAtMillis: hasReceivedCertification.startAt ? hasReceivedCertification.startAt.toMillis() : null,
              endAtMillis: hasReceivedCertification.endAt ? hasReceivedCertification.endAt.toMillis() : null,
            },
            supportPlanStatus: {
              isNotCreated: supportPlanStatus.isNotCreated,
              startAtMillis: supportPlanStatus.startAt ? supportPlanStatus.startAt.toMillis() : null,
            },
            supportForEmploymentTransition: {
              isAdded: supportForEmploymentTransition.isAdded,
              startAtMillis: supportForEmploymentTransition.startAt ? supportForEmploymentTransition.startAt.toMillis() : null,
            },
            specialCaseForUsage: specialCaseForUsage ? { // 元々A型と移行でspecialCaseForUsageが漏れていたためundenedの場合がある
              isSpecialCase: specialCaseForUsage.isSpecialCase,
              startAtMillis: specialCaseForUsage.startAt ? specialCaseForUsage.startAt.toMillis() : null,
              endAtMillis: specialCaseForUsage.endAt ? specialCaseForUsage.endAt.toMillis() : null,
            } : {
              isSpecialCase: false,
              startAtMillis: null,
              endAtMillis: null,
            },
            intensiveSupport: {
              isStarted: intensiveSupport?.isStarted || false,
              startAtMillis: intensiveSupport?.startAt ? intensiveSupport.startAt.toMillis() : null,
            },
          }));
          dispatch(resetTypeBUsages())
          dispatch(resetTransferUsages());
        } else if (serviceType === 'bType') {
          const usagesDocumentSnapshot = usagesDocumentData.data() as TypeBUsages
          const {
            createAt,
            updateAt,
            hasReceivedService,
            hasReceivedCertification,
            supportPlanStatus,
            supportForEmploymentTransition,
            specialCaseForUsage,
            disabilityClassification,
            intensiveSupport,
            ...params
          } = usagesDocumentSnapshot;
          dispatch(setTypeBUsages({
            ...params,
            disabilityClassification: disabilityClassification ?? 0,
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
            hasReceivedService: {
              startAtMillis: hasReceivedService.startAt ? hasReceivedService.startAt.toMillis() : null,
              endAtMillis: hasReceivedService.endAt ? hasReceivedService.endAt.toMillis() : null,
            },
            hasReceivedCertification: {
              startAtMillis: hasReceivedCertification.startAt ? hasReceivedCertification.startAt.toMillis() : null,
              endAtMillis: hasReceivedCertification.endAt ? hasReceivedCertification.endAt.toMillis() : null,
            },
            supportPlanStatus: {
              isNotCreated: supportPlanStatus.isNotCreated,
              startAtMillis: supportPlanStatus.startAt ? supportPlanStatus.startAt.toMillis() : null,
            },
            supportForEmploymentTransition: {
              isAdded: supportForEmploymentTransition.isAdded,
              startAtMillis: supportForEmploymentTransition.startAt ? supportForEmploymentTransition.startAt.toMillis() : null,
            },
            specialCaseForUsage: {
              isSpecialCase: specialCaseForUsage.isSpecialCase,
              startAtMillis: specialCaseForUsage.startAt ? specialCaseForUsage.startAt.toMillis() : null,
              endAtMillis: specialCaseForUsage.endAt ? specialCaseForUsage.endAt.toMillis() : null,
            },
            intensiveSupport: {
              isStarted: intensiveSupport?.isStarted || false,
              startAtMillis: intensiveSupport?.startAt ? intensiveSupport.startAt.toMillis() : null,
            },
          }));
          dispatch(resetUsages());
          dispatch(resetTransferUsages());
        } else if (serviceType === "transfer") {
          const usagesDocumentSnapshot =
            usagesDocumentData.data() as TransferUsages;
          const {
            createAt,
            updateAt,
            hasReceivedService,
            hasReceivedCertification,
            supportPlanStatus,
            supportForEmploymentTransition,
            specialCaseForUsage,
            localCooperationMeeting,
            disabilityClassification,
            ...params
          } = usagesDocumentSnapshot;
          dispatch(
            setTransferUsages({
              ...params,
              disabilityClassification: disabilityClassification ?? 0,
              createAtMillis: createAt.toMillis(),
              updateAtMillis: updateAt.toMillis(),
              hasReceivedService: {
                startAtMillis: hasReceivedService.startAt
                  ? hasReceivedService.startAt.toMillis()
                  : null,
                endAtMillis: hasReceivedService.endAt
                  ? hasReceivedService.endAt.toMillis()
                  : null,
              },
              hasReceivedCertification: {
                startAtMillis: hasReceivedCertification.startAt
                  ? hasReceivedCertification.startAt.toMillis()
                  : null,
                endAtMillis: hasReceivedCertification.endAt
                  ? hasReceivedCertification.endAt.toMillis()
                  : null,
              },
              supportPlanStatus: {
                isNotCreated: supportPlanStatus.isNotCreated,
                startAtMillis: supportPlanStatus.startAt
                  ? supportPlanStatus.startAt.toMillis()
                  : null,
              },
              supportForEmploymentTransition: {
                isAdded: supportForEmploymentTransition.isAdded,
                startAtMillis: supportForEmploymentTransition.startAt
                  ? supportForEmploymentTransition.startAt.toMillis()
                  : null,
              },
              specialCaseForUsage: specialCaseForUsage ? { // 元々A型と移行でspecialCaseForUsageが漏れていたためundenedの場合がある
                isSpecialCase: specialCaseForUsage.isSpecialCase,
                startAtMillis: specialCaseForUsage.startAt ? specialCaseForUsage.startAt.toMillis() : null,
                endAtMillis: specialCaseForUsage.endAt ? specialCaseForUsage.endAt.toMillis() : null,
              } : {
                isSpecialCase: false,
                startAtMillis: null,
                endAtMillis: null,
              },
              localCooperationMeeting: {
                isAdded: localCooperationMeeting.isAdded,
                startAtMillis: localCooperationMeeting.startAt ? localCooperationMeeting.startAt.toMillis() : null,
              },
            })
          );
          dispatch(resetUsages());
          dispatch(resetTypeBUsages());
      }
      } else {
        dispatch(resetUsages());
        dispatch(resetTypeBUsages());
        dispatch(resetTransferUsages());
      }
    });
  }, [dispatch]);

  const dispatchFirestoreProfileSetting = useCallback(async (
    userId: string
  ) => {
    firestore.collection('users').doc(userId).collection(settings).doc('profile').onSnapshot((doc) => {
      if (doc.exists) {
        const profileDocumentSnapshot = doc.data() as UserProfile;
        const { createAt, updateAt, ...params } = profileDocumentSnapshot
        dispatch(setProfile({
          ...params,
          createAtMillis: profileDocumentSnapshot.createAt.toMillis(),
          updateAtMillis: profileDocumentSnapshot.updateAt.toMillis(),
        }));
      } else {
        dispatch(resetProfile())
      }
    });
  }, [dispatch]);

  const dispatchFirestoreBeneficiaryCertificateSetting = useCallback(async (
    userId: string
  ) => {
    firestore.collection('users').doc(userId).collection(settings).doc('beneficiaryCertificate').onSnapshot((beneficiaryCertificateDocumentData) => {
      if (beneficiaryCertificateDocumentData.exists) {
        const beneficiaryCertificateDocumentSnapshot = beneficiaryCertificateDocumentData.data() as BeneficiaryCertificate;
        const {
          createAt,
          updateAt,
          periodOfMaximuMonthlyBurden,
          periodOfMealProvisionSystemAddition,
          certificationValidityPeriodOfDisabilitySupportCategory,
          supportDecisionPeriodforCareBenefits,
          paymentPeriodOfPlanningSupportExpenses,
          monitoringPeriodOfPlanningSupportExpenses,
          ...params
        } = beneficiaryCertificateDocumentSnapshot;
        dispatch(setBeneficiaryCertificate({
          ...params,
          periodOfMaximuMonthlyBurden: {
            hasQualification: periodOfMaximuMonthlyBurden.hasQualification,
            startAtMillis: periodOfMaximuMonthlyBurden.startAt ? periodOfMaximuMonthlyBurden.startAt.toMillis() : null,
            endAtMillis: periodOfMaximuMonthlyBurden.endAt ? periodOfMaximuMonthlyBurden.endAt.toMillis() : null
          },
          periodOfMealProvisionSystemAddition: {
            hasQualification: periodOfMealProvisionSystemAddition.hasQualification,
            startAtMillis: periodOfMealProvisionSystemAddition.startAt ? periodOfMealProvisionSystemAddition.startAt.toMillis() : null,
            endAtMillis: periodOfMealProvisionSystemAddition.endAt ? periodOfMealProvisionSystemAddition.endAt.toMillis() : null
          },
          certificationValidityPeriodOfDisabilitySupportCategory: {
            hasQualification: certificationValidityPeriodOfDisabilitySupportCategory.hasQualification,
            startAtMillis: certificationValidityPeriodOfDisabilitySupportCategory.startAt ? certificationValidityPeriodOfDisabilitySupportCategory.startAt.toMillis() : null,
            endAtMillis: certificationValidityPeriodOfDisabilitySupportCategory.endAt ? certificationValidityPeriodOfDisabilitySupportCategory.endAt.toMillis() : null
          },
          supportDecisionPeriodforCareBenefits: {
            hasQualification: supportDecisionPeriodforCareBenefits.hasQualification,
            startAtMillis: supportDecisionPeriodforCareBenefits.startAt ? supportDecisionPeriodforCareBenefits.startAt.toMillis() : null,
            endAtMillis: supportDecisionPeriodforCareBenefits.endAt ? supportDecisionPeriodforCareBenefits.endAt.toMillis() : null
          },
          paymentPeriodOfPlanningSupportExpenses: {
            hasQualification: paymentPeriodOfPlanningSupportExpenses.hasQualification,
            startAtMillis: paymentPeriodOfPlanningSupportExpenses.startAt ? paymentPeriodOfPlanningSupportExpenses.startAt.toMillis() : null,
            endAtMillis: paymentPeriodOfPlanningSupportExpenses.endAt ? paymentPeriodOfPlanningSupportExpenses.endAt.toMillis() : null
          },
          monitoringPeriodOfPlanningSupportExpenses: {
            hasQualification: monitoringPeriodOfPlanningSupportExpenses.hasQualification,
            startAtMillis: monitoringPeriodOfPlanningSupportExpenses.startAt ? monitoringPeriodOfPlanningSupportExpenses.startAt.toMillis() : null,
            endAtMillis: monitoringPeriodOfPlanningSupportExpenses.endAt ? monitoringPeriodOfPlanningSupportExpenses.endAt.toMillis() : null
          },
          createAtMillis: createAt ? createAt.toMillis() : null,
          updateAtMillis: updateAt ? updateAt.toMillis() : null,
        }))
      } else {
        dispatch(resetBeneficiaryCertificate())
      }
    });
  }, [dispatch]);

  const observeInitialSetting = useCallback((organizationId: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    const docRef = firestore.collection(selectedOrganization).doc(organizationId).collection('settings').doc('initialSetting');
    docRef.onSnapshot((doc) => {
      const initialSettingSnapshot = doc.data() as InitialSetting;
      if (initialSettingSnapshot) {
        if (!initialSettingSnapshot.openDays) {
          initialSettingSnapshot.openDays = {oneMonthAgo:0, twoMonthAgo:0, threeMonthAgo:0};
        } // openDaysを追加したことによるバグ防止
        const initialSettingDoc: StateInitialSetting = {
          initialClaimWork: {
            startAtMillis: initialSettingSnapshot.initialClaimWork.startAt.toMillis(),
          },
          numberOfUsage: {
            oneMonthAgo: initialSettingSnapshot.numberOfUsage.oneMonthAgo,
            twoMonthAgo: initialSettingSnapshot.numberOfUsage.twoMonthAgo,
            threeMonthAgo: initialSettingSnapshot.numberOfUsage.threeMonthAgo,
          },
          openDays: {
            oneMonthAgo: initialSettingSnapshot.openDays.oneMonthAgo,
            twoMonthAgo: initialSettingSnapshot.openDays.twoMonthAgo,
            threeMonthAgo: initialSettingSnapshot.openDays.threeMonthAgo,
          },
          numberOfSupportForTraining: initialSettingSnapshot.numberOfSupportForTraining.filter((doc) => !doc.isDeleted),
          createAtMillis: initialSettingSnapshot.createAt.toMillis(),
          updateAtMillis: initialSettingSnapshot.updateAt.toMillis(),
          createdBy: initialSettingSnapshot.createdBy,
          updatedBy: initialSettingSnapshot.updatedBy,
        }
        dispatch(setInitialSetting(initialSettingDoc));
      } else {
        dispatch(resetInitialSetting());
      }
    });
  }, [dispatch])

  const observeWorkItems = useCallback((organizationId: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection('workItems').onSnapshot((querySnapshot) => {
      const list: StateWorkItem[] = [];
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(async (doc) => {
          const workItemSnapshot = doc.data() as WorkItem;
          list.push({
            workItemName: workItemSnapshot.workItemName,
            workItemCategory: workItemSnapshot.workItemCategory,
            workItemId: doc.id,
            createAtMillis: workItemSnapshot.createAt.toMillis(),
            updateAtMillis: workItemSnapshot.updateAt.toMillis(),
            createdBy: workItemSnapshot.createdBy,
            updatedBy: workItemSnapshot.updatedBy,
            isDeleted: workItemSnapshot.isDeleted,
          });
        });
        dispatch(setWorkItems(list));
      } else {
        dispatch(resetWorkItems())
      }
    });
  }, [dispatch]);

  const observeInvoices = useCallback((organizationId: string) => {
    firestore.collection('organizations').doc(organizationId).collection('invoices').onSnapshot((querySnapshot) => {
      const list: StateInvoice[] = [];
      querySnapshot.forEach(async (doc) => {
        const invoicesSnapshot = doc.data() as Invoice;
        list.push({
          targetMonthMillis: invoicesSnapshot.targetMonth.toMillis(),
          fileType: invoicesSnapshot.fileType,
          createAtMillis: invoicesSnapshot.createAt.toMillis(),
          updateAtMillis: invoicesSnapshot.updateAt.toMillis(),
        });
      });
      dispatch(setInvoices(list))
    });
  }, [dispatch]);

  const observeWorkPlaceCompanies = useCallback((organizationId: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection(workPlaceCompanies).where('isDeleted', '==', false).onSnapshot((querySnapshot) => {
      const list: StateWorkPlaceCompany[] = [];
      if (querySnapshot.size > 0) {
        querySnapshot.forEach(async (doc) => {
          const workPlaceCompanySnapshot = doc.data() as WorkPlaceCompany;
          list.push({
            companyName: workPlaceCompanySnapshot.companyName,
            postalCode: workPlaceCompanySnapshot.postalCode,
            prefecture: workPlaceCompanySnapshot.prefecture,
            city: workPlaceCompanySnapshot.city,
            town: workPlaceCompanySnapshot.town,
            address: workPlaceCompanySnapshot.address,
            phoneNumber: workPlaceCompanySnapshot.phoneNumber,
            contract: {
              startAtMillis: workPlaceCompanySnapshot.contract.startAt.toMillis(),
              endAtMillis: workPlaceCompanySnapshot.contract.endAt.toMillis(),
              workDate: workPlaceCompanySnapshot.contract.workDate,
              workTime: workPlaceCompanySnapshot.contract.workTime,
              workContent: workPlaceCompanySnapshot.contract.workContent
            },
            plannedUser: workPlaceCompanySnapshot.plannedUser,
            note: workPlaceCompanySnapshot.note,
            supplementaryMemo: workPlaceCompanySnapshot.supplementaryMemo,
            createAtMillis: workPlaceCompanySnapshot.createAt.toMillis(),
            updateAtMillis: workPlaceCompanySnapshot.updateAt.toMillis(),
            createdBy: workPlaceCompanySnapshot.createdBy,
            updatedBy: workPlaceCompanySnapshot.updatedBy,
            isDeleted: workPlaceCompanySnapshot.isDeleted,
            workPlaceCompanyId: doc.id,
          });
        });
        dispatch(setWorkPlaceCompanies(list));
      } else {
        dispatch(resetWorkPlaceCompanies());
      }
    });
  }, [dispatch]);

  const observeBusinessLogs = useCallback((organizationId: string, businessLogYearMonth: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    const startAt = dayjs(businessLogYearMonth).toDate();
    const endAt = dayjs(businessLogYearMonth).add(1, 'M').toDate();
    dispatch(resetBusinessLogs());
    const businessLogsCollectionRef = firestore.collection(selectedOrganization).doc(organizationId).collection(businessLogs).orderBy('createAt', 'asc').startAt(startAt).endBefore(endAt);
    businessLogsCollectionRef.onSnapshot((querySnapshot) => {
      const list: StateBusinessLog[] = [];
      querySnapshot.docChanges().forEach((change) => {
        const doc = change.doc;
        const businessLogSnapshot = doc.data() as BusinessLog;
        if(change.type === 'added' || change.type === 'modified'){
          list.push({
            am: businessLogSnapshot.am,
            pm: businessLogSnapshot.pm,
            others: businessLogSnapshot.others,
            works: businessLogSnapshot.works,
            outOfFacilityWorking: businessLogSnapshot.outOfFacilityWorking,
            recorderName: businessLogSnapshot.recorderName,
            recorderId: businessLogSnapshot.recorderId,
            createdBy: businessLogSnapshot.createdBy,
            updatedBy: businessLogSnapshot.updatedBy,
            createAtMillis: businessLogSnapshot.createAt.toMillis(),
            updateAtMillis: businessLogSnapshot.updateAt.toMillis(),
            businessLogId: doc.id,
            isDeleted: businessLogSnapshot.isDeleted,
          });
        }
        if(change.type === 'removed'){
          dispatch(deleteBusinessLog(doc.id));
        }
      });
      dispatch(updateBusinessLogs(list));
    });
  }, [dispatch]);

  const observeStripeInfo = useCallback((organizationId: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection('settings').doc('stripeInfo').onSnapshot((doc) => {
      const stripeInfoSnapshot = doc.data() as StripeInfo;
      if (stripeInfoSnapshot) {
        dispatch(setStripeInfo(stripeInfoSnapshot));
      } else {
        dispatch(resetStripeInfo());
      }
    });
  }, [dispatch]);

  const observeInterviewRecords = useCallback((organizationId: string, userPeriod: ArbitraryPeriod, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    const startAt = dayjs(userPeriod.startDate).toDate();
    const endAt = dayjs(userPeriod.endDate).add(1, 'day').toDate(); // 最終日の翌日
    const interviewRecordsRef = firestore.collection(selectedOrganization).doc(organizationId).collection(interviewRecords).orderBy('attendanceRecord.startAt', 'asc').startAt(startAt).endBefore(endAt);
    interviewRecordsRef.onSnapshot((querySnapshot) => {
      const list: StateInterviewRecord[] = [];
      querySnapshot.forEach(async (doc) => {
        const interviewRecordSnapshot = doc.data() as InterviewRecord;
        if (!interviewRecordSnapshot.isDeleted) {
          const {
            attendanceRecord,
            interviewRecord,
            createAt,
            updateAt,
            ...params
          } = interviewRecordSnapshot
          list.push({
            ...params,
            attendanceRecord: {
              startAtMillis: attendanceRecord.startAt.toMillis(),
              endAtMillis: attendanceRecord.endAt ? attendanceRecord.endAt.toMillis() : null,
            },
            interviewRecord: {
              startAtMillis: interviewRecord.startAt ? interviewRecord.startAt.toMillis() : null,
              endAtMillis: interviewRecord.endAt ? interviewRecord.endAt.toMillis() : null,
            },
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
            interviewRecordId: doc.id
          });
        }
      });
      dispatch(setInterviewRecords(list));
    });
  }, [dispatch]);

  const observeSupportPlanReviews = useCallback((organizationId: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    const supportPlanReviewsRef = firestore.collection(selectedOrganization).doc(organizationId).collection(supportPlanReviews);
    supportPlanReviewsRef.onSnapshot((querySnapshot) => {
      const list: StateSupportPlanReview[] = [];
      querySnapshot.forEach(async (doc) => {
        const supportPlanReviewSnapshot = doc.data() as SupportPlanReview;
        if (!supportPlanReviewSnapshot.isDeleted) {
          const {
            summary,
            meetingForReview,
            createAt,
            updateAt,
            ...params
          } = supportPlanReviewSnapshot;
          list.push({
            ...params,
            summary: {
              comment: summary.comment,
              reviewAtMillis: summary.reviewAt ? summary.reviewAt.toMillis() : null,
              reviewedBy: summary.reviewedBy,
            },
            meetingForReview: {
              dateAtMillis: meetingForReview.dateAt ? meetingForReview.dateAt.toMillis() : null,
              note: meetingForReview.note,
            },
            createAtMillis: createAt.toMillis(),
            updateAtMillis: updateAt.toMillis(),
            supportPlanReviewId: doc.id
          });
        }
      });
      dispatch(setSupportPlanReviews(list));
    });
  }, [dispatch]);

  const observeBilling = useCallback((organizationId: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection('billing').orderBy('createAt', 'desc').onSnapshot((querySnapshot) => {
      const list: StateBilling[] = [];
      querySnapshot.forEach(async (doc) => {
        const billingSnapshot = doc.data() as Billing;
        const { createAt, ...props } = billingSnapshot;
        list.push({
          ...props,
          createAtMillis: createAt.toMillis(),
        });
      });
      dispatch(setBilling(list))
    });
  }, [dispatch]);

  const observeTemporaryLink = useCallback((organizationId: string,  serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection('temporaryLinks').where('status', '==', 'active').onSnapshot((querySnapshot) => {
      const list: string[] = [];
      querySnapshot.forEach(async (doc) => {
        list.push(doc.id);
      });
      dispatch(setTemporaryLink(list));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNews = useCallback(async(organizationId: string) => {
    const newsListData = await firestore.collection('news').where('isDeleted', '==', false).where('isPublish', '==', true).orderBy('updateAt','desc').withConverter(newsConverter).limit(10).get();
    const snapshot = newsListData.docs;
    dispatch(setNews(snapshot.map(doc => doc.data())));
  },[dispatch]);

  const observeReadNews = useCallback((organizationId: string, uid: string, serviceType: ServiceType) => {
    const selectedOrganization = getSelectedOrganization(serviceType);
    firestore.collection(selectedOrganization).doc(organizationId).collection('readNews').where('createdBy', '==', uid).withConverter(readNewsConverter).onSnapshot(async (querySnapshot) => {
      const snapshot = querySnapshot.docs;
      dispatch(setReadNews(snapshot.map(doc => doc.data())));
    });
  },[dispatch]);

  const observeMember = useCallback((uid: string) => {
    firestore.collection('members').doc(uid).onSnapshot(async(doc: any) => {
      if (doc.data()) {
        const memberData = doc.data() as StateMember;
        const roles = memberData.roles;
        const aTypeOrgIds = roles.filter((n) => n.serviceType === 'aType').map((m) => m.organizationId);
        const bTypeOrgIds = roles.filter((n) => n.serviceType === 'bType').map((m) => m.organizationId);
        const transferOrgIds = roles.filter((n) => n.serviceType === 'transfer').map((m) => m.organizationId);
        const orgList: Array<{organizationId: string, status: string, serviceType: ServiceType}> = [];
        for (let index = 0; index < aTypeOrgIds.length; index++) {
          const orgId = aTypeOrgIds[index];
          const orgDocumentSnapshot = await getFirestoreOrganization(orgId);
          const orgData = orgDocumentSnapshot.data() as Organization;
          orgList.push({organizationId: orgDocumentSnapshot.id, status: orgData?.status ?? 'inActive', serviceType: 'aType'});
        }
        for (let index = 0; index < bTypeOrgIds.length; index++) {
          const typeBOrgId = bTypeOrgIds[index];
          const typeBOrgDocumentSnapshot = await getFirestoreTypeBOrganization(typeBOrgId);
          const typeBOrgData = typeBOrgDocumentSnapshot.data() as TypeBOrganization;
          orgList.push({organizationId: typeBOrgDocumentSnapshot.id, status: typeBOrgData?.status ?? "inActive", serviceType: 'bType'});
        }
        for (let index = 0; index < transferOrgIds.length; index++) {
          const transferOrgId = transferOrgIds[index];
          const transferOrgDocumentSnapshot = await getFirestoreTransferOrganization(transferOrgId);
          const transferOrgData = transferOrgDocumentSnapshot.data() as TransferOrganization;
          orgList.push({organizationId: transferOrgDocumentSnapshot.id, status: transferOrgData?.status ?? "inActive", serviceType: 'transfer'});
        }
        // 各組織のstatusを取得する
        // TODO:persistから参照するコードを追加する
        const isExistActiveOrg = orgList.find((org) => org.status === 'active');
        if (!organizationId) {
          // persistに入っていない場合
          if(isExistActiveOrg){
            dispatch(setSelectedOrganization({
              organizationId: isExistActiveOrg.organizationId,
              serviceType: isExistActiveOrg.serviceType
            }));
          } else {
            authLogout();
            dispatch(logout());
            dispatch(updateError({
              urlString: "/login",
              errors: [{
              mainMenu: 'ログイン',
              message: {
                text: '全ての事業所アカウントが停止しています',
                type: 'alert',
                isInStateUser: true,
              }
            }]}));
            return;
          }
        } else {
          const organizationIds = doc.data().organizationIds;
          const selectedOrg = orgList.find((org) => org.organizationId === organizationId);
          if(selectedOrg?.status === 'active'){
            if (!organizationIds.includes(organizationId)) {
              dispatch(setSelectedOrganization({
                organizationId: organizationId,
                serviceType: selectedOrg.serviceType
              }));
            }
          } else {
            if(isExistActiveOrg){
              dispatch(setSelectedOrganization({
                organizationId: isExistActiveOrg.organizationId,
                serviceType: isExistActiveOrg.serviceType
              }));
            } else {
              authLogout();
              dispatch(logout());
              dispatch(updateError({
                urlString: "/login",
                errors:[{
                mainMenu: 'ログイン',
                message: {
                  text: '全ての事業所アカウントが停止しています',
                  type: 'alert',
                  isInStateUser: true,
                }
              }]}));
              return;
            }
          }
        }
        dispatch(setMemberData({
          memberId: uid,
          roles: doc.data().roles,
          organizationIds: doc.data().organizationIds,
          email: doc.data().email,
          name: doc.data().name,
          createAtMillis: doc.data().createAt.toMillis(),
          updateAtMillis: doc.data().updateAt.toMillis(),
          createdBy: doc.data().createdBy,
          updatedBy: doc.data().updatedBy,
          isDeleted: doc.data().isDeleted
        }));
      }
    });
  }, [dispatch, organizationId]);

  // selectedOrganizationIdで動く
  useEffect(() => {
    if (selectedOrganizationId && logined) {
      observeMembers(selectedOrganizationId);
      observeRoles(selectedOrganizationId);
      observeSupportPlans(selectedOrganizationId, selectedServiceType);
      observeAssessments(selectedOrganizationId, selectedServiceType);
      observeGroups(selectedOrganizationId, selectedServiceType);
      observeMembers(selectedOrganizationId);
      observeUsers(selectedOrganizationId, selectedServiceType);
      observeOrganization(selectedOrganizationId, selectedServiceType);
      observeBusinessHours(selectedOrganizationId, selectedServiceType);
      observeWorkingHours(selectedOrganizationId, selectedServiceType);
      observeBasicReward(selectedOrganizationId, selectedServiceType);
      observeSubtractingRewardItem(selectedOrganizationId, selectedServiceType);
      observeAddingRewardItem(selectedOrganizationId, selectedServiceType);
      observeInvoices(selectedOrganizationId);
      observeWorkItems(selectedOrganizationId, selectedServiceType);
      observeWorkPlaceCompanies(selectedOrganizationId, selectedServiceType);
      observeInitialSetting(selectedOrganizationId, selectedServiceType);
      observeSupportPlanReviews(selectedOrganizationId, selectedServiceType);
      observeStripeInfo(selectedOrganizationId, selectedServiceType);
      observeTemporaryLink(selectedOrganizationId, selectedServiceType);
      observeUsageRecords(selectedOrganizationId, usageRecordYearAndMonth, selectedServiceType);
      observeInterviewRecords(selectedOrganizationId, userPeriod, selectedServiceType);
      observeWorkRecords(selectedOrganizationId, workRecordYearAndMonth, selectedServiceType);
      observeSupportRecords(selectedOrganizationId, supportRecordYearAndMonth, selectedServiceType);
      observeBilling(selectedOrganizationId, selectedServiceType);
      observeNewWorkingHours(selectedOrganizationId, selectedServiceType);
      getNews(selectedOrganizationId);
      dispatch(resetTopAlert());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[
    selectedOrganizationId,
    logined
  ]);



  useEffect(() => {
    if(selectedOrganizationId && memberData.memberId) {
      observeReadNews(selectedOrganizationId, memberData.memberId, selectedServiceType);
    }
  },[memberData.memberId, observeReadNews, selectedOrganizationId, selectedServiceType]);

  const loginTempUser = useCallback((organizationId: string, serviceType: ServiceType) => {
    observeUsers(organizationId, serviceType);
    observeUsageRecords(organizationId, dayjs().format("YYYY-MM"), serviceType);
    dispatch(resetMemberData());
  }, [observeUsers, observeUsageRecords, dispatch]);

  const observeAuth = useCallback(() => {
    auth.onAuthStateChanged((user) => {
      if (user && !user.email) {
        user.getIdTokenResult(true).then((idTokenResult) => {
          if (idTokenResult.claims.organizationId && idTokenResult.claims.temporaryLinkId && idTokenResult.claims.serviceType) {
            loginTempUser(idTokenResult.claims.organizationId, idTokenResult.claims.serviceType);
          }
        })
      } else if (user && user.email) {
        dispatch(login());
        observeMember(user.uid);
      } else {
        dispatch(logout());
        dispatch(resetTopAlert());
        dispatch(resetMemberData());
        dispatch(resetSelectedOrganization());
        //dispatch(setUid(''));
      }
      setTimeout(() => {
        dispatch(setRead());
      }, 500);
    });
  }, [dispatch, loginTempUser, observeMember]);

  useEffect(() => {
    if (organizationId && businessLogYearMonth) {
      observeBusinessLogs(organizationId, businessLogYearMonth, selectedServiceType)
    }
  }, [businessLogYearMonth, observeBusinessLogs, organizationId, selectedServiceType]);

  useEffect(() => {
    observeAuth();
  }, [observeAuth]);

  // 事業所情報バリデーション
  useEffect(() => {
    let orgError: StateError[] = [];
    let basicRewardError: StateError[] = [];
    let basicReward2024Error: StateError[] = [];
    let subtractingRewardItemError: StateError[] = [];
    let subtractingRewardItem2024Error: StateError[] = [];
    let addRewardItemError: StateError[] = [];
    let addRewardItem2024Error: StateError[] = [];
    if (selectedServiceType === "aType") {
      orgError = checkOrganization({ organization });
      basicRewardError = checkBasicReward({ basicReward: basicReward });
      basicReward2024Error = checkBasicReward2024({ basicReward: basicReward2024 });
      subtractingRewardItemError = checkSubtractingRewardItem({ subtractingRewardItem: subtractingRewardItem })
      subtractingRewardItem2024Error = checkSubtractingRewardItem2024({ subtractingRewardItem: subtractingRewardItem2024 })
      addRewardItemError =checkAddingRewardItem({ addingRewardItem: addingRewardItem });
      addRewardItem2024Error =checkAddingRewardItem2024({ addingRewardItem: addingRewardItem2024 });
      // topページへのalert表示
      dispatch(setTopAlert(checkTopAlert(organization, 'aType', basicReward2024, addingRewardItem2024, subtractingRewardItem2024 )))
    } else if (selectedServiceType === "bType") {
      orgError = checkTypeBOrganization({ organization: typeBOrganization });
      basicRewardError = checkTypeBBasicReward({ basicReward: typeBBasicReward });
      subtractingRewardItemError = checkTypeBSubtractingRewardItem({ subtractingRewardItem: typeBSubtractingRewardItem });
      subtractingRewardItem2024Error = checkTypeBSubtractingRewardItem2024({ subtractingRewardItem: typeBSubtractingRewardItem2024 });
      addRewardItemError = checkTypeBAddingRewardItem({ addingRewardItem: typeBAddingRewardItem })
      addRewardItem2024Error = checkTypeBAddingRewardItem2024({ addingRewardItem: typeBAddingRewardItem2024 })
      // topページへのalert表示
      dispatch(setTopAlert(checkTopAlert(typeBOrganization, 'bType', typeBBasicReward, typeBAddingRewardItem2024, typeBSubtractingRewardItem2024 )))
    } else if (selectedServiceType === "transfer") {
      orgError = checkTransferOrganization({ organization: transferOrganization });
      basicRewardError = checkTransferBasicReward({ basicReward: transferBasicReward });
      subtractingRewardItemError = checkTransferSubtractingRewardItem({ subtractingRewardItem: transferSubtractingRewardItem });
      addRewardItemError = checkTransferAddingRewardItem({ addingRewardItem: transferAddingRewardItem })
    }
    let bufError: StateError[] = []
    bufError = bufError.concat(
      orgError,
      basicRewardError,
      basicReward2024Error,
      subtractingRewardItemError,
      subtractingRewardItem2024Error,
      addRewardItemError,
      addRewardItem2024Error
    );
    dispatch(
      updateError({
        urlString: "organization?tab=",
        errors: bufError,
      })
    );
  }, [
    organization,
    basicReward,
    basicReward2024,
    subtractingRewardItem,
    subtractingRewardItem2024,
    addingRewardItem,
    addingRewardItem2024,
    dispatch,
    selectedServiceType,
    typeBOrganization,
    typeBBasicReward,
    typeBSubtractingRewardItem,
    typeBSubtractingRewardItem2024,
    typeBAddingRewardItem,
    typeBAddingRewardItem2024,
    transferOrganization,
    transferBasicReward,
    transferSubtractingRewardItem,
    transferAddingRewardItem,
  ]);

  // 支援計画アラート
  useEffect(() => {
    for (let index = 0; index < users.length; index++) {
      const user = users[index];
      const { userId, nameKanji } = user;
      // 支援期間対象外であれば除外
      if (isAfterHasReceivedServiceEndDate(users, userId, dayjs())) continue
      const filterdSupportPlans = supportPlans
      .filter((supportPlan)=> supportPlan.userId === userId && supportPlan.supportDate.endAtMillis)
      if (!filterdSupportPlans[0]) continue
      const sortedSupportPlans = sortSupportPlanSupportEndDateDesc(filterdSupportPlans)
      // supportDate.endAtMillisが一番遅いものをバリデーションチェック対象にする
      const message: Message | undefined = checkSupportPlanAlert({userName: nameKanji, supportPlan: sortedSupportPlans[0]});
      if (!message) continue
      dispatch(
        updateError({
          urlString: "supportPlans",
          errors: message ? [{ mainMenu: "利用者情報", message }] : [],
        })
      );
    }
  }, [users, dispatch, supportPlans, selectedServiceType]);

  // 利用者情報バリデーション
  // 利用者詳細情報バリデーション
  useEffect(() => {
    let bufError: StateError[] = [];
    users.map((data) => {
      const userId = data.userId;
      const userName = data.nameKanji;
      bufError = bufError.concat(
        checkStateUser({ userId: userId, userName: userName, user: data })
      );
    });
    const userName = getUserNameByUserId(userId, users);
    let usageError: StateError[] = [];
    if (selectedServiceType === "aType") {
      usageError = checkUsage({
        userId: userId,
        userName: userName,
        usages: usages,
      });
    }
    if (selectedServiceType === "bType") {
      usageError = checkTypeBUsage({
        userId: userId,
        userName: userName,
        usages: typeBUsages,
      });
    }
    if (selectedServiceType === "transfer") {
      usageError = checkUsage({
        userId: userId,
        userName: userName,
        usages: transferUsages,
      });
    }
    bufError = bufError.concat(
      checkProfile({ userId: userId, userName: userName, profile: profile }),
      usageError,
      checkBeneficiaryCertificate({
        userId: userId,
        userName: userName,
        beneficiaryCertificate: beneficiaryCertificate,
      })
    );
    let newBufError: StateError[] = [];
    bufError.forEach((doc, index) => {
      if(index === 0) newBufError.push(doc);
      const isExist = newBufError.find((error) => error.message.text === doc.message.text);
      if(!isExist) newBufError.push(doc);
    });
    dispatch(
      updateError({
        urlString: `users`,
        errors: newBufError,
      })
    );
  }, [
    userId,
    usages,
    profile,
    beneficiaryCertificate,
    dispatch,
    users,
    selectedServiceType,
    typeBUsages,
    transferUsages,
  ]);

  // 利用実績バリデーション
  useEffect(() => {
    if(users.length === 1 && !users[0].userId) return;
    let bufError: StateError[] = []
    users.map((data) => {
      const userId = data.userId;
      const userName = data.nameKanji;
      if (selectedServiceType==='aType') {
        const targetUsageRecords = usageRecords.filter(usageRecord => usageRecord.userId===userId);
        targetUsageRecords.map(usageRecord => {
          bufError = bufError.concat(
            checkUsageRecord({userId:userId, userName:userName, usageRecord: usageRecord})
          )
        })
        bufError = bufError.concat(
          checkUsageRecords({userId:userId, userName:userName, usageRecords: targetUsageRecords})
        )
      } else if (selectedServiceType==='bType') {
        const targetUsageRecords = usageRecords.filter(usageRecord => usageRecord.userId===userId);
        targetUsageRecords.map(usageRecord => {
          bufError = bufError.concat(
            checkTypeBUsageRecord({userId:userId, userName:userName, usageRecord: usageRecord})
          )
        })
        bufError = bufError.concat(
          checkTypeBUsageRecords({userId:userId, userName:userName, usageRecords: targetUsageRecords})
        )
      } else if (selectedServiceType==='transfer') {
        const targetUsageRecords = transferUsageRecords.filter(usageRecord => usageRecord.userId===userId);
        targetUsageRecords.map(usageRecord => {
          bufError = bufError.concat(
            checkTransferUsageRecord({userId:userId, userName:userName, usageRecord: usageRecord})
          )
        })
        bufError = bufError.concat(
          checkTransferUsageRecords({userId:userId, userName:userName, usageRecords: targetUsageRecords})
        )
      }
    });
    dispatch(updateError({
      urlString: "usageRecords",
      errors: bufError
    }));
  }, [usageRecords, transferUsageRecords, dispatch, users, selectedServiceType])

  // 支援記録バリデーション
  useEffect(() => {
    let bufError: StateError[] = []
    users.map((data) => {
      if (data.serviceType !== selectedServiceType) return;
      const userId = data.userId;
      const userName = data.nameKanji;
      const targetSupportPlans = supportPlans.filter(supportPlan => supportPlan.userId===userId);
      targetSupportPlans.map(supportPlan => {
        bufError = bufError.concat(
          checkSupportPlan({userId:userId, userName:userName, supportPlan:supportPlan})
        );
      });
    });
    dispatch(updateError({
      urlString: 'supportPlans',
      errors: bufError
    }));
  }, [supportPlans, dispatch, selectedServiceType])

  useEffect(() => {
    const errors: StateError[] = [];
    for (const supportRecord of supportRecords) {
      const user = users.find((user) => user.userId === supportRecord.userId);
      if (!user) continue;
      const userName = user ? user.nameKanji : "";
      const error = checkSupportRecord(supportRecord, userName);
      error.map((e) => {
        errors.push({
          mainMenu: "記録",
          subMenu: "支援記録",
          message: e,
        });
      })
    }
    dispatch(
      updateError({
        urlString: "supportRecords",
        errors,
      })
    );
  }, [supportRecords, dispatch, selectedServiceType, users])


  // カード情報バリデーション
  useEffect(() => {
    let bufError: StateError[] = []
    bufError = bufError.concat(
      checkStripeInfo({stripeInfo:stripeInfo}),
    )
    dispatch(updateError({
      urlString: 'register-card',
      errors: bufError
    }));
  }, [stripeInfo, dispatch, selectedServiceType]);

  // userIdの変更をトリガーにユーザ情報の詳細を取得
  useEffect(() => {
    if (userId) {
      dispatchFirestoreUsagesSetting(userId, selectedServiceType);
      dispatchFirestoreProfileSetting(userId);
      dispatchFirestoreBeneficiaryCertificateSetting(userId);
    } else {
      dispatch(resetUsages());
      dispatch(resetProfile());
      dispatch(resetBeneficiaryCertificate());
    }
  }, [userId, dispatch, dispatchFirestoreUsagesSetting, dispatchFirestoreProfileSetting, dispatchFirestoreBeneficiaryCertificateSetting, selectedOrganizationId, selectedServiceType]);

  useEffect(() => {
    if (organizationId && userPeriod) {
      observeInterviewRecords(organizationId, userPeriod, selectedServiceType);
    }
  }, [observeInterviewRecords, organizationId, selectedServiceType, userPeriod]);

  useEffect(() => {
    if (organizationId && workRecordYearAndMonth) {
      observeWorkRecords(organizationId, workRecordYearAndMonth, selectedServiceType);
    }
  }, [observeWorkRecords, organizationId, selectedServiceType, workRecordYearAndMonth]);

  useEffect(() => {
    if (organizationId && usageRecordYearAndMonth && dayjs(usageRecordYearAndMonth).isValid()) {
      observeUsageRecords(organizationId, usageRecordYearAndMonth, selectedServiceType);
     }
  }, [observeUsageRecords, organizationId, selectedServiceType, usageRecordYearAndMonth]);

  useEffect(() => {
    if (organizationId && supportRecordYearAndMonth) {
      observeSupportRecords(organizationId, supportRecordYearAndMonth, selectedServiceType);
    }
  }, [observeSupportRecords, organizationId, selectedServiceType, supportRecordYearAndMonth]);

  return null;
}
