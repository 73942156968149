import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Message = {
  text: string;
  url?: string;
  /**
   * error: 請求書発行に影響あり（対象ページ下部に表示）
   * alert: 請求書に関係ないが有効期限切れなど業務に影響あり（画面上部にアラートとして表示）
   * */
  type: "error" | "alert";
  isInStateUser?: boolean; // profile, usage, beneficiaryCertificateのエラーとuserのエラーが重複するのを防ぐため。userに含まれるエラーはcheckUser時に削除
  isFatal?: boolean; // trueの時は全てのページのSnackbarに表示
};

export type StateError = {
  mainMenu: '実績'|'業務日誌'|'記録'|'利用者情報'|'事業所設定'|'帳票出力'|'ヘルプ'|'打刻URL' | 'ログイン';
  subMenu?: '支援記録'|'作業記録'|'事業所基本情報'|'就労先基本情報'|'作業情報'|'職員情報'|'カード登録'|'初期設定'|
    '請求書類'|'各種記録'|'施設外就労実施報告書'|'各種記録'|'施設外就労実施報告書'|'操作マニュアル'|'お問い合わせ';
  pageId?: string;
  message: Message;
};

export const initialState: StateError[] = [];

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (_state: StateError[], action: PayloadAction<StateError[]>) => {
      return action.payload;
    },
    updateError: (state: StateError[], action: PayloadAction<{
      urlString: string
      errors: StateError[],
      isInStateUser?: boolean
    }>) => {
      // actionで送られてきた物とは別タイプのエラー
      const existsError = state.filter(
        (e) => !e.message?.url?.includes(action.payload.urlString) && (action.payload.isInStateUser ? e.message.isInStateUser : true)
      );
      return [...existsError, ...action.payload.errors];
    },
    resetError: () => {
      return initialState;
    },
    appendError: (state: StateError[], action: PayloadAction<StateError>) => {
      return [...state, action.payload];
    }
  }
});

export const { setError, resetError, appendError, updateError } =
  errorSlice.actions;
export const errorReducer = errorSlice.reducer;
