import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkItem } from 'common-types/firebase/firestore/organizations/workItems';

export type StateWorkItem = Omit<WorkItem, 'createAt' | 'updateAt'> & {createAtMillis: number |  null, updateAtMillis: number | null, workItemId: string}
export const initialState: StateWorkItem[] = [{
  workItemCategory: '',
  workItemName: '',
  workItemId: '',
  createAtMillis: null,
  updateAtMillis: null,
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
}]
const workItemsSlice = createSlice({
  name: 'workitems',
  initialState,
  reducers: {
    setWorkItems: (_state: StateWorkItem[], action: PayloadAction<StateWorkItem[]>) => {
      // 並び順を作成日時の昇順にして扱う
      return action.payload.sort((a, b) => {
        if (a.createAtMillis === null && b.createAtMillis === null) {
          return 0;
        }
        if (a.createAtMillis === null) {
          return -1;
        }
        if (b.createAtMillis === null) {
          return 1;
        }
        return a.createAtMillis - b.createAtMillis;
      });
    },
    resetWorkItems: () => {
      return initialState;
    }
  }
});

export const { setWorkItems, resetWorkItems } = workItemsSlice.actions;
export const workItemsReducer = workItemsSlice.reducer;
