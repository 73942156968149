import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ServiceType } from 'common-types/firebase/firestore/users';

// TODO: createdByを入れる
const initialState: {organizationId: string, serviceType: ServiceType} = {
  organizationId: '',
  serviceType: 'aType'
};
const selectedOrganizationSlice = createSlice({
  name: 'selectedOrganization',
  initialState,
  reducers: {
    setSelectedOrganization: (_state:  {organizationId: string, serviceType: ServiceType}, action: PayloadAction<{organizationId: string, serviceType: ServiceType}>) => {
      return action.payload;
    },
    resetSelectedOrganization: () => {
      return initialState;
    }
  }
});

export const { setSelectedOrganization, resetSelectedOrganization } = selectedOrganizationSlice.actions;
export const selectedOrganizationReducer = selectedOrganizationSlice.reducer;
