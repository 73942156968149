import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialSetting } from "common-types/firebase/firestore/organizations/settings";

export type StateInitialSetting = Omit<
InitialSetting,
  "createAt" | "updateAt" | "initialClaimWork"
> & {
  initialClaimWork: { startAtMillis: number };
  createAtMillis: number;
  updateAtMillis: number;
};
export const initialState: StateInitialSetting = {
  initialClaimWork: {
    startAtMillis: 0,
  },
  numberOfUsage: {
    oneMonthAgo: 0,
    twoMonthAgo: 0,
    threeMonthAgo: 0,
  },
  openDays: {
    oneMonthAgo: 0,
    twoMonthAgo: 0,
    threeMonthAgo: 0,
  },
  numberOfSupportForTraining: [],
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: "",
  updatedBy: "",
};

const initialSettingSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setInitialSetting: (
      _state: StateInitialSetting,
      action: PayloadAction<StateInitialSetting>
    ) => {
      return action.payload;
    },
    resetInitialSetting: () => {
      return initialState;
    },
  },
});

export const { setInitialSetting, resetInitialSetting } = initialSettingSlice.actions;
export const initialSettingReducer = initialSettingSlice.reducer;
