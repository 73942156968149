import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from '../lib/dayjs';

export type ArbitraryPeriod = {
  startDate: string,
  endDate: string,
  isArbitraryPeriod: boolean,
};

export const initialStartDate = dayjs(dayjs().format('YYYYMM'),'YYYYMM').format('YYYYMMDD') as string;
export const initialEndDate = dayjs(dayjs().format('YYYYMM'),'YYYYMM').endOf('month').format('YYYYMMDD') as string;

const userPeriodSlice = createSlice({
  name: 'userPeriod',
  initialState: {
    startDate: initialStartDate,
    endDate: initialEndDate,
    isArbitraryPeriod: false,
  },
  reducers: {
    setUserPeriod: (_state: ArbitraryPeriod, action: PayloadAction<ArbitraryPeriod>) => {
      return action.payload;
    },
    resetUserPeriod: () => {
      return {
        startDate: initialStartDate,
        endDate: initialEndDate,
        isArbitraryPeriod: false,
      };
    }
  }
});

export const { setUserPeriod, resetUserPeriod } = userPeriodSlice.actions;
export const userPeriodReducer = userPeriodSlice.reducer;
