import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const temporaryLinkSlice = createSlice({
  name: 'temporaryLink',
  initialState: [''],
  reducers: {
    setTemporaryLink: (_state: string[], action: PayloadAction<string[]>) => {
      return action.payload;
    },
    resetTemporaryLink: () => {
      return [];
    }
  }
});

export const { setTemporaryLink, resetTemporaryLink } = temporaryLinkSlice.actions;
export const temporaryLinkReducer = temporaryLinkSlice.reducer;