import { StateError, Message } from "../redux/error";
import { StateTransferUsages } from "../redux/transferUsages";
import { StateTypeBUsages } from "../redux/typeBUsages";
import { StateUsages } from "../redux/usages";
import { checkExpireDate } from "./checkExpireDate";

type PropsUsages = {
  userId: string,
  userName: string,
  usages: StateUsages | StateTransferUsages | undefined,
}

type PropsTypeBUsages = {
  userId: string,
  userName: string,
  usages: StateTypeBUsages | undefined,
}

export const checkUsage = (props: PropsUsages) => {
  let messages: Message[] = [];
  const { userId, userName, usages } = props;

  if(!usages) {
    messages.push({
      text:`${userName}さんの初回設定が完了していません。サービス利用詳細画面と受給者証詳細設定画面で保存するを押して初回設定を完了してください`,
      url: `users/${userId}/basicInfo?tab=service`,
      type: 'error',
    })
    const error = messages.map(m => { return {mainMenu:'利用者情報', message: m} as StateError });
    return error;
  }
  if(!usages.createAtMillis) { // 初回利用者登録時
    return [] as StateError[];
  }
  messages = messages.concat(checkTypeAUsagesItems(userId, userName, usages));
  const error = messages.map(m => { return {mainMenu:'利用者情報', message: m} as StateError });
  return error;
}

export const checkTypeBUsage = (props: PropsTypeBUsages) => {
  let messages: Message[] = [];
  const { userId, userName, usages } = props;

  if(!usages) {
    messages.push({
      text: `${userName}さんのサービス利用詳細情報が登録されていません`,
      url: `users/${userId}/basicInfo?tab=service`,
      type: 'error',
    })
    const error = messages.map(m => { return {mainMenu:'利用者情報', message: m} as StateError });
    return error;
  }
  if(!usages.createAtMillis) { // 初回利用者登録時
    return [] as StateError[];
  }
  messages = messages.concat(checkTypeBUsagesItems(userId, userName, usages));
  const error = messages.map(m => { return {mainMenu:'利用者情報', message: m} as StateError });
  return error;
}

const checkTypeAUsagesItems = (
  userId: string,
  userName: string,
  usages: StateUsages
) => {
  let messages = checkCommonUsagesItems(userId, userName, usages);
  return messages;
}

const checkTypeBUsagesItems = (
  userId: string,
  userName: string,
  usages: StateTypeBUsages
) => {
  let messages = checkCommonUsagesItems(userId, userName, usages);
  return messages;
}

const checkCommonUsagesItems = (
  userId: string,
  userName: string,
  usages: StateUsages | StateTypeBUsages
) => {
  const messages: Message[] = [];
  const hasReceivedServiceExpireDate = checkExpireDate(usages.hasReceivedService.endAtMillis)
  const hasReceivedCertificationExpireDate = checkExpireDate(usages.hasReceivedCertification.endAtMillis)

  if (!usages.hasReceivedService.startAtMillis) {
    messages.push({
      text: `${userName}さんのサービス利用詳細情報：サービス提供期間支給決定開始日が登録されていません`,
      url: `uusers/${userId}/basicInfo?tab=service`,
      type: 'error',
      isInStateUser: true,
    })
  };
  if (hasReceivedServiceExpireDate) {
    messages.push({
      text: `${userName}さんのサービス提供期間が${hasReceivedServiceExpireDate + 1}日後に切れます`,
      url: `users/${userId}/basicInfo?tab=service`,
      type: 'alert',
      isInStateUser: true,
    })
  };
  if (!usages.hasReceivedCertification.startAtMillis) {
    messages.push({
      text: `${userName}さんのサービス利用詳細情報：受給者証支給決定開始日が登録されていません`,
      url: `users/${userId}/basicInfo?tab=service`,
      type: 'error',
      isInStateUser: true,
    })
  };
  if (!usages.hasReceivedCertification.endAtMillis) {
    messages.push({
      text: `${userName}さんのサービス利用詳細情報：受給者証支給決定終了日が登録されていません`,
      url: `users/${userId}/basicInfo?tab=service`,
      type: 'error',
      isInStateUser: true,
    })
  };
  if (hasReceivedCertificationExpireDate) {
    messages.push({
      text: `${userName}さんの受給者証の支給決定期間が${
        hasReceivedCertificationExpireDate + 1
      }日後に切れます`,
      url: `users/${userId}/basicInfo?tab=service`,
      type: 'alert',
      isInStateUser: true,
    });
  };
  if (usages.managementOrganization.hasUpperLimitManagementOrganization) {
    if (!usages.managementOrganization.hasManagementAuthentication && (
       !usages.managementOrganization.otherOrganizationsInformation ||
       !usages.managementOrganization.otherOrganizationsInformation[0].organizationId
    )) {
      messages.push({
        text: `${userName}さんのサービス利用詳細情報：上限管理事業所が他事業所に設定されていますが事業所番号または事業所名が設定されていません`,
        url: `users/${userId}/basicInfo?tab=service`,
        type: 'error',
      })
    } else if (usages.managementOrganization.hasManagementAuthentication) {
      if (
        !usages.managementOrganization.otherOrganizationsInformation ||
        !usages.managementOrganization.otherOrganizationsInformation.every((d)=> d.organizationId && d.organizationName)
      ) {
        messages.push({
          text: `${userName}さんのサービス利用詳細情報：上限管理事業所が自事業所に設定されていますが他事業所の事業所番号または事業所名が設定されていません`,
          url: `users/${userId}/basicInfo?tab=service`,
          type: 'error',
        })
      };
      if (
        usages.managementOrganization.managementResult===2 &&
        !usages.managementOrganization.otherOrganizationsInformation.every((d)=> !(Number.isNaN(d.totalAmount) || Number.isNaN(d.userBurden) || Number.isNaN(d.managedUserBurden)))
      ) {
        messages.push({
          text: `${userName}さんのサービス利用詳細情報：他事業所の総利用額と利用者負担額と管理結果後利用者負担額を入力してください`,
          url: `users/${userId}/basicInfo?tab=service`,
          type: 'error',
        })
      } else if (
        !usages.managementOrganization.otherOrganizationsInformation.every((d)=> !(Number.isNaN(d.totalAmount) || Number.isNaN(d.userBurden)))
      ) {
        messages.push({
          text: `${userName}さんのサービス利用詳細情報：他事業所の総利用額と利用者負担額を入力してください`,
          url: `users/${userId}/basicInfo?tab=service`,
          type: 'error',
        })
      }
    }
  }
  if (usages.supportPlanStatus.isNotCreated && !usages.supportPlanStatus.startAtMillis) {
    messages.push({
      text: `${userName}さんのサービス利用詳細情報：個別支援計画書が未作成ですが未作成期間開始日が設定されていません`,
      url: `users/${userId}/basicInfo?tab=service`,
      type: 'error',
    })
  };
  if (usages.supportForEmploymentTransition.isAdded && !usages.supportForEmploymentTransition.startAtMillis) {
    messages.push({
      text: `${userName}さんのサービス利用詳細情報：就労移行連携加算が設定されていますが実施日が設定されていません`,
      url: `users/${userId}/basicInfo?tab=service`,
      type: 'error',
    })
  };
  if (!usages.contractPaymentAmount.isDefault && !(
    usages.contractPaymentAmount.numberOfDate &&
    usages.contractPaymentAmount.businessEntryNumber
  )) {
    messages.push({
      text: `${userName}さんのサービス利用詳細情報：契約支給量の日数または事業者記入欄番号が設定されていません`,
      url: `users/${userId}/basicInfo?tab=service`,
      type: 'error',
    })
  };
  if (usages.municipalSubsidy.isTarget && !(usages.municipalSubsidy.unit && usages.municipalSubsidy.cityCode)) {
    messages.push({
      text: `${userName}さんのサービス利用詳細情報：自治体助成金対象に設定されていますが助成金額と市区町村番号が設定されていません`,
      url: `users/${userId}/basicInfo?tab=service`,
      type: 'error',
    })
  };

  return messages;
}
