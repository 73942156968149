import { firestore, DocumentReference } from '../../../index';
import { readNewsConverter } from '../../../../redux/readNews';
import firebase from 'firebase';
import { ServiceType } from 'common-types/firebase/firestore/organizations';
import { getSelectedOrganization } from '../../../../utils/getSelectedOrganization';

export const setFirestoreReadNews = async (
  organizationId: string,
  newsId: string,
  memberId: string,
  selectedServiceType: ServiceType
) => {
  const organization = getSelectedOrganization(selectedServiceType);
  const readNewsRef: DocumentReference = await firestore
    .collection(organization)
    .doc(organizationId)
    .collection('readNews')
    .withConverter(readNewsConverter)
    .doc();
  return await readNewsRef.set({
    newsId: newsId,
    createdBy: memberId,
    updatedBy: memberId,
    createAt: firebase.firestore.Timestamp.now(),
    updateAt: firebase.firestore.Timestamp.now(),
  }, { merge: true });
};