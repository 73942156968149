import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from '../lib/dayjs';

const supportRecordDateSlice = createSlice({
  name: 'supportRecordDate',
  initialState: dayjs().format('YYYY-MM-DD'),
  reducers: {
    setSupportRecordDateSlice: (_state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
    resetSupportRecordDateSlice: () => {
      return '';
    }
  }
});

export const { setSupportRecordDateSlice, resetSupportRecordDateSlice } = supportRecordDateSlice.actions;
export const supportRecordDateReducer = supportRecordDateSlice.reducer;
