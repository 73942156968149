import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NewWorkingHours } from 'common-types/firebase/firestore/organizations/settings';

export type StateNewWorkingHour = Omit<
  NewWorkingHours,
  | "createAt"
  | "updateAt"
  | "isSetSundaySetting"
  | "isSetMondaySetting"
  | "isSetTuesdaySetting"
  | "isSetWednesdaySetting"
  | "isSetThursdaySetting"
  | "isSetFridaySetting"
  | "isSetSaturdaySetting"
> & {
  createAtMillis: number;
  updateAtMillis: number;
  id?: string;
  isSetSundaySetting: boolean;
  isSetMondaySetting: boolean;
  isSetTuesdaySetting: boolean;
  isSetWednesdaySetting: boolean;
  isSetThursdaySetting: boolean;
  isSetFridaySetting: boolean;
  isSetSaturdaySetting: boolean;
};

export const initialState: StateNewWorkingHour[] = [
  {
  id: undefined,
  unitTime: 0,
  workTime: {
    startTime: '00:00',
    endTime: '00:00'
  },
  breakTimes: [],
  hasDefaultSettingForWorkAndBreakEachDay: false,
  sunday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  monday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  tuesday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  wednesday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  thursday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  friday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  saturday: {
    breakTimes: [],
    startTime: '00:00',
    endTime: '00:00'
  },
  userIds: [],
  groupIds: [],
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: '',
  isSetSundaySetting: false,
  isSetMondaySetting: false,
  isSetTuesdaySetting: false,
  isSetWednesdaySetting: false,
  isSetThursdaySetting: false,
  isSetFridaySetting: false,
  isSetSaturdaySetting: false,
  }
];

const workingHoursSlice = createSlice({
  name: 'newWorkingHours',
  initialState,
  reducers: {
    setNewWorkingHours: (_state: StateNewWorkingHour[], action: PayloadAction<StateNewWorkingHour[]>) => {
      return action.payload;
    },
    resetNewWorkingHours: () => {
      return initialState;
    }
  }
});

export const { setNewWorkingHours, resetNewWorkingHours} = workingHoursSlice.actions;
export const newWorkingHoursReducer = workingHoursSlice.reducer;
