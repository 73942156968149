import { firestore, DocumentReference } from '../../index';
import { TypeBOrganization } from 'common-types/firebase/firestore/typeBOrganization';
import { StateTypeBOrganization } from '../../../redux/typeBOrganization';
import firebase from 'firebase/app';

export const setFirestoreTypeBOrganization= async (
  typeBOrganizationId:string,
  doc: StateTypeBOrganization,
) => {
  const organization = setFirebaseDoc(doc);
  const organizationRef: DocumentReference = await firestore
    .collection('typeBOrganizations')
    .doc(typeBOrganizationId);
  return await organizationRef.set(organization, {merge: true});
};

const setFirebaseDoc = (doc: StateTypeBOrganization): TypeBOrganization => {
  const { createAtMillis, updateAtMillis, totalUserCapacity, ...params } = doc;
  return {
    ...params,
    totalUserCapacity: Number(totalUserCapacity),
    createAt: firebase.firestore.Timestamp.fromMillis(createAtMillis),
    updateAt: firebase.firestore.Timestamp.fromMillis(updateAtMillis),
  }
}

export const getFirestoreTypeBOrganization = async(typeBOrganizationId: string) => {
  const organizationRef: DocumentReference =  firestore
    .collection('typeBOrganizations')
    .doc(typeBOrganizationId);
  return organizationRef.get()
}