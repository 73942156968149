import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransferOrganization } from 'common-types/firebase/firestore/transferOrganization';

export type StateTransferOrganization = Omit<TransferOrganization, 'createAt' | 'updateAt'> &
{
  createAtMillis: number,
  updateAtMillis: number
};
const initialState: StateTransferOrganization = {
  companyName: '',
  organizationCode: '',
  organizationName: '',
  serviceType: '',
  userCapacity: 0,
  representativeId: '',
  isGroupOrganization: false,
  isMaster: true,
  isMultifunctionalOrganization: false,
  totalUserCapacity: 0,
  postalCode: '',
  prefecture: '',
  city: '',
  areaCategory: '',
  areaCategory2024: '',
  cityCode: '',
  town: '',
  address: '',
  phoneNumber: '',
  hasFoodService: false,
  pickUpService: {
    hasPickUpService: false,
    serviceType: '',
  },
  status: 'active',
  startPaymentDate: '',
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: '',
};

const transferOrganizationSlice = createSlice({
  name: 'transferOrganization',
  initialState,
  reducers: {
    setTransferOrganization: (_state: StateTransferOrganization, action: PayloadAction<StateTransferOrganization>) => {
      return action.payload;
    },
    resetTransferOrganization: () => {
      return initialState;
    }
  }
});

export const { setTransferOrganization, resetTransferOrganization } = transferOrganizationSlice.actions;
export const transferOrganizationReducer = transferOrganizationSlice.reducer;