import dayjs from "../lib/dayjs";

export const checkExpireDate = (dayAtMillis: number | null) => {
if(!dayAtMillis) {
    return null
};
const today = dayjs();
const expireDate = dayjs(dayAtMillis);
const daysTillExpire = expireDate.diff(today, 'day');
if(daysTillExpire <= 29 && 0 < daysTillExpire) {
    return daysTillExpire;
} else {
    return null;
}
}

export const isWithInOneMonth = (dayAtMillis: number | null) => {
    if(!dayAtMillis) {
        return null
    };
    const today = dayjs();
    const expireDate = dayjs(dayAtMillis);
    const daysTillExpire = expireDate.diff(today, 'day');

    if(daysTillExpire <= 29) {
        return daysTillExpire;
    } else {
        return null;
    }
    }
