import { StateOrganization } from "../redux/organization";
import { StateTransferOrganization } from "../redux/transferOrganization";
import { StateTypeBOrganization } from "../redux/typeBOrganization";
import { ServiceType } from "common-types/firebase/firestore/organizations";
import { StateBasicReward2024 } from "../redux/basicReward2024";
import { StateTypeBAddingRewardItem2024 } from "../redux/typeBAddingRewardItem2024";
import { StateTypeBSubtractingRewardItem2024 } from "../redux/typeBSubtractingRewardItem2024";
import { StateAddingRewardItem2024 } from "../redux/addingRewardItem2024";
import { StateSubtractingRewardItem2024 } from "../redux/subtractingRewardItem2024";
import { StateTypeBBasicReward } from "../redux/typeBBasicReward";

type TopAlert = {
  message: string;
  type: 'dailybasicInformation' | 'rewards';
  path: string
}

export const checkTopAlert = (
  organization: StateOrganization | StateTypeBOrganization | StateTransferOrganization,
  serviceType: ServiceType,
  basicReward: StateBasicReward2024 | StateTypeBBasicReward, 
  addingReward: StateAddingRewardItem2024 | StateTypeBAddingRewardItem2024, 
  subtractingReward:  StateSubtractingRewardItem2024 | StateTypeBSubtractingRewardItem2024
  ) => {
  let messages: TopAlert[] = [];
  if(organization.organizationName && [undefined, ''].includes(organization.areaCategory2024)){
  messages.push({
    message: '地域区分が改定されました。基本情報より新しい地域区分を入力して保存してください',
    type: 'dailybasicInformation',
    path: '/organization?tab=dailybasicInformation'
  })
}
if(serviceType === 'aType'){
  if(!(basicReward.createAtMillis !== 0 && addingReward.createAtMillis !==0 && subtractingReward.createAtMillis !==0)){
    messages.push({
      message: '2024年度の報酬改定が実施されました。報酬情報を入力して保存してください',
      type: 'rewards',
      path: '/organization?tab=rewards'
    })
  }
} else if(serviceType === 'bType'){
  if(!(addingReward.createAtMillis !==0 && subtractingReward.createAtMillis !==0)){
    messages.push({
      message: '2024年度の報酬改定が実施されました。報酬情報を入力して保存してください',
      type: 'rewards',
      path: '/organization?tab=rewards'
    })
  }
} 
return messages;
}