import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from '../lib/dayjs';

const workRecordDateSlice = createSlice({
  name: 'workRecordDate',
  initialState: dayjs().format('YYYY-MM-DD'),
  reducers: {
    setWorkRecordDateSlice: (_state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
    resetWorkRecordDateSlice: () => {
      return '';
    }
  }
});

export const { setWorkRecordDateSlice, resetWorkRecordDateSlice } = workRecordDateSlice.actions;
export const workRecordDateReducer = workRecordDateSlice.reducer;
