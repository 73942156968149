import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from '../lib/dayjs';

const usageRecordDateSlice = createSlice({
  name: 'usageRecordDate',
  initialState: dayjs().format('YYYY-MM-DD'),
  reducers: {
    setUsageRecordDateSlice: (_state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
    resetUsageRecordDateSlice: () => {
      return '';
    }
  }
});

export const { setUsageRecordDateSlice, resetUsageRecordDateSlice } = usageRecordDateSlice.actions;
export const usageRecordDateReducer = usageRecordDateSlice.reducer;
