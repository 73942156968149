import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportRecord } from 'common-types/firebase/firestore/organizations/supportRecords';

export type StateSupportRecord = Omit<SupportRecord, 'attendanceRecord' | 'createAt' | 'updateAt' | 'interviewRecord'> &
{
  attendanceRecord: {startAtMillis: number, endAtMillis: number | null},
  createAtMillis: number,
  updateAtMillis: number | null,
  supportRecordId: string,
  interviewRecord: {startAtMillis: number | null, endAtMillis: number | null},
};
const initialState: StateSupportRecord[] = [{
  userId: '',
  usageRecordId: '',
  attendanceRecord: {
    startAtMillis: 0,
    endAtMillis: null
  },
  serviceProvisionStatus: '',
  workPlaceCompanyId: '',
  userStatus: '',
  workIds: [],
  absenceReason: '',
  support: '',
  staffReview: '',
  interview: false,
  interviewRecord: {
    startAtMillis: null,
    endAtMillis: null
  },
  interviewContent: '',
  other: '',
  recorderName: '',
  recorderId: '',
  createAtMillis: 0,
  updateAtMillis: null,
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
  supportRecordId: '',
  supportMemberId: null
}];

const supportRecordsSlice = createSlice({
  name: 'supportRecords',
  initialState,
  reducers: {
    setSupportRecords: (_state: StateSupportRecord[], action: PayloadAction<StateSupportRecord[]>) => {
      return action.payload;
    },
    resetSupportRecords: () => {
      return initialState;
    },
    updateSupportRecords: (state: StateSupportRecord[], action: PayloadAction<StateSupportRecord[]>) => {
      // すでにaction.payloadのusageRecordIdがstateに存在する場合は、stateを更新する
      // 存在しない場合は追加する
      const newState = state.map((supportRecord) => {
        const targetSupportRecord = action.payload.find(
          (usageRecord) => usageRecord.supportRecordId === supportRecord.supportRecordId
        );
        if (targetSupportRecord) {
          return targetSupportRecord;
        }
        return supportRecord;
      });
      action.payload.forEach((supportRecord) => {
        if (!state.find((stateSupportRecord) => stateSupportRecord.supportRecordId === supportRecord.supportRecordId)) {
          newState.push(supportRecord);
        }
      });
      return newState;
    },
    deleteSupportRecord: (state: StateSupportRecord[], action: PayloadAction<string>) => {
      return state.filter((supportRecord) => supportRecord.supportRecordId !== action.payload);
    },
  }
});

export const { setSupportRecords, resetSupportRecords, updateSupportRecords, deleteSupportRecord } = supportRecordsSlice.actions;
export const supportRecordsReducer = supportRecordsSlice.reducer;
