import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeBBasicReward } from 'common-types/firebase/firestore/typeBOrganization/settings';

export type StateTypeBBasicReward = Omit<TypeBBasicReward, 'createAt' | 'updateAt'> & {
  createAtMillis: number, updateAtMillis: number
};
const initialState: StateTypeBBasicReward = {
  rewardSystem: 'monthlyAverageWage',
  monthlyAverageWage: 7,
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const typeBBasicRewardSlice = createSlice({
  name: 'typeBBasicReward',
  initialState,
  reducers: {
    setTypeBBasicReward: (_state: StateTypeBBasicReward, action: PayloadAction<StateTypeBBasicReward>) => {
      return action.payload;
    },
    resetTypeBBasicReward: () => {
      return initialState;
    }
  }
});

export const { setTypeBBasicReward, resetTypeBBasicReward } = typeBBasicRewardSlice.actions;
export const typeBBasicRewardReducer = typeBBasicRewardSlice.reducer;
