import { StateError, Message } from "../redux/error";
import { StateOrganization } from "../redux/organization";
import { StateTransferOrganization } from "../redux/transferOrganization";
import { StateTypeBOrganization } from "../redux/typeBOrganization";

type PropsOrganization = {
  organization: StateOrganization | undefined,
}
type PropsTypeBOrganization = {
  organization: StateTypeBOrganization | undefined,
}

type PropsTransferOrganization = {
  organization: StateTransferOrganization | undefined,
}

export const checkOrganization = (props: PropsOrganization) => {
  let messages: Message[] = [];
  const { organization } = props;

  if(!organization) {
    messages.push({
      text:`事業所基本情報が登録されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    });
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  } 
  messages = messages.concat(checkTypeAOrganizationItems(organization));
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error;
}

export const checkTypeBOrganization = (props: PropsTypeBOrganization) => {
  let messages: Message[] = [];
  const { organization } = props;

  if(!organization) {
    messages.push({
      text:`事業所基本情報が登録されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    });
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  } 
  messages = messages.concat(checkTypeBOrganizationItems(organization));
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error;
}

export const checkTransferOrganization = (props: PropsTransferOrganization) => {
  let messages: Message[] = [];
  const { organization } = props;

  if(!organization) {
    messages.push({
      text:`事業所基本情報が登録されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    });
    const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
    return error;
  } 
  messages = messages.concat(checkTransferOrganizationItems(organization));
  const error = messages.map(m => { return {mainMenu:'事業所設定', subMenu:'事業所基本情報', message: m} as StateError });
  return error;
}

const checkTypeAOrganizationItems = (organization: StateOrganization) => {
  let messages = checkCommonOrganizationItems(organization);
  if (organization.aTypeExemption.hasATypeExemption && !organization.aTypeExemption.unit) { 
    messages.push({
      text:`事業所基本情報：A型事業者負担減免措置実施が選択されていますが減免額が正しく保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  return messages;
}

const checkTypeBOrganizationItems = (organization: StateTypeBOrganization) => {
  let messages = checkCommonOrganizationItems(organization);
  return messages;
}

const checkTransferOrganizationItems = (organization: StateTransferOrganization) => {
  let messages = checkCommonOrganizationItems(organization);
  return messages;
}

const checkCommonOrganizationItems = (organization: StateOrganization | StateTypeBOrganization) => {
  const messages: Message[] = [];
  
  if (!organization.organizationName) { 
    messages.push({
      text:`事業所基本情報：事業所名が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  if (!organization.organizationCode) { 
    messages.push({
      text:`事業所基本情報：事業所番号が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  if (!organization.companyName) { 
    messages.push({
      text:`事業所基本情報：法人名が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  if (!organization.postalCode) { 
    messages.push({
      text:`事業所基本情報：郵便番号が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  if (!organization.prefecture) { 
    messages.push({
      text:`事業所基本情報：都道府県が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  if (!organization.city) { 
    messages.push({
      text:`事業所基本情報：市区町村が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  if (!organization.address) { 
    messages.push({
      text:`事業所基本情報：番地以降の住所が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  if (!organization.phoneNumber) { 
    messages.push({
      text:`事業所基本情報：電話番号が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  if (!organization.representativeId) { 
    messages.push({
      text:`事業所基本情報：代表者職員氏名が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  if (!organization.cityCode) { 
    messages.push({
      text:`事業所基本情報：市区町村番号が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  if (!organization.areaCategory) { 
    messages.push({
      text:`事業所基本情報：地域区分が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  if (!organization.userCapacity) { 
    messages.push({
      text:`事業所基本情報：利用者定員が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  if (organization.isMultifunctionalOrganization && !organization.totalUserCapacity) { 
    messages.push({
      text:`事業所基本情報：多機能型事業所が選択されていますが人数が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  if (organization.pickUpService.hasPickUpService && !organization.pickUpService.serviceType) { 
    messages.push({
      text:`事業所基本情報：送迎サービスが選択されていますが送迎種類が保存されていません`,
      url: `organization?tab=dailybasicInformation`,
      type: 'error',
    })
  }
  return messages;
}
