import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StripeInfo } from 'common-types/firebase/firestore/organizations/settings';

// TODO: createdByを入れる
const initialState: StripeInfo = {
  customerId: '',
  last4: '',
  brand: '',
  email:  '',
}
const stripeInfoSlice = createSlice({
  name: 'stripeInfo',
  initialState,
  reducers: {
    setStripeInfo: (_state: StripeInfo, action: PayloadAction<StripeInfo>) => {
      return action.payload;
    },
    resetStripeInfo: () => {
      return initialState;
    }
  }
});

export const { setStripeInfo, resetStripeInfo } = stripeInfoSlice.actions;
export const stripeInfoReducer = stripeInfoSlice.reducer;
