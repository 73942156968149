import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const BodyTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  font-weight: 700;
  font-family: Noto Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: 0.5px;
`;

export const SubTitle = styled(Typography).attrs({
  variant: 'body1',
})<{ fontColor?:"white"}>`
  font-size: 18px;
  line-height: 18px;
  font-weight: bold;
  font-family: Noto Sans;
  font-style: normal;
  letter-spacing: 0.5px;
`;

export const BoldTitle = styled(Typography).attrs({
  variant: 'body1',
})<{ fontColor?:"white"}>`
  font-size: 15px;
  font-weight: bold;
  font-family: Noto Sans;
  font-style: normal;
  letter-spacing: 0.5px;
`;

export const BodyText = styled(Typography).attrs({
  variant: 'body1',
})`
  font-weight: 400;
  font-family: Noto Sans;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.5px;
`;

export const MiniText = styled(Typography).attrs({
  variant: 'body1',
})`
  font-weight: 400;
  font-family: Noto Sans;
  font-style: normal;
  font-size: 12px;
  letter-spacing: 0.5px;
`;


export const Caption = styled(Typography).attrs({
  variant: 'body2',
})`
  line-height: ${(props) => props.theme.spacing(5)}px;
  font-weight: 300;
  letter-spacing: 0.5px;
`;
