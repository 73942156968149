import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';
import { firebaseConfig } from '../config';

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();
export const auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const functions = firebase.app().functions('asia-northeast1');
export const storage = firebase.storage();
export const storageRef = firebase.storage().ref();
export const analytics =  firebase.analytics();
export type DocumentReference = firebase.firestore.DocumentReference;
export type QuerySnapshot = firebase.firestore.QuerySnapshot;
export const Timestamp = firebase.firestore.Timestamp;
export type DocumentSnapshot = firebase.firestore.DocumentSnapshot;

firestore.settings({ ignoreUndefinedProperties: true })

// MEMO: emulatorを使う場合はREACT_APP_ENVを指定せずに起動すること
if (!process.env.REACT_APP_ENV) {
  firestore.useEmulator("localhost", 8080);
  auth.useEmulator("http://localhost:9099");
  functions.useEmulator("localhost", 5001);
  storage.useEmulator("localhost", 9199);
}
