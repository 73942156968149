import { auth,googleProvider } from './index';
import firebase from 'firebase';

export const googleLogin = () => {
  auth.signInWithRedirect(googleProvider);
}

export const logout =() => {
  auth.signOut();
}

const login = async (email: string, passwd: string) => {
  // ログイン処理
  return await auth.signInWithEmailAndPassword(email, passwd)
    .then(async(userCredential) => {
      // Signed in
      // const user = userCredential.user;
      return true;
    }).catch((error) => {
      throw error;
  });
}

const isEmail = async (email: string) => {
  const res = await auth.fetchSignInMethodsForEmail(email)
    .then((signInMethods) => {
      if (signInMethods.indexOf(
        firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1) {
        // emailとパスワードでログイン可能
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode + ': ' + errorMessage);
      return false;
    });
  return res;
}

export const mailLogin = async (email: string, passwd: string) => {
  if (email && passwd) {
    // 既にemailがauthに登録されているかチェック
    if (await isEmail(email)) {
    } else {
      return false;
    }
    // ログイン実行
   return await login(email, passwd).catch((error) => {
      throw error;
    });
  } else {
    return false;
  }
}

export const createUserAndLogin = async (email: string, passwd: string) => {
  if (email && passwd) {
    // authへのユーザ作成
    await auth.createUserWithEmailAndPassword(email, passwd)
      .then(async (userCredential) => {
        // ログイン実行
        await login(email, passwd);
        return true;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode + ': ' + errorMessage);
        return false;
      });
  } else {
    return false;
  }
}

export const resetPasswdWithEmail = async (email: string) => {
  // パスワードリセット案内のメール送信
  return await auth.sendPasswordResetEmail(email)
    .then(async () => {
      // Email送信成功.
      return true;
    }).catch(function (error) {
      throw error;
    });
}
