import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasicReward } from 'common-types/firebase/firestore/organizations/settings';

export type StateBasicReward = Omit<BasicReward, 'createAt' | 'updateAt'> & {
  createAtMillis: number, updateAtMillis: number
}
const initialState: StateBasicReward = {
  scoreOfAverageWorkingHours: 5,
  scoreOfProductionActivity: 5,
  scoreOfWorkStyle: 0,
  scoreOfImprovementSupport: 0,
  scoreOfLocalCooperationActivity: 0,
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const basicRewardSlice = createSlice({
  name: 'basicReward',
  initialState,
  reducers: {
    setBasicReward: (_state: StateBasicReward, action: PayloadAction<StateBasicReward>) => {
      return action.payload;
    },
    resetBasicReward: () => {
      return initialState;
    }
  }
});

export const { setBasicReward, resetBasicReward } = basicRewardSlice.actions;
export const basicRewardReducer = basicRewardSlice.reducer;
