import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SupportPlan } from 'common-types/firebase/firestore/organizations/supportPlans';

export type StateSupportPlan = Omit<SupportPlan,
'planAt' |
'beforePlanAt' |
'supportDate' |
'longTermObjective' |
'shortTermObjective' |
'serviceContents' |
'meetingForSupport' |
'createAt' |
'updateAt'> &
{
  planAtMillis: number | null,
  beforePlanAtMillis: number | null,
  supportDate: {
    startAtMillis: number | null,
    endAtMillis: number | null
  },
  longTermObjective: {
    objective: string,
    setAtMillis: number | null,
    willArchiveAtMillis: number | null,
  },
  shortTermObjective:{
    objective: string,
    setAtMillis: number | null,
    willArchiveAtMillis: number | null,
  }
  serviceContents: {
    supportPolicy: Array<StateSupportPolicy>,
    transferServiceType: number,
    programs:  Array<StateProgram>
  },
  meetingForSupport: StateMeetingForSupport,
  createAtMillis: number | null,
  updateAtMillis: number | null,
  supportPlanId: string,
  attachedFiles?: Array<{
    photoUrl: string;
    memo: string;
  }>
};

export type StateSupportPolicy = {
  goal: string, // 目標
  personalActivity: string, // 本人の取組内容
  staffSupport: string, // 職員の支援内容
  startAtMillis: number | null, // 適用開始日
  endAtMillis: number | null, // 適用終了日
}
type StateProgram = {
  scheduledTime: string; // 予定時間
  content: string;// サービス内容
}

type StateMeetingForSupport = {
  dateAtMillis: number | null;// 実施日
  participants: Array<string>;//  参加者, memberId
  note: string;// 会議議事録
}

export const initSupportPolicy = {
  goal: '',
  personalActivity: '',
  staffSupport: '',
  startAtMillis: null,
  endAtMillis: null,
}
export const initProgram = {
  scheduledTime: '',
  content: ''
}
export const initStateObject = {
  userId: '',
  planAtMillis: null,
  beforePlanAtMillis: null,
  supportDate: {
    startAtMillis: null,
    endAtMillis: null
  },
  approvedBy: '',
  isApproved: false,
  personalInfomation: {
    personalHistory: '',
    userRequest: '',
    IncomeInformation: '',
    challengeForActivities: '',
    healthCondition: '',
    medicalRiskForActivities: '',
    livingConditions: '',
  },
  longTermObjective: {
    objective: '',
    setAtMillis: null,
    willArchiveAtMillis: null,
  },
  shortTermObjective: {
    objective: '',
    setAtMillis: null,
    willArchiveAtMillis: null,
  },
  serviceContents: {
    supportPolicy: [initSupportPolicy],
    transferServiceType: 0,
    programs:  [initProgram]
  },
  others: {
    notice: '',
    staffComment: ''
  },
  meetingForSupport: {
    dateAtMillis: null,
    participants: [],
    note: '',
  },
  createAtMillis: null,
  updateAtMillis: null,
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
  supportPlanId: '',
  attachedFiles: [],
  };
const initialState: StateSupportPlan[] = [];

const supportPlansSlice = createSlice({
  name: 'supportPlans',
  initialState,
  reducers: {
    setSupportPlans: (_state: StateSupportPlan[], action: PayloadAction<StateSupportPlan[]>) => {
      return action.payload;
    },
    resetSupportPlans: () => {
      return initialState;
    }
  }
});

export const { setSupportPlans, resetSupportPlans } = supportPlansSlice.actions;
export const supportPlansReducer = supportPlansSlice.reducer;
