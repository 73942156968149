import { ValidationModel } from "../types/validation";

export const specialStringRegex = /[-/\\^$*+?.()|[\]{}]/g;
export const katakanaRegex = /^([\u{3000}-\u{301C}\u{30A1}-\u{30F6}\u{30FB}-\u{30FE}])+$/mu;
export const urlRegex = /https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g;
export const emailRegex = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/i;
export const phoneNumberRegex = /^[0-9]{10,11}$/;
export const patientNumberRegex = /^\d+$/;
export const priceRegex = /^([1-9]\d*|0)$/;
export const bankAccountNumberRegex = /^\d{7}$/;
export const branchCodeRegex = /^\d{3}$/;
export const postalCodeRegex = /^(\d{7}|\d{8})$/
export const address_2Regex = /^[0-9-]+$/;
export const onChangeCatchCopyRegex = /^.{0,30}$/;
export const symbolRegex = /[!"#$%&'()☆＊※@☆*+\-.,/:;<=>?@＠[\\\]^_`{|}~【】￥「」]/g;
export const percentRegex = /^[1-9][0-9]?$/;
export const organizationCodeRegex = /^[0-9]{10}$/;
export const naturalNumberRegex = /^\d+$/;
export const intGreaterThanZero = /^([1-9]\d*|0)$/
export const areaCategoryRegex = /^[0-9]{1}$/;
export const cityCodeRegex = /^[0-9]{6}$/;
export const hyphenRegex = /^(?!.*-|ー).*$/;
export const zenkakuRegex = /^[^\x01-\x7E\uFF61-\uFF9F]+$/;
export const hoursRegex = /^(0(\.[0-9])?|1?[0-9](\.[0-9])?|2[0-3](\.[0-9])?|24(\.0)?)$/;
export const businessLogAccompanyingTimeRegex =
  /^(0(\.[0-9]{1,2})?|1?[0-9](\.[0-9]{1,2})?|2[0-3](\.[0-9]{1,2})?|24(\.00?)?)$/;
export const passwdRegex = /^[a-zA-Z0-9!-/:-@¥[-`{-~]{8,}$/
export const yyyymmddRegex = /^\d{4}-\d{2}-\d{2}$/
export const yyyymmRegex = /^\d{4}-\d{2}$/
export const twoDigitRegex = /^\d{2}$/

// 通常フォーム用
export const isContain = (validationMessages: ValidationModel[], key: string) => {
  return validationMessages.some(m=> m.key === key)
}

export const getValidationMessage = (validationMessages: ValidationModel[], key: string) => {
  const m = validationMessages.find(m=> m.key === key);
  return m?.message;
}

// 配列フォーム用
export const isContainIndex = (validationMessages: ValidationModel[], key: string, index: number) => {
  return validationMessages.some(m=> m.key === key && m.index === index)
}

export const getValidationMessageIndex = (validationMessages: ValidationModel[], key: string, index: number) => {
  const m = validationMessages.find(m=> m.key === key && m.index === index);
  return m?.message;
}
