import { firestore, DocumentReference } from '../../index';
import { TransferOrganization } from 'common-types/firebase/firestore/transferOrganization';
import { StateTransferOrganization } from '../../../redux/transferOrganization';
import firebase from 'firebase/app';

export const setFirestoreTransferOrganization= async (
  transferOrganizationId:string,
  doc: StateTransferOrganization,
) => {
  const organization = setFirebaseDoc(doc);
  const organizationRef: DocumentReference = await firestore
    .collection('transferOrganizations')
    .doc(transferOrganizationId);
  return await organizationRef.set(organization, {merge: true});
};

const setFirebaseDoc = (doc: StateTransferOrganization): TransferOrganization => {
  const { createAtMillis, updateAtMillis, totalUserCapacity, ...params } = doc;
  return {
    ...params,
    totalUserCapacity: Number(totalUserCapacity),
    createAt: firebase.firestore.Timestamp.fromMillis(createAtMillis),
    updateAt: firebase.firestore.Timestamp.fromMillis(updateAtMillis),
  }
}

export const getFirestoreTransferOrganization = async(transferOrganizationId: string) => {
  const organizationRef: DocumentReference =  firestore
    .collection('transferOrganizations')
    .doc(transferOrganizationId);
  return organizationRef.get()
}