import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkPlaceCompany } from  'common-types/firebase/firestore/organizations/workPlaceCompanies';

export type Contract = { startAtMillis: number, endAtMillis: number, workDate: string, workTime: string, workContent: string}
export type StateWorkPlaceCompany = Omit<WorkPlaceCompany, 'contract' |'createAt' | 'updateAt'> 
& { contract: Contract, createAtMillis: number | null, updateAtMillis:  number | null, workPlaceCompanyId: string}
export const initialState: StateWorkPlaceCompany[] = [{
  companyName: '',
  postalCode: '',
  prefecture: '',
  city: '',
  town: '',
  address: '',
  phoneNumber: '',
  contract: {
    startAtMillis: 0,
    endAtMillis: 0,
    workDate: '',
    workTime: '',
    workContent: '',
  },
  plannedUser: [],
  note: '',
  supplementaryMemo: '',
  createAtMillis: null,
  updateAtMillis: null ,
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
  workPlaceCompanyId: '',
}]
const workPlaceCompaniesSlice = createSlice({
  name: 'workPlaceCompanies',
  initialState,
  reducers: {
    setWorkPlaceCompanies: (_state: StateWorkPlaceCompany[], action: PayloadAction<StateWorkPlaceCompany[]>) => {
      return action.payload;
    },
    resetWorkPlaceCompanies: () => {
      return initialState;
    }
  }
});

export const { setWorkPlaceCompanies, resetWorkPlaceCompanies } = workPlaceCompaniesSlice.actions;
export const workPlaceCompaniesReducer = workPlaceCompaniesSlice.reducer;