import dayjs from "../lib/dayjs";
import { StateError, Message } from "../redux/error";
import { StateTransferUsageRecord } from "../redux/transferUsageRecords";
import { AttendanceRecordBreakTime, StateUsageRecord, WorkRecord } from "../redux/usageRecords";
import { MedicalCooperation, TransferService } from "common-types/firebase/firestore/organizations/usageRecords";

type PropsUsageRecords = {
  userId: string,
  userName: string,
  usageRecords: StateUsageRecord[],
}

type PropsTypeBUsageRecords = {
  userId: string,
  userName: string,
  usageRecords: StateUsageRecord[],
}

type PropsTransferUsageRecords = {
  userId: string,
  userName: string,
  usageRecords: StateTransferUsageRecord[],
}

export const checkUsageRecords = (props: PropsUsageRecords) => {
  let messages: Message[] = [];
  const { userId, userName, usageRecords } = props;
  messages = messages.concat(checkTypeAUsageRecordsItems(userId, userName, usageRecords));
  const error = messages.map(m => { return {mainMenu:'実績', message: m} as StateError });
  return error;
}

export const checkTypeBUsageRecords = (props: PropsTypeBUsageRecords) => {
  let messages: Message[] = [];
  const { userId, userName, usageRecords } = props;
  messages = messages.concat(checkTypeBUsageRecordsItems(userId, userName, usageRecords));
  const error = messages.map(m => { return {mainMenu:'実績', message: m} as StateError });
  return error;
}

export const checkTransferUsageRecords = (props: PropsTransferUsageRecords) => {
  let messages: Message[] = [];
  const { userId, userName, usageRecords } = props;
  messages = messages.concat(checkTransferUsageRecordsItems(userId, userName, usageRecords));
  const error = messages.map(m => { return {mainMenu:'実績', message: m} as StateError });
  return error;
}

const checkTypeAUsageRecordsItems = (
  userId: string,
  userName: string,
  usageRecords: StateUsageRecord[]
) => {
  let messages = checkCommonUsageRecordsItems(userId, userName, usageRecords);
  return messages;
}

const checkTypeBUsageRecordsItems = (
  userId: string,
  userName: string,
  usageRecords: StateUsageRecord[]
) => {
  let messages = checkCommonUsageRecordsItems(userId, userName, usageRecords);
  return messages;
}

const checkTransferUsageRecordsItems = (
  userId: string,
  userName: string,
  usageRecords: StateTransferUsageRecord[]
) => {
  let messages = checkCommonUsageRecordsItems(userId, userName, usageRecords);
  return messages;
}

const checkCommonUsageRecordsItems = (
  userId: string,
  userName: string,
  usageRecords: (StateUsageRecord | StateTransferUsageRecord)[]
) => {
  const messages: Message[] = [];
  const targetMonths: string[] = [];
  usageRecords.map(usageRecord => {
    const month = dayjs(usageRecord.attendanceRecord.startAtMillis).format('YYYY/MM');
    if (!targetMonths.includes(month)){
      targetMonths.push(month)
    }
  })
  targetMonths.map(targetMonth => {
    const targetMonthDayjs = dayjs(targetMonth);
    const targetUsageRecords = usageRecords.filter(usageRecord => dayjs(usageRecord.attendanceRecord.startAtMillis).format('YYYY/MM')===targetMonth)
    if (targetUsageRecords.filter(usageRecord => usageRecord.serviceProvisionStatus==='supportForAbsence').length > 4) {
      messages.push({
        text:`提供サービスの欠席時対応は1月につき4日以下にしてください（${userName}さん, ${targetMonthDayjs?.format('YYYY年M月')}）`,
        url: `usageRecords?tab=user&userId=${userId}&month=${targetMonthDayjs?.format('YYYY/MM')}`,
        type: 'error',
      })
    };
    if (targetUsageRecords.filter(usageRecord => usageRecord.serviceProvisionStatus==='visitingToUser').length > 2) {
      messages.push({
        text:`提供サービスの訪問は1月につき2日以下にしてください（${userName}さん, ${targetMonthDayjs?.format('YYYY年M月')}`,
        url: `usageRecords?tab=user&userId=${userId}&month=${targetMonthDayjs?.format('YYYY/MM')}`,
        type: 'error',
      })
    };
    if (targetUsageRecords.filter(usageRecord => {return 'wasTookIntensiveSupport' in usageRecord && usageRecord.wasTookIntensiveSupport}).length > 4) {
      messages.push({
        text:`集中的支援加算は1月につき4回以下にしてください（${userName}さん, ${targetMonthDayjs?.format('YYYY年M月')}`,
        url: `usageRecords?tab=user&userId=${userId}&month=${targetMonthDayjs?.format('YYYY/MM')}`,
        type: 'error',
      })
    };  
  })
  return messages;
}
