import { firestore, DocumentReference } from '../index';
import { Organization } from 'common-types/firebase/firestore/organizations';
import { StateOrganization } from '../../redux/organization';
import firebase from 'firebase/app';

export const setFirestoreOrganization= async (
  organizationId:string,
  doc: StateOrganization,
) => {
  const organization = setFirebaseDoc(doc);
  const organizationRef: DocumentReference = await firestore
    .collection('organizations')
    .doc(organizationId);
  return await organizationRef.set(organization, {merge: true});
};

const setFirebaseDoc = (doc: StateOrganization): Organization => {
  const { createAtMillis, updateAtMillis, aTypeExemption, totalUserCapacity, ...params } = doc;
  return {
    ...params,
    totalUserCapacity: Number(totalUserCapacity),
    aTypeExemption: {
      hasATypeExemption: aTypeExemption.hasATypeExemption,
      charge: Number(aTypeExemption.charge),
      unit: aTypeExemption.unit
    },
    createAt: firebase.firestore.Timestamp.fromMillis(createAtMillis),
    updateAt: firebase.firestore.Timestamp.now(),
  }
}

export const getFirestoreOrganization = async(organizationId: string) => {
  const organizationRef: DocumentReference =  firestore
    .collection('organizations')
    .doc(organizationId);
  return organizationRef.get()
}
