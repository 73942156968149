import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubtractingRewardItem2024 } from 'common-types/firebase/firestore/organizations/settings';

export type StateVacantPosition = {
  isVacancyOfStaff: boolean, 
  position: string, 
  startAtMillis: number
};

export type StateSubtractingRewardItem2024 = Omit<SubtractingRewardItem2024, 'vacantPosition' | 'createAt' | 'updateAt'> & { 
  vacantPosition: StateVacantPosition,
  createAtMillis: number, 
  updateAtMillis: number 
};
const initialState: StateSubtractingRewardItem2024 = {
  isInstalledByLocalGovernment: false,
  vacantPosition: {
    isVacancyOfStaff: false,
    position: '',
    startAtMillis: 0,
  },
  isUnpublishedSelfAssessment: false,
  isNotAbolishedPhysicalRestraint: false,
  isDisabledSupportFacilities: false,
  isNoAbusePrevention: false,
  isNoBusinessContinuityPlan: false,
  isNoInformationDisclosured: false,
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const subtractingRewardItem2024Slice = createSlice({
  name: 'subtractingRewardItem2024',
  initialState,
  reducers: {
    setSubtractingRewardItem2024: (_state: StateSubtractingRewardItem2024, action: PayloadAction<StateSubtractingRewardItem2024>) => {
      return action.payload;
    },
    resetSubtractingRewardItem2024: () => {
      return initialState;
    }
  }
});

export const { setSubtractingRewardItem2024, resetSubtractingRewardItem2024 } = subtractingRewardItem2024Slice.actions;
export const subtractingRewardItem2024Reducer = subtractingRewardItem2024Slice.reducer;