import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import firebase from 'firebase/app';
import DialogContentText from '@mui/material/DialogContentText';
import { BodyTitle } from './Text';
import styledComponents from 'styled-components'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

type Props = {
  open: boolean,
  setClose: (value: React.SetStateAction<boolean>) => void,
  title: string,
  component: React.ReactNode,
  onSave?: () => Promise<void | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>,
  disabled?: boolean,
}

export const  CustomizedDialogs: React.VFC<Props> = (props) => {
  const onSave = props.onSave ? props.onSave : () => {};
  const handleClose = () => {
    if (props.disabled) return;
    props.setClose(false);
  };
  const handleSave = async() => {
    await onSave();
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth={'md'}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {props.title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
        {props.component}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus onClick={handleSave}
            disabled={props.disabled}
            style={{border: "1px solid #ED6164", color: "#ED6164"}}
            >
           {'保存する'}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

type DeleteDialogProps = {
  open: boolean,
  disabled: boolean,
  setClose: (data: boolean) => void,
  onSave: () => Promise<void | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>,
}

export const DeleteDialog: React.VFC<DeleteDialogProps>  = (props) => {
  const handleClose = () => {
    if (props.disabled) return;
    props.setClose(false);
  };
  const handleDelete = async() => {
    await props.onSave();
  }
  return(
  <>
    <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"削除しますか？"}
        </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button disabled={props.disabled} onClick={handleClose}>キャンセル</Button>
          <Button disabled={props.disabled} onClick={handleDelete} autoFocus>
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

type SimpleDialogProps = {
  open: boolean,
  setClose: (value: React.SetStateAction<boolean>) => void,
  title?: string,
  component: React.ReactNode,
  disabled?: boolean,
  buttomComponent?: React.ReactNode,
}

export const OTRDialog: React.VFC<SimpleDialogProps> = (props) => {
  const handleClose = () => {
    if (props.disabled) return;
    props.setClose(false);
  };

  return(
    <StyledDialog
    open={props.open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth='md'
  >
    <OTRTitleWrap>
       <BodyTitle>{props.title}</BodyTitle>
      </OTRTitleWrap>
    <OTRDialogContent>
      {props.component}
    </OTRDialogContent>
    <OTRDialogActions>
      {props.buttomComponent}
    </OTRDialogActions>
  </StyledDialog>
  )
}
export const SimpleDialog: React.VFC<SimpleDialogProps> = (props) => {
const handleClose = () => {
    if (props.disabled) return;
    props.setClose(false);
  };
  return(
    <StyledDialog
    open={props.open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth='md'
  >
    <TitleWrap>
       <StyledBodyTitle>{props.title}</StyledBodyTitle>
      </TitleWrap>
    <StyledDialogContent>
      {props.component}
    </StyledDialogContent>
    <StyledDialogActions>
      {props.buttomComponent}
    </StyledDialogActions>
  </StyledDialog>
  )
}

export const AlartDialog: React.VFC<SimpleDialogProps> = (props) => {
  const handleClose = () => {
      if (props.disabled) return;
      props.setClose(false);
    };
    return(
      <AlertDialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // maxWidth='md'
    >
      <TitleWrap>
         <BodyTitle>{props.title}</BodyTitle>
        </TitleWrap>
      <StyledDialogContent>
        {props.component}
      </StyledDialogContent>
      <StyledDialogActions>
        {props.buttomComponent}
      </StyledDialogActions>
    </AlertDialog>
    )
  }

const OTRTitleWrap = styledComponents.div`
margin: 0;
`;


const OTRDialogActions = styledComponents(DialogActions)`
&.MuiDialogActions-root{
  padding: 0
}
`;

const OTRDialogContent = styledComponents(DialogContent)`
&.MuiDialogContent-root{
  width: 388px;
  height: 585px;
}
`;
const TitleWrap = styledComponents.div`
margin: ${props => props.theme.spacing(6)}px ${props => props.theme.spacing(4)}px  ${props => props.theme.spacing(2.5)}px ${props => props.theme.spacing(4)}px;
`;

const StyledDialogContent = styledComponents(DialogContent)`
&.MuiDialogContent-root{
  padding: 0 ${props => props.theme.spacing(4)}px;
  min-width: 720px;
}
`;

const StyledDialog = styledComponents(Dialog)`
`;

const StyledDialogActions = styledComponents(DialogActions)`
&.MuiDialogActions-root{
  padding: 0
}
`;

const AlertDialog = styledComponents(Dialog)`
  position: absolute;
  width: 984px;
  height: 512px;
  left: 108px;
  top: 278px;
`;

const StyledBodyTitle = styled(BodyTitle)`
font-size:32px;
line-height: 40px;
`;
