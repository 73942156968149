import { StateError, Message } from "../redux/error";
import { StateUserProfile } from "../redux/profile";

type PropsProfile = {
  userId: string,
  userName: string,
  profile: StateUserProfile | undefined,
}

export const checkProfile = (props: PropsProfile) => {
  let messages: Message[] = [];
  const { userId, userName, profile } = props;

  if(!profile) {
    messages.push({
      text:`${userName}さんの基本情報が登録されていません`,
      url: `users/${userId}/basicInfo?tab=profile`,
      type: 'error',
    })
    const error = messages.map(m => { return {mainMenu:'利用者情報', message: m} as StateError });
    return error;
  } 
  if(!profile.createAtMillis) { // 初回利用者登録
    return [] as StateError[];
  }
  messages = messages.concat(checkProfileItems(userId, userName, profile));
  const error = messages.map(m => { return {mainMenu:'利用者情報', message: m} as StateError });
  return error;
}

const checkProfileItems = (
  userId: string,
  userName: string,
  profile: StateUserProfile
) => {
  let messages = checkCommonProfileItems(userId, userName, profile);
  return messages;
}

const checkCommonProfileItems = (
  userId: string,
  userName: string,
  profile: StateUserProfile
) => {
  const messages: Message[] = [];
  if (!profile.cityCode) { 
    messages.push({
      text:`${userName}さんの基本情報：市区町村番号が登録されていません`,
      url: `users/${userId}/basicInfo?tab=profile`,
      type: 'error',
    })
  }
  if (!profile.lastNameKanji || !profile.firstNameKanji) { 
    messages.push({
      text:`${userName}さんの基本情報：氏名（漢字）が登録されていません`,
      url: `users/${userId}/basicInfo?tab=profile`,
      type: 'error',
    })
  }
  if (!profile.lastNameKana || !profile.firstNameKana) { 
    messages.push({
      text:`${userName}さんの基本情報：氏名（カナ）が正しく登録されていません`,
      url: `users/${userId}/basicInfo?tab=profile`,
      type: 'error',
    })
  }
  if (profile.isNotBillable || !profile.beneficiaryNumber) { 
    messages.push({
      text:`${userName}さんの基本情報：受給者番号が登録されていません`,
      url: `users/${userId}/basicInfo?tab=profile`,
      type: 'error',
      isInStateUser: true,
    })
  }
  return messages;
}
