import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BeneficiaryCertificate } from 'common-types/firebase/firestore/users/settings';

export type StateQualificationModel = {
  hasQualification: boolean;
  startAtMillis: number | null;
  endAtMillis: number | null;
}

export type StateBeneficiaryCertificate = Omit<BeneficiaryCertificate,
'createAt'|
'updateAt'|
'periodOfMaximuMonthlyBurden'|
'periodOfMealProvisionSystemAddition'|
'certificationValidityPeriodOfDisabilitySupportCategory'|
'supportDecisionPeriodforCareBenefits'|
'paymentPeriodOfPlanningSupportExpenses'|
'monitoringPeriodOfPlanningSupportExpenses'
> & {
  createAtMillis: number | null,
  updateAtMillis: number | null,
  periodOfMaximuMonthlyBurden: StateQualificationModel,
  periodOfMealProvisionSystemAddition: StateQualificationModel,
  certificationValidityPeriodOfDisabilitySupportCategory: StateQualificationModel,
  supportDecisionPeriodforCareBenefits: StateQualificationModel,
  paymentPeriodOfPlanningSupportExpenses: StateQualificationModel,
  monitoringPeriodOfPlanningSupportExpenses: StateQualificationModel,
};

const stateQualificationModelInit = {
  hasQualification: false,
  startAtMillis: null,
  endAtMillis: null,
}

const initialState: StateBeneficiaryCertificate = {
  periodOfMaximuMonthlyBurden: stateQualificationModelInit,
  periodOfMealProvisionSystemAddition: stateQualificationModelInit,
  certificationValidityPeriodOfDisabilitySupportCategory: stateQualificationModelInit,
  supportDecisionPeriodforCareBenefits: stateQualificationModelInit,
  paymentPeriodOfPlanningSupportExpenses: stateQualificationModelInit,
  monitoringPeriodOfPlanningSupportExpenses: stateQualificationModelInit,
  createAtMillis: null,
  updateAtMillis: null,
  createdBy: '',
  updatedBy: '',
}
const beneficiaryCertificateSlice = createSlice({
  name: 'beneficiaryCertificate',
  initialState,
  reducers: {
    setBeneficiaryCertificate: (_state: StateBeneficiaryCertificate, action: PayloadAction<StateBeneficiaryCertificate>) => {
      return action.payload;
    },
    resetBeneficiaryCertificate: () => {
      return initialState;
    }
  }
});

export const { setBeneficiaryCertificate, resetBeneficiaryCertificate } = beneficiaryCertificateSlice.actions;
export const beneficiaryCertificateReducer = beneficiaryCertificateSlice.reducer;
