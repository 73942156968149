import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserProfile } from 'common-types/firebase/firestore/users/settings';

export type StateUserProfile = Omit<UserProfile, 'createAt' | 'updateAt'> & { createAtMillis: number | null, updateAtMillis:  number | null};
const initialState: StateUserProfile = {
  photoUrl: "",
  lastNameKanji: "",
  firstNameKanji: "",
  lastNameKana: "",
  firstNameKana: "",
  beneficiaryNumber: "",
  isNotBillable: false,
  disability: {
    isPhysicalDisability: false,
    isIntellectualDisability: false,
    isMentalIllness: false,
    isDevelopmentalDisability: false,
    isHigherBrainDisorder: false,
    isChildrenWithDisabilities: false,
    isIntractableDiseaseTarget: false,
  },
  gender: "male",
  birthday: "",
  postalCode: "",
  prefecture: "",
  city: "",
  town: "",
  address: "",
  cityCode: "",
  phoneNumber: "",
  email: "",
  protectorName: "",
  relationship: "",
  emergencyContact: "",
  memo: "",
  createAtMillis: null,
  updateAtMillis: null,
  createdBy: "",
  updatedBy: "",
  admissionDate: ""
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile: (_state: StateUserProfile, action: PayloadAction<StateUserProfile>) => {
      return action.payload;
    },
    resetProfile: () => {
      return initialState;
    }
  }
});

export const { setProfile, resetProfile } = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
