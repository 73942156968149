import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { store } from "./redux";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from '@sentry/browser';
/*
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

// 永続化の設定
const persistConfig = {
  key: 'root', // Storageに保存されるキー名を指定する

  whitelist: ['todos'] // Stateは`todos`のみStorageに保存する
  // blacklist: ['visibilityFilter'] // `visibilityFilter`は保存しない
}*/

Sentry.init({
  dsn: process.env.NODE_ENV === "production"
      ? "https://c69c7518956d4732a5b204ef2250b009@o4505029502369792.ingest.sentry.io/4505029568626688"
      : "https://11e57a56012c407eaa5734bbfdfe52ac@o4505029502369792.ingest.sentry.io/4505029504466944",
  environment: process.env.NODE_ENV ,
  tracesSampleRate: 1.0,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: false,
    }),
    new BrowserTracing(),
    new Sentry.Replay()
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
