import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const businessLogYearMonthSlice = createSlice({
  name: 'businessLogYearMonth',
  initialState: '',
  reducers: {
    setBusinessLogYearMonth: (_state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
    resetBusinessLogYearMonth: () => {
      return '';
    }
  }
});

export const { setBusinessLogYearMonth, resetBusinessLogYearMonth } = businessLogYearMonthSlice.actions;
export const businessLogYearMonthReducer = businessLogYearMonthSlice.reducer;
