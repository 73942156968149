import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Member } from 'common-types/firebase/firestore/members/';

export type StateMember = Omit<Member, 'createAt'|'updateAt'> & {
  memberId: string,
  createAtMillis: number | null,
  updateAtMillis: number | null,
};

export const initialState: StateMember = {
  memberId: '',
  roles: [{
    organizationId: '',
    role: '',
    serviceType: '',
    position: '',
  }],
  organizationIds: [],
  email: '',
  name: '',
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
};

const memberDataSlice = createSlice({
  name: 'memberData',
  initialState,

  reducers: {
    setMemberData: (_state: StateMember, action: PayloadAction<StateMember>) => {
      return action.payload;
    },
    resetMemberData: () => {
      return initialState;
    }
  }
});

export const { setMemberData, resetMemberData } = memberDataSlice.actions;
export const memberDataReducer = memberDataSlice.reducer;
