import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

type progressType = {
  message?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  inline: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  progress: {
    marginTop: ' 5vw',
  },
  customProgress: {
    position: 'absolute',
    top: 250,
    marginTop: ' 2vw',
  },
}));

export const Progress = (props: progressType) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Message>{props.message}</Message>
      <CircularProgress className={classes.progress} size={100} />
    </div>
  );
};

const Message = styled.div`
  margin-top: 5vw;
  font-size: 24px;
`;