import dayjs from "../lib/dayjs";
import { StateError, Message } from "../redux/error";
import { StateUser } from "../redux/users";
import { checkExpireDate } from "./checkExpireDate";

type Props = {
  userId: string,
  userName: string,
  user: StateUser | undefined,
}

export const checkStateUser = (props: Props) => {
  const error: StateError[] = [];
  const message: Message[] = [];
  const { userId, userName, user } = props;

  if(user) {
    const u = user;
    const b = u.beneficiaryCertificateDetail;
    const hasReceivedServiceExpireDate = checkExpireDate(u.hasReceivedService.endAtMillis)
    const hasReceivedCertificationExpireDate = checkExpireDate(u.hasReceivedCertification.endAtMillis)
    const periodOfMaximuMonthlyBurdenExpireDate = checkExpireDate(b.periodOfMaximuMonthlyBurden.endAtMillis)
    const periodOfMealProvisionSystemAdditionExpireDate = checkExpireDate(b.periodOfMealProvisionSystemAddition.endAtMillis)
    const certificationValidityPeriodOfDisabilitySupportCategoryExpireDate = checkExpireDate(b.certificationValidityPeriodOfDisabilitySupportCategory.endAtMillis)
    const supportDecisionPeriodforCareBenefitsExpireDate = checkExpireDate(b.supportDecisionPeriodforCareBenefits.endAtMillis)
    const paymentPeriodOfPlanningSupportExpensesExpireDate = checkExpireDate(b.paymentPeriodOfPlanningSupportExpenses.endAtMillis)
    const monitoringPeriodOfPlanningSupportExpensesExpireDate = checkExpireDate(b.monitoringPeriodOfPlanningSupportExpenses.endAtMillis)
    if (!u.beneficiaryNumber) {
      message.push({
        text:`${userName}さんの基本情報：受給者番号が登録されていません`,
        url: `users/${userId}/basicInfo?tab=profile`,
        type: 'error',
        isInStateUser: true,
      })
    }
    // 現在サービス利用期間内かどうか
    // 開始終了が現在の間に入っている
    // 終了期間が入っていない
    const isInsideServicePeriod = (((u.hasReceivedService.startAtMillis && u.hasReceivedService?.endAtMillis) && (u.hasReceivedService.startAtMillis < dayjs().valueOf() && u.hasReceivedService.endAtMillis >= dayjs().valueOf())) || !u.hasReceivedService?.endAtMillis) ? true : false
    // サービス利用詳細
    if (!u.hasReceivedService.startAtMillis) {
      message.push({
        text: `${userName}さんのサービス利用詳細情報：サービス提供期間支給決定開始日が登録されていません`,
        url: `users/${userId}/basicInfo?tab=service`,
        type: 'error',
        isInStateUser: true,
      })
    };
    if (hasReceivedServiceExpireDate) {
      message.push({
        text: `${userName}さんのサービス提供期間が${
          hasReceivedServiceExpireDate + 1
        }日後に切れます`,
        url: `users/${userId}/basicInfo?tab=service`,
        type: 'alert',
        isInStateUser: true,
      });
    };
    if (!u.hasReceivedCertification.startAtMillis) {
      message.push({
        text: `${userName}さんのサービス利用詳細情報：受給者証支給決定開始日が登録されていません`,
        url: `users/${userId}/basicInfo?tab=service`,
        type: 'error',
        isInStateUser: true,
      })
    };
    if (!u.hasReceivedCertification.endAtMillis) {
      message.push({
        text: `${userName}さんのサービス利用詳細情報：受給者証支給決定終了日が登録されていません`,
        url: `users/${userId}/basicInfo?tab=service`,
        type: 'error',
        isInStateUser: true,
      })
    };
    if (hasReceivedCertificationExpireDate && isInsideServicePeriod) {
      message.push({
        text: `${userName}さんの受給者証の支給決定期間が${
          hasReceivedCertificationExpireDate + 1
        }日後に切れます`,
        url: `users/${userId}/basicInfo?tab=service`,
        type: 'alert',
        isInStateUser: true,
      });
    };
    // 受給者証詳細
    if (!u.createAtMillis) {
      message.push({
        text:`${userName}さんの受給者証詳細情報が登録されていません`,
        url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
        type: 'error',
        isInStateUser: true,
      })
    }
    // サービス利用期間に入っている前提
    if (periodOfMaximuMonthlyBurdenExpireDate && isInsideServicePeriod) {
      message.push({
        text: `${userName}さんの負担上限額の適用期間が${
          periodOfMaximuMonthlyBurdenExpireDate + 1
        }日後に切れます`,
        url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
        type: 'alert',
        isInStateUser: true,
      });
    };
    if (periodOfMealProvisionSystemAdditionExpireDate && isInsideServicePeriod) {
      message.push({
        text: `${userName}さんの食事提供体制加算の適用期間が${
          periodOfMealProvisionSystemAdditionExpireDate + 1
        }日後に切れます`,
        url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
        type: 'alert',
        isInStateUser: true,
      });
    };
    if (certificationValidityPeriodOfDisabilitySupportCategoryExpireDate && isInsideServicePeriod) {
      message.push({
        text: `${userName}さんの障害支援区分の認定有効期間が${
          certificationValidityPeriodOfDisabilitySupportCategoryExpireDate + 1
        }日後に切れます`,
        url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
        type: 'alert',
        isInStateUser: true,
      });
    };
    if (supportDecisionPeriodforCareBenefitsExpireDate && isInsideServicePeriod) {
      message.push({
        text: `${userName}さんの介護給付費の支援決定期間が${
          supportDecisionPeriodforCareBenefitsExpireDate + 1
        }日後に切れます`,
        url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
        type: 'alert',
        isInStateUser: true,
      });
    };
    if (paymentPeriodOfPlanningSupportExpensesExpireDate && isInsideServicePeriod) {
      message.push({
        text: `${userName}さんの計画相談支援給付費の支給期間が${
          paymentPeriodOfPlanningSupportExpensesExpireDate + 1
        }日後に切れます`,
        url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
        type: 'alert',
        isInStateUser: true,
      });
    };
    if (monitoringPeriodOfPlanningSupportExpensesExpireDate && isInsideServicePeriod) {
      message.push({
        text: `${userName}さんの計画相談支援給付費のモニタリング期間が${
          monitoringPeriodOfPlanningSupportExpensesExpireDate + 1
        }日後に切れます`,
        url: `users/${userId}/basicInfo?tab=beneficiaryCertificate`,
        type: 'alert',
        isInStateUser: true,
      });
    };
    message.map(m => error.push({mainMenu:'利用者情報', message: m}));
  }
  return error
}
