import "./App.css";
import { Suspense } from 'react';
import "./firebase/index";
import {
  ThemeProvider as MaterialThemeProvider,
  StylesProvider,
} from "@material-ui/styles";
import { FirebaseToRedux } from "./firebase/FirebaseToRedux";
import { BrowserRouter as Router } from 'react-router-dom';
import { AppLogo } from './components/atoms/AppLogo';
import { ThemeProvider } from "styled-components";
import { theme } from "./styles/theme";
import { HelmetProvider } from "react-helmet-async";
import { GlobalStyle } from "./styles/GlobalStyle";
import { AppFrame } from './components/templates/AppFrame';
import { Pages } from './components/pages';
import { Progress } from './components/atoms/Progress';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { store } from './redux';

const persistor = persistStore(store)


function App() {
  return (
    <StylesProvider injectFirst>
      <PersistGate loading={null} persistor={persistor}>
      <MaterialThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <HelmetProvider>
              <FirebaseToRedux />
              <Router>
              <Suspense fallback={<Progress />}>
              <AppFrame
                appLogo={<AppLogo />}
              >
              <Pages/>
              </AppFrame>
              </Suspense>
              </Router>
          </HelmetProvider>
        </ThemeProvider>
      </MaterialThemeProvider>
      </PersistGate>
    </StylesProvider>
  );
}

export default App;
