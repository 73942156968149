import { StateError, Message } from "../redux/error";
import { StateSupportPlan } from "../redux/supportPlans";
import { checkExpireDate, isWithInOneMonth } from "./checkExpireDate";

type Props = {
  userId: string,
  userName: string,
  supportPlan: StateSupportPlan | undefined,
}

export const checkSupportPlan = (props: Props) => {
  const error: StateError[] = [];
  const message: Message[] = [];
  const { userId, userName, supportPlan } = props;
  const supportPlanId = supportPlan?.supportPlanId;

  if(supportPlan) {
    const s = supportPlan;
    const expireDate = checkExpireDate(s.supportDate.endAtMillis);

    if (expireDate) {
      message.push({
        text: `${userName}さんの支援期間が${expireDate + 1}日後に終了します`,
        url: `users/${userId}/supportPlans/${supportPlanId}?tab=supportPlans`,
        type: 'alert',
      });
    };
  }
  message.map(m => error.push({mainMenu:'利用者情報', message: m}));
  return error
}

type checkSupportPlanArgs = {
  userName: string,
  supportPlan: StateSupportPlan,
}

export const checkSupportPlanAlert = ({userName, supportPlan}: checkSupportPlanArgs):Message | undefined => {
  const date = isWithInOneMonth(supportPlan.supportDate.endAtMillis)
  if (date) {
    return {
        text:`${userName}さんの支援期間は1ヶ月以内に更新が必要です。`,
        url: `users/${supportPlan.userId}/supportPlans/${supportPlan.supportPlanId}?tab=supportPlans`,
        type: 'alert',
      }
  }
}
