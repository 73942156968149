import { StateSupportPlan} from '../../../redux/supportPlans';
import dayjs from '../../../lib/dayjs';

// 対象の支援計画が支援期間内か？
export const isInsidePeriodSupportPlan = (userId: string, supportPlan: StateSupportPlan) =>{
  return(supportPlan.userId === userId &&
    supportPlan.supportDate.startAtMillis &&
    supportPlan.supportDate.endAtMillis &&
    dayjs().isBetween(supportPlan.supportDate.startAtMillis, supportPlan.supportDate.endAtMillis, null, '[]')
    )
}

// 対象の支援計画が支援期間外か？
const isOutsidePerioSupportPlan = (userId: string, supportPlan: StateSupportPlan) =>{
  return(supportPlan.userId === userId &&
    supportPlan.supportDate.startAtMillis &&
    supportPlan.supportDate.endAtMillis &&
    !dayjs().isBetween(supportPlan.supportDate.startAtMillis, supportPlan.supportDate.endAtMillis, null, '[]')
    )
}

// 支援期間内の支援計画をfilterする
export const filterInsidePeriodSupportPlan = (userId: string, supportPlans: StateSupportPlan[]) =>{
  return supportPlans.filter((supportPlan)=>{
    return isInsidePeriodSupportPlan(userId, supportPlan)
  })
}

// 支援期間外の支援計画をfilterする
export const filterOutsidePeriodSupportPlan = (userId: string, supportPlans: StateSupportPlan[]) =>{
  return supportPlans.filter((supportPlan)=>{
    return isOutsidePerioSupportPlan(userId, supportPlan)
  })
}

// supportDate.endAtMillisをdescでソート
export const sortSupportPlanSupportEndDateDesc = (supportPlans: StateSupportPlan[]) => {
  return supportPlans.slice().sort(
    (a, b) => {
      return (
        a.supportDate?.endAtMillis &&  b.supportDate?.endAtMillis
        ) &&
        a.supportDate?.endAtMillis > b.supportDate?.endAtMillis ?
        -1 :
        1
      }
    );
}
