import { StripeInfo } from "common-types/firebase/firestore/organizations/settings";
import { StateError, Message } from "../redux/error";

type Props = {
  stripeInfo: StripeInfo | undefined;
}

export const checkStripeInfo = (props: Props) => {
  const error: StateError[] = [];
  const message: Message[] = [];
  const { stripeInfo } = props;

  if(stripeInfo) {
    if (!stripeInfo.customerId || !stripeInfo.last4) {
      message.push({
        text:`カード情報が登録されていません`,
        url: 'register-card',
        type: 'error',
        isFatal: true,
      })
    };
  }
  message.map(m => error.push({mainMenu:'事業所設定', subMenu:'カード登録', message: m}));
  return error
}