import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from '../lib/dayjs';

const supportRecordYearAndMonthSlice = createSlice({
  name: 'supportRecordYearAndMonth',
  initialState: dayjs().format('YYYY-MM'),
  reducers: {
    setSupportRecordYearAndMonthSlice: (_state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
    resetSupportRecordYearAndMonthSlice: () => {
      return '';
    }
  }
});

export const { setSupportRecordYearAndMonthSlice, resetSupportRecordYearAndMonthSlice } = supportRecordYearAndMonthSlice.actions;
export const supportRecordYearAndMonthReducer = supportRecordYearAndMonthSlice.reducer;
