import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeBOrganization } from 'common-types/firebase/firestore/typeBOrganization';

export type StateTypeBOrganization = Omit<TypeBOrganization, 'createAt' | 'updateAt'> &
{
  createAtMillis: number,
  updateAtMillis: number
};
const initialState: StateTypeBOrganization = {
  companyName: '',
  organizationCode: '',
  organizationName: '',
  serviceType: '',
  userCapacity: 0,
  representativeId: '',
  isGroupOrganization: false,
  isMaster: true,
  isMultifunctionalOrganization: false,
  totalUserCapacity: 0,
  postalCode: '',
  prefecture: '',
  city: '',
  areaCategory: '',
  areaCategory2024: '',
  cityCode: '',
  town: '',
  address: '',
  phoneNumber: '',
  hasFoodService: false,
  pickUpService: {
    hasPickUpService: false,
    serviceType: '',
  },
  status: 'active',
  startPaymentDate: '',
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: '',
};

const typeBOrganizationSlice = createSlice({
  name: 'typeBOrganization',
  initialState,
  reducers: {
    setTypeBOrganization: (_state: StateTypeBOrganization, action: PayloadAction<StateTypeBOrganization>) => {
      return action.payload;
    },
    resetTypeBOrganization: () => {
      return initialState;
    }
  }
});

export const { setTypeBOrganization, resetTypeBOrganization } = typeBOrganizationSlice.actions;
export const typeBOrganizationReducer = typeBOrganizationSlice.reducer;