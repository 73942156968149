import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { createSelectorHook,useDispatch } from 'react-redux';
import { loginedReducer } from './logined';
import { memberDataReducer } from './memberData';
import { rolesReducer } from './roles';
import { userIdReducer} from './userId';
import { usagesReducer } from './usages';
import { profileReducer } from './profile';
import { beneficiaryCertificateReducer } from './beneficiaryCertificate';
import { usageRecordsReducer } from './usageRecords';
import { supportRecordsReducer } from './supportRecords';
import { supportPlansReducer } from './supportPlans';
import { workItemsReducer } from './workItems';
import { businessHoursReducer } from './businessHours';
import { workingHoursReducer } from './workingHours';
import { basicRewardReducer } from './basicReward';
import { subtractingRewardItemReducer } from './subtractingRewardItem';
import { addingRewardItemReducer } from './addingRewardItem';
import { invoicesReducer } from './invoices';
import { membersReducer } from './members';
import { usersReducer } from './users';
import { workPlaceCompaniesReducer } from './workPlaceCompanies';
import { businessLogsReducer } from './businessLogs';
import { workRecordsReducer } from './workRecords';
import { organizationReducer } from './organization';
import { initialSettingReducer } from './initialSetting';
import { interviewYearAndMonthReducer } from './interviewYearMonth';
import { businessLogYearMonthReducer } from './businessLogYearMonth';
import { stripeInfoReducer } from './stripeInfo';
import { userYearMonthReducer } from './userYearMonth';
import { interviewRecordsReducer } from './interviewRecords';
import { supportPlanReviewsReducer } from './supportPlanReviews';
import { billingReducer } from './billing';
import { temporaryLinkReducer } from './temporaryLink';
import { errorReducer } from './error';
import { supportRecordDateReducer } from './supportRecordDate';
import { supportRecordYearAndMonthReducer } from './supportRecordYearMonth';
import { workRecordDateReducer } from './workRecordDate';
import { workRecordYearAndMonthReducer } from './workRecordYearMonth';
import { usageRecordYearAndMonthReducer } from './usageRecordYearMonth';
import { usageRecordDateReducer } from './usageRecordDate';
import { newWorkingHoursReducer } from './newWorkingHours';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { assessmentsReducer } from './assessments';
import { initReadReducer } from './initRead';
import { selectedOrganizationReducer } from './selectedOrganization';
import { typeBOrganizationReducer } from './typeBOrganization';
import { typeBBasicRewardReducer } from './typeBBasicReward';
import { typeBAddingRewardItemReducer } from './typeBAddingRewardItem';
import { typeBSubtractingRewardItemReducer } from './typeBSubtractingRewardItem';
import { typeBUsagesReducer } from './typeBUsages';
import { transferOrganizationReducer } from './transferOrganization';
import { transferBasicRewardReducer } from './transferBasicReward';
import { transferAddingRewardItemReducer } from './transferAddingRewardItem';
import { transferSubtractingRewardItemReducer } from './transferSubtractingRewardItem';
import { groupsReducer } from './groups';
import { newsReducer } from './news';
import { readNewsReducer } from './readNews';
import { transferUsagesReducer } from './transferUsages';
import { userPeriodReducer } from './userPeriod';
import { transferUsageRecordsReducer } from './transferUsageRecords';
import { typeBAddingRewardItem2024Reducer } from './typeBAddingRewardItem2024';
import { typeBSubtractingRewardItem2024Reducer } from './typeBSubtractingRewardItem2024';
import { basicReward2024Reducer } from './basicReward2024';
import { subtractingRewardItem2024Reducer } from './subtractingRewardItem2024';
import { addingRewardItem2024Reducer } from './addingRewardItem2024';
import { topAlertReducer } from './topAlert';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['selectedOrganization']
}

const reducer = combineReducers({
  logined:loginedReducer,
  memberData: memberDataReducer,
  members: membersReducer,
  roles: rolesReducer,
  userId: userIdReducer,
  usages: usagesReducer,
  profile: profileReducer,
  beneficiaryCertificate: beneficiaryCertificateReducer,
  usageRecords: usageRecordsReducer,
  supportRecords: supportRecordsReducer,
  workItems: workItemsReducer,
  organization: organizationReducer,
  businessHours: businessHoursReducer,
  workingHours: workingHoursReducer,
  basicReward: basicRewardReducer,
  basicReward2024: basicReward2024Reducer,
  subtractingRewardItem: subtractingRewardItemReducer,
  subtractingRewardItem2024: subtractingRewardItem2024Reducer,
  addingRewardItem: addingRewardItemReducer,
  addingRewardItem2024: addingRewardItem2024Reducer,
  invoices: invoicesReducer,
  users: usersReducer,
  workPlaceCompanies: workPlaceCompaniesReducer,
  businessLogs: businessLogsReducer,
  workRecords: workRecordsReducer,
  initialSetting: initialSettingReducer,
  interviewYearAndMonth: interviewYearAndMonthReducer,
  businessLogYearMonth: businessLogYearMonthReducer,
  stripeInfo: stripeInfoReducer,
  userYearMonth: userYearMonthReducer,
  userPeriod: userPeriodReducer,
  interviewRecords: interviewRecordsReducer,
  supportPlanReviews: supportPlanReviewsReducer,
  supportPlans: supportPlansReducer,
  assessments: assessmentsReducer,
  billing: billingReducer,
  temporaryLink: temporaryLinkReducer,
  error: errorReducer,
  workRecordDate :workRecordDateReducer,
  workRecordYearAndMonth :workRecordYearAndMonthReducer,
  usageRecordDate :usageRecordDateReducer,
  usageRecordYearAndMonth :usageRecordYearAndMonthReducer,
  supportRecordDate :supportRecordDateReducer,
  supportRecordYearMonth: supportRecordYearAndMonthReducer,
  selectedOrganization: selectedOrganizationReducer,
  typeBOrganization: typeBOrganizationReducer,
  typeBBasicReward: typeBBasicRewardReducer,
  typeBAddingRewardItem: typeBAddingRewardItemReducer,
  typeBAddingRewardItem2024: typeBAddingRewardItem2024Reducer,
  typeBSubtractingRewardItem: typeBSubtractingRewardItemReducer,
  typeBSubtractingRewardItem2024: typeBSubtractingRewardItem2024Reducer,
  typeBUsages: typeBUsagesReducer,
  transferOrganization: transferOrganizationReducer,
  transferBasicReward: transferBasicRewardReducer,
  transferAddingRewardItem: transferAddingRewardItemReducer,
  transferSubtractingRewardItem: transferSubtractingRewardItemReducer,
  transferUsageRecords: transferUsageRecordsReducer,
  newWorkingHours: newWorkingHoursReducer,
  groups: groupsReducer,
  initRead: initReadReducer,
  news: newsReducer,
  readNews: readNewsReducer,
  transferUsages: transferUsagesReducer,
  topAlert: topAlertReducer,
});
const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      // Redux-ToolkitにFirebaseのTimestampをいれるときのエラー処理
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, ],
    },
  }),
});
type RootState = ReturnType<typeof store.getState>;

export const useTypedSelector = createSelectorHook<RootState>();
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
