import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusinessLog } from 'common-types/firebase/firestore/organizations/businessLogs';

export type StateBusinessLog = Omit<BusinessLog, 'createAt' | 'updateAt' > & {createAtMillis: number,  updateAtMillis: number, businessLogId: string} 
export const initialState: StateBusinessLog[] = [{
  am: '',
  pm: '',
  works: [{
    userId: '',
    workIds: [],
  }],
  others: '',
  outOfFacilityWorking:[{
    workPlaceCompanyId: '',
    accompanyingStaff: [{
      staffId: '',
      time: 0
  }]
  }],
  recorderName: '',
  recorderId: '',
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: '',
  businessLogId: '',
  isDeleted: false,
}]
const businessLogsSlice = createSlice({
  name: 'businessLogs',
  initialState,

  reducers: {
    setBusinessLogs: (_state: StateBusinessLog[], action: PayloadAction<StateBusinessLog[]>) => {
      return action.payload;
    },
    resetBusinessLogs: () => {
      return initialState;
    },
    updateBusinessLogs: (state: StateBusinessLog[], action: PayloadAction<StateBusinessLog[]>) => {
      // すでにaction.payloadのBusinessLoagがstateに存在する場合は、stateを更新する
      // 存在しない場合は追加する
      const newState = state.map((businessLog) => {
        const targetBusinessLog = action.payload.find(
          (usageRecord) => usageRecord.businessLogId === businessLog.businessLogId
        );
        if (targetBusinessLog) {
          return targetBusinessLog;
        }
        return businessLog;
      });
      action.payload.forEach((businessLog) => {
        if (!state.find((stateBusinessLog) => stateBusinessLog.businessLogId === businessLog.businessLogId)) {
          newState.push(businessLog);
        }
      });
      return newState;
    },
    deleteBusinessLog: (state: StateBusinessLog[], action: PayloadAction<string>) => {
      return state.filter((businessLog) => businessLog.businessLogId !== action.payload);
    },
  }
});

export const { setBusinessLogs, resetBusinessLogs, updateBusinessLogs, deleteBusinessLog } = businessLogsSlice.actions;
export const businessLogsReducer = businessLogsSlice.reducer;