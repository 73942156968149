import { StateMember } from '../redux/memberData'
import { StateUser } from '../redux/users'
import { StateWorkItem } from '../redux/workItems';
export const setCreatedBy = (createdBy: string, memberId: string | null) => {
  if (!memberId) return '';
  return createdBy ? createdBy : memberId;
}

export const getMemberNameByMemberId = (userId: string, members: StateMember[]) => {
  const user = members.find(m=> m.memberId === userId);
  return user ? user.name : "-"
}

export const getUserNameByUserId = (userId: string, users: StateUser[]) => {
  const user = users.find(m=> m.userId === userId);
  return user ? user.nameKanji : "-"
}

export const getUserNameKanaByUserId = (userId: string, users: StateUser[]) => {
  const user = users.find(m=> m.userId === userId);
  return user ? user.nameKana : "-"
}

export const getUserPhotoByUserId = (userId: string, users: StateUser[]) => {
  const user = users.find(m=> m.userId === userId);
  return user ? user.photoUrl : ''
}

export const getWorkItemNameByWorkItemId = (workItemId: string, workItems: StateWorkItem[]) => {
  const workItem = workItems.find((doc) => doc.workItemId === workItemId);
  return workItem ? workItem.workItemName : '';
}

export const getBeneficiaryNumber = (userId: string, users: StateUser[]) => {
  const user = users.find(m=> m.userId === userId);
  return user ? user.beneficiaryNumber : ''
}
