import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddingRewardItem } from 'common-types/firebase/firestore/organizations/settings';

export type StateWelfareSpecialist = {
  placementType: number,
  startAtMillis: number | null,
  endAtMillis: number | null
};
export type StatePeriod = {
  startAtMillis: number | null,
  endAtMillis: number | null,
};

export type StateAddingRewardItem = Omit<AddingRewardItem,
  'welfareSpecialist' | 'instructorOfWageImprovement' | 'createAt' | 'updateAt'> &
{
  welfareSpecialist: StateWelfareSpecialist,
  instructorOfWageImprovement: StatePeriod,
  createAtMillis: number,
  updateAtMillis: number
};
const initialState: StateAddingRewardItem = {
  staffingPlacement: 7.5,
  welfareSpecialist: {
    placementType: 0,
    startAtMillis: 0,
    endAtMillis: 0
  },
  hasSupportOfDisabilities: false,
  supportOfEmploymentTransition: {
    hasSupport: false,
    lastYearsResult: 0
  },
  supportOfSevereDisabilities: 0,
  instructorOfWageImprovement: {
    startAtMillis: null,
    endAtMillis: null
  },
  improvementOfStaffTreatment: 0,
  isSupportFacilityForDesignatedDisability: false,
  improvementOfStaffSpecificTreatment: 0,
  baseUpSupportOfStaff: false,
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const addingRewardItemSlice = createSlice({
  name: 'addingRewardItem',
  initialState,
  reducers: {
    setAddingRewardItem: (_state: StateAddingRewardItem, action: PayloadAction<StateAddingRewardItem>) => {
      return action.payload;
    },
    resetAddingRewardItem: () => {
      return initialState;
    }
  }
});

export const { setAddingRewardItem, resetAddingRewardItem } = addingRewardItemSlice.actions;
export const addingRewardItemReducer = addingRewardItemSlice.reducer;