import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransferAddingRewardItem } from 'common-types/firebase/firestore/transferOrganization/settings';

export type StateWelfareSpecialist = {
  placementType: number,
  startAtMillis: number | null,
  endAtMillis: number | null
};
export type StatePeriod = {
  startAtMillis: number | null,
  endAtMillis: number | null,
};

export type StateTransferAddingRewardItem = Omit<TransferAddingRewardItem,
  'welfareSpecialist' | 'createAt' | 'updateAt'> &
{
  welfareSpecialist: StateWelfareSpecialist,
  createAtMillis: number,
  updateAtMillis: number
};
const initialState: StateTransferAddingRewardItem = {
  welfareSpecialist: {
    placementType: 0,
    startAtMillis: 0,
    endAtMillis: 0
  },
  hasSupportOfDisabilities: false,
  hasCompletedTransitionSupportTraining: false,
  mentalDisabilitiesDischargeSupport: 0,
  improvementOfStaffTreatment: 0,
  isSupportFacilityForDesignatedDisability: false,
  improvementOfStaffSpecificTreatment: 0,
  baseUpSupportOfStaff: false,
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const transferAddingRewardItemSlice = createSlice({
  name: 'transferAddingRewardItem',
  initialState,
  reducers: {
    setTransferAddingRewardItem: (_state: StateTransferAddingRewardItem, action: PayloadAction<StateTransferAddingRewardItem>) => {
      return action.payload;
    },
    resetTransferAddingRewardItem: () => {
      return initialState;
    }
  }
});

export const { setTransferAddingRewardItem, resetTransferAddingRewardItem } = transferAddingRewardItemSlice.actions;
export const transferAddingRewardItemReducer = transferAddingRewardItemSlice.reducer;
