import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const userIdSlice = createSlice({
  name: 'userId',
  initialState: '',
  reducers: {
    setUserId: (_state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
    resetUserId: () => {
      return '';
    }
  }
});

export const { setUserId, resetUserId } = userIdSlice.actions;
export const userIdReducer = userIdSlice.reducer;