import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StateMember } from './memberData';

const initialState:  Omit<StateMember, 'organizationId'>[] = [{
  organizationIds: [],
  roles: [{
    organizationId: '',
    role: '',
    serviceType: '',
    position: '',
  }],
  email: '',
  name: '',
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
  memberId: '',
}];

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    setMembers: (_state: StateMember[], action: PayloadAction<StateMember[]>) => {
      return action.payload;
    },
    resetMembers: () => {
      return initialState;
    }
  }
});

export const { setMembers, resetMembers } = membersSlice.actions;
export const membersReducer = membersSlice.reducer;
