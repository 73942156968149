import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeBSubtractingRewardItem2024 } from 'common-types/firebase/firestore/typeBOrganization/settings';

export type StateVacantPosition = {
  isVacancyOfStaff: boolean, 
  position: string, 
  startAtMillis: number
};

export type StateTypeBSubtractingRewardItem2024 = Omit<TypeBSubtractingRewardItem2024, 'vacantPosition' | 'createAt' | 'updateAt'> & { 
  vacantPosition: StateVacantPosition,
  createAtMillis: number, 
  updateAtMillis: number 
};
const initialState: StateTypeBSubtractingRewardItem2024 = {
  isInstalledByLocalGovernment: false,
  vacantPosition: {
    isVacancyOfStaff: false,
    position: '',
    startAtMillis: 0,
  },
  isNotAbolishedPhysicalRestraint: false,
  isDisabledSupportFacilities: false,
  isNoAbusePrevention: false,
  isNoBusinessContinuityPlan: false,
  isNoInformationDisclosured: false,
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const typeBSubtractingRewardItem2024Slice = createSlice({
  name: 'typeBSubtractingRewardItem2024',
  initialState,
  reducers: {
    setTypeBSubtractingRewardItem2024: (_state: StateTypeBSubtractingRewardItem2024, action: PayloadAction<StateTypeBSubtractingRewardItem2024>) => {
      return action.payload;
    },
    resetTypeBSubtractingRewardItem2024: () => {
      return initialState;
    }
  }
});

export const { setTypeBSubtractingRewardItem2024, resetTypeBSubtractingRewardItem2024 } = typeBSubtractingRewardItem2024Slice.actions;
export const typeBSubtractingRewardItem2024Reducer = typeBSubtractingRewardItem2024Slice.reducer;