import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasicReward2024 } from 'common-types/firebase/firestore/organizations/settings';

export type StateBasicReward2024 = Omit<BasicReward2024, 'createAt' | 'updateAt'> & {
  createAtMillis: number, updateAtMillis: number
}
const initialState: StateBasicReward2024 = {
  scoreOfAverageWorkingHours: 5,
  scoreOfProductionActivity: -20,
  scoreOfWorkStyle: 0,
  scoreOfImprovementSupport: 0,
  scoreOfLocalCooperationActivity: 0,
  scoreOfUserAbilityImprovement: 0,
  scoreOfManagementImprovementPlan: 0,
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const basicReward2024Slice = createSlice({
  name: 'basicReward2024',
  initialState,
  reducers: {
    setBasicReward2024: (_state: StateBasicReward2024, action: PayloadAction<StateBasicReward2024>) => {
      return action.payload;
    },
    resetBasicReward2024: () => {
      return initialState;
    }
  }
});

export const { setBasicReward2024, resetBasicReward2024 } = basicReward2024Slice.actions;
export const basicReward2024Reducer = basicReward2024Slice.reducer;
