import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkRecord } from 'common-types/firebase/firestore/organizations/workRecords';

export type StateWorkRecord = Omit<WorkRecord,
  'attendanceRecord' |
  'createAt' |
  'updateAt'
  > &
  { attendanceRecord: {
    startAtMillis: number,
    endAtMillis: number | null
  },
  createAtMillis: number,
  updateAtMillis: number,
  workRecordId: string
};
const initialState: StateWorkRecord[] = [{
  userId: '',
  usageRecordId: '',
  attendanceRecord: {
    startAtMillis: 0,
    endAtMillis: null
  },
  serviceProvisionStatus: '',
  workIds: [],
  recorderId: '',
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: '',
  isDeleted: false,
  workRecordId: ''
}];

const workRecordsSlice = createSlice({
  name: 'workRecords',
  initialState,
  reducers: {
    setWorkRecords: (_state: StateWorkRecord[], action: PayloadAction<StateWorkRecord[]>) => {
      return action.payload;
    },
    resetWorkRecords: () => {
      return [];
    },
    updateWorkRecords: (state: StateWorkRecord[], action: PayloadAction<StateWorkRecord[]>) => {
      // すでにaction.payloadのuupdateWorkRecordsがstateに存在する場合は、stateを更新する
      // 存在しない場合は追加する
      const newState = state.map((workRecord) => {
        const targetWorkRecord = action.payload.find(
          (usageRecord) => usageRecord.workRecordId === workRecord.workRecordId
        );
        if (targetWorkRecord) {
          return targetWorkRecord;
        } else {
          return workRecord;
        }
      });
      action.payload.forEach((workRecord) => {
        if (!state.some((usageRecord) => usageRecord.workRecordId === workRecord.workRecordId)) {
          newState.push(workRecord);
        }
      }
      );
      return newState;
    },
    deleteWorkRecord: (state: StateWorkRecord[], action: PayloadAction<string>) => {
      return state.filter((workRecord) => workRecord.workRecordId !== action.payload);
    },
  }
});

export const { setWorkRecords, resetWorkRecords, updateWorkRecords, deleteWorkRecord } = workRecordsSlice.actions;
export const workRecordsReducer = workRecordsSlice.reducer;
