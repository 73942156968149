import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from '../lib/dayjs';

const usageRecordYearAndMonthSlice = createSlice({
  name: 'usageRecordYearAndMonth',
  initialState: dayjs().format('YYYY-MM'),
  reducers: {
    setUsageRecordYearAndMonthSlice: (_state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
    resetUsageRecordYearAndMonthSlice: () => {
      return '';
    }
  }
});

export const { setUsageRecordYearAndMonthSlice, resetUsageRecordYearAndMonthSlice } = usageRecordYearAndMonthSlice.actions;
export const usageRecordYearAndMonthReducer = usageRecordYearAndMonthSlice.reducer;
