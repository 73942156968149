import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from '../lib/dayjs';

const userYearMonthSlice = createSlice({
  name: 'userYearMonth',
  initialState: dayjs().format('YYYY-MM'),
  reducers: {
    setUserYearMonth: (_state: string, action: PayloadAction<string>) => {
      return action.payload;
    },
    resetUserYearMonth: () => {
      return '';
    }
  }
});

export const { setUserYearMonth, resetUserYearMonth } = userYearMonthSlice.actions;
export const userYearMonthReducer = userYearMonthSlice.reducer;
