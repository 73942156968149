const firebaseConfigProd = {
  apiKey: "AIzaSyCyg87-UD0Z-xzY7k0s7HtTdWAFnpCNIGc",
  authDomain: "coala-prod.firebaseapp.com",
  projectId: "coala-prod",
  storageBucket: "coala-prod.appspot.com",
  messagingSenderId: "61322356456",
  appId: "1:61322356456:web:a6a6a93401ca97d9450b5d",
  measurementId: "G-MELCBB7XC3"
};

const firebaseConfigStg = {
  apiKey: "AIzaSyBY_77Sv0FMIdHDOhhRqvV0SSRkeLJoUXg",
  authDomain: "coala-stg.firebaseapp.com",
  projectId: "coala-stg",
  storageBucket: "coala-stg.appspot.com",
  messagingSenderId: "1038658848206",
  appId: "1:1038658848206:web:6ce32cda63e43caec8179f",
  measurementId: "G-2YK81ZP28Q"
};
const firebaseConfigFeat = {
  apiKey: "AIzaSyBrdGvwBZPwWmbS973GTtnVKcTMXQLu-M4",
  authDomain: "rakuco-feat.firebaseapp.com",
  projectId: "rakuco-feat",
  storageBucket: "rakuco-feat.appspot.com",
  messagingSenderId: "955846324506",
  appId: "1:955846324506:web:ca39dc032e12edab0a3ca3",
  measurementId: "G-0DNTM1WW97"
};

const getFirebaseConfig = () => {
  switch (process.env.REACT_APP_ENV) {
    case "production":
      return firebaseConfigProd;
    case "feature":
      return firebaseConfigFeat;
    case "staging":
      return firebaseConfigStg;
    default:
      return firebaseConfigStg
  }
}

export const firebaseConfig = getFirebaseConfig()

 export const stripeApiKey =
 process.env.REACT_APP_ENV === 'production'
   ? process.env.REACT_APP_STRIPE_PK_LIVE
   : process.env.REACT_APP_STRIPE_PK_TEST;

export const formUrl = 'https://form.run/@rakuco-contact';
export const manualUrl = 'https://heady-acai-c8b.notion.site/Rakuco-89227c85987d45b48552aa03611e68af?pvs=4';
export const termsUrl = 'https://firebasestorage.googleapis.com/v0/b/coala-prod.appspot.com/o/public%2F20211029_%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84.pdf?alt=media&token=a8864579-2883-4e8e-8e66-69b7a6b148e6';
export const privacyPolicyUrl = 'https://firebasestorage.googleapis.com/v0/b/coala-prod.appspot.com/o/public%2F20211009_privacy.pdf?alt=media&token=f8bb8446-7ca5-4d3a-9529-c831eabaefe3';
export const csvTemplateUrl = 'https://firebasestorage.googleapis.com/v0/b/coala-prod.appspot.com/o/public%2F%E4%BD%9C%E6%A5%AD%E6%99%82%E9%96%93%E3%82%A4%E3%83%B3%E3%83%9B%E3%82%9A%E3%83%BC%E3%83%88%E7%94%A8%E3%83%86%E3%83%B3%E3%83%95%E3%82%9A%E3%83%AC%E3%83%BC%E3%83%88_202307.csv?alt=media&token=2649c9c8-900b-4292-baea-1941e273605b';
export const csvGuideUrl = 'https://heady-acai-c8b.notion.site/d067a12e6b0443c089a4bd1958196c65';
export const userCsvUploadGuideUrl = 'https://heady-acai-c8b.notion.site/006fc4cef3d74ff99b7b85193e723c11'

