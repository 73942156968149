import { Message } from "../redux/error";
import { StateSupportRecord } from "../redux/supportRecords";
import dayjs from "../lib/dayjs"

export const checkSupportRecord = (supportRecord: StateSupportRecord, userName: string) => {
  let messages: Message[] = [];
  if (
    supportRecord.serviceProvisionStatus === "supportForAbsence" &&
    !supportRecord.support &&
    // 当日ではない、または当日であれば20時以降
    (!dayjs().isSame(supportRecord.attendanceRecord.startAtMillis, "day") ||
      (dayjs().isSame(supportRecord.attendanceRecord.startAtMillis, "day") &&
        dayjs().hour() >= 20))
  ) {
    messages.push({
      type: "error",
      text: `欠席時対応の場合、欠席理由・支援内容を入力してください。（${userName}さん, ${dayjs(
        supportRecord.attendanceRecord.startAtMillis
      ).format("YYYY年M月D日")}）`,
      url: `supportRecords?tab=user&userId=${
        supportRecord.userId
      }&month=${dayjs(supportRecord?.attendanceRecord.startAtMillis).format(
        "YYYY/MM"
      )}`,
    });
  }
  if (supportRecord.interview){
    if (
      supportRecord.attendanceRecord.endAtMillis &&
      supportRecord.interviewRecord.startAtMillis &&
      supportRecord.interviewRecord.endAtMillis &&
      (
        supportRecord.attendanceRecord.startAtMillis > supportRecord.interviewRecord.startAtMillis
      )
    ) {
      messages = [
        ...messages,
        {
          type: "error",
          text: `面談の開始時間は利用時間の開始時間より後に設定してください。（${userName}さん, ${dayjs(
            supportRecord.attendanceRecord.startAtMillis
          ).format("YYYY年M月D日")}）`,
          url: `supportRecords?tab=user&userId=${
            supportRecord.userId
          }&month=${dayjs(supportRecord?.attendanceRecord.startAtMillis).format(
            "YYYY/MM"
          )}`,
        },
      ];
    }
    if (
      supportRecord.attendanceRecord.endAtMillis &&
      supportRecord.interviewRecord.startAtMillis &&
      supportRecord.interviewRecord.endAtMillis &&
      supportRecord.attendanceRecord.endAtMillis <
        supportRecord.interviewRecord.endAtMillis
    ) {
      messages = [
        ...messages,
        {
          type: "error",
          text: `面談の終了時間は利用時間の終了時間より前に設定してください。（${userName}さん, ${dayjs(
            supportRecord.attendanceRecord.startAtMillis
          ).format("YYYY年M月D日")}）`,
          url: `supportRecords?tab=user&userId=${
            supportRecord.userId
          }&month=${dayjs(supportRecord?.attendanceRecord.startAtMillis).format(
            "YYYY/MM"
          )}`,
        },
      ];
    }    
  }
  return messages;
};
