import firebase from 'firebase/app';
import { firestore, DocumentReference } from '../../../index';
import { BusinessHours, BasicReward, SubtractingRewardItem, AddingRewardItem, InitialSetting, BasicReward2024, AddingRewardItem2024, SubtractingRewardItem2024 } from 'common-types/firebase/firestore/organizations/settings';
import { StateInitialSetting } from '../../../../redux/initialSetting';
import { StateBusinessHours } from '../../../../redux/businessHours';
import { StateNewWorkingHour } from '../../../../redux/newWorkingHours';
import { StateBasicReward } from '../../../../redux/basicReward';
import { StateAddingRewardItem } from '../../../../redux/addingRewardItem';
import { StateSubtractingRewardItem } from '../../../../redux/subtractingRewardItem';
import { NewWorkingHours } from 'common-types/firebase/firestore/organizations/settings';
import { ServiceType } from 'common-types/firebase/firestore/organizations';
import { getSelectedOrganization } from '../../../../utils/getSelectedOrganization';
import { StateBasicReward2024 } from '../../../../redux/basicReward2024';
import { StateAddingRewardItem2024 } from '../../../../redux/addingRewardItem2024';
import { StateSubtractingRewardItem2024 } from '../../../../redux/subtractingRewardItem2024';

export const workingHoursRef =(
  organizationId: string,
  serviceType: ServiceType
) => {
  const selectedOrganization = getSelectedOrganization(serviceType);
  return firestore
    .collection(selectedOrganization)
    .doc(organizationId)
    .collection('settings')
    .doc('workingHours')
    .collection('docs');
}

export const setFirestoreBusinessHours = async (
  organizationId:string,
  doc: StateBusinessHours,
  serviceType: ServiceType
) => {
  const businessHours = setFirebaseBusinessHoursDoc(doc);
  const selectedOrganization = getSelectedOrganization(serviceType);
  const businessHoursRef: DocumentReference = await firestore
    .collection(selectedOrganization)
    .doc(organizationId)
    .collection('settings')
    .doc('businessHours');
  return await businessHoursRef.set(businessHours, { merge: true });
};

const setFirebaseBusinessHoursDoc = (doc: StateBusinessHours): BusinessHours => {
  const { createAtMillis, updateAtMillis, ...params } = doc;
  return {
    ...params,
    createAt: createAtMillis ? firebase.firestore.Timestamp.fromMillis(createAtMillis) : firebase.firestore.Timestamp.now(),
    updateAt: firebase.firestore.Timestamp.now()
  }
}

export const setFirestoreWorkingHours = async (
  organizationId: string,
  workingHours: StateNewWorkingHour[],
  serviceType: ServiceType
) => {
  workingHours.forEach(async(doc)=> {
    const workingHour = setFirebaseWorkingHoursDoc(doc);
    const { id } = doc;
    if (id) {
      return await workingHoursRef(organizationId, serviceType).doc(id).set(workingHour, { merge: true });
    }
    await workingHoursRef(organizationId, serviceType).add(workingHour);
  })
};

export const deleteFirestoreWorkingHours = async (
  ids: string[],
  organizationId: string,
  serviceType: ServiceType
) => {
  ids.forEach(async id=>{
    await workingHoursRef(organizationId, serviceType).doc(id).delete();
  })
}

const setFirebaseWorkingHoursDoc = (doc: StateNewWorkingHour): NewWorkingHours => {
  const {
    unitTime,
    createAtMillis, updateAtMillis,
    id,
    isSetSundaySetting,
    isSetMondaySetting,
    isSetTuesdaySetting,
    isSetWednesdaySetting,
    isSetThursdaySetting,
    isSetFridaySetting,
    isSetSaturdaySetting,
    sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    ...params } = doc;
  return {
    ...params,
    unitTime: Number(unitTime),
    createAt: createAtMillis
      ? firebase.firestore.Timestamp.fromMillis(createAtMillis)
      : firebase.firestore.Timestamp.now(),
    updateAt: firebase.firestore.Timestamp.now(),
    sunday: isSetSundaySetting && sunday ? sunday : null,
    monday: isSetMondaySetting && monday ? monday : null,
    tuesday: isSetTuesdaySetting && tuesday ? tuesday : null,
    wednesday: isSetWednesdaySetting && wednesday ? wednesday : null,
    thursday: isSetThursdaySetting && thursday ? thursday : null,
    friday: isSetFridaySetting && friday ? friday : null,
    saturday: isSetSaturdaySetting && saturday ? saturday : null,
    isSetSundaySetting,
    isSetMondaySetting,
    isSetTuesdaySetting,
    isSetWednesdaySetting,
    isSetThursdaySetting,
    isSetFridaySetting,
    isSetSaturdaySetting,
  };
}

export const setFirestoreBasicReward = async (
  organizationId: string,
  doc: StateBasicReward,
  memberId: string
) => {
  const basicReward = setFirebaseBasicRewardDoc(doc, memberId);
  const basicRewardRef: DocumentReference = await firestore
    .collection('organizations')
    .doc(organizationId)
    .collection('settings')
    .doc('basicReward');
  return await basicRewardRef.set(basicReward, { merge: true });
};

const setFirebaseBasicRewardDoc = (doc: StateBasicReward, memberId: string): BasicReward => {
  const { createAtMillis, updateAtMillis, ...params } = doc;
  return {
    ...params,
    createdBy: params.createdBy ? params.createdBy : memberId,
    updatedBy: memberId,
    createAt: createAtMillis ? firebase.firestore.Timestamp.fromMillis(createAtMillis) : firebase.firestore.Timestamp.now(),
    updateAt: firebase.firestore.Timestamp.now(),
  }
}

export const setFirestoreBasicReward2024 = async (
  organizationId: string,
  doc: StateBasicReward2024,
  memberId: string
) => {
  const basicReward = setFirebaseBasicReward2024Doc(doc, memberId);
  const basicRewardRef: DocumentReference = await firestore
    .collection('organizations')
    .doc(organizationId)
    .collection('settings')
    .doc('basicReward2024');
  return await basicRewardRef.set(basicReward, { merge: true });
};

const setFirebaseBasicReward2024Doc = (doc: StateBasicReward2024, memberId: string): BasicReward2024 => {
  const { createAtMillis, updateAtMillis, ...params } = doc;
  return {
    ...params,
    createdBy: params.createdBy ? params.createdBy : memberId,
    updatedBy: memberId,
    createAt: createAtMillis ? firebase.firestore.Timestamp.fromMillis(createAtMillis) : firebase.firestore.Timestamp.now(),
    updateAt: firebase.firestore.Timestamp.now(),
  }
}

export const setFirestoreAddingRewardItem = async (
  organizationId: string,
  doc: StateAddingRewardItem,
  memberId: string
) => {
  const addingRewardItem = setFirebaseAddingRewardItemDoc(doc, memberId);
  const addingRewardItemRef: DocumentReference = await firestore
    .collection('organizations')
    .doc(organizationId)
    .collection('settings')
    .doc('addingRewardItem');
  return await addingRewardItemRef.set(addingRewardItem, { merge: true });
};

const setFirebaseAddingRewardItemDoc = (doc: StateAddingRewardItem, memberId: string): AddingRewardItem => {
  const { welfareSpecialist, instructorOfWageImprovement, supportOfEmploymentTransition, createAtMillis, updateAtMillis, ...params } = doc;
  return {
    ...params,
    welfareSpecialist: {
      placementType: welfareSpecialist.placementType,
      startAt: welfareSpecialist.startAtMillis ? firebase.firestore.Timestamp.fromMillis(welfareSpecialist.startAtMillis) : null,
      endAt: welfareSpecialist.endAtMillis ? firebase.firestore.Timestamp.fromMillis(welfareSpecialist.endAtMillis) : null
    },
    instructorOfWageImprovement: {
      startAt: instructorOfWageImprovement.startAtMillis ? firebase.firestore.Timestamp.fromMillis(instructorOfWageImprovement.startAtMillis) : null,
      endAt: instructorOfWageImprovement.endAtMillis ? firebase.firestore.Timestamp.fromMillis(instructorOfWageImprovement.endAtMillis) : null
    },
    supportOfEmploymentTransition : {
      hasSupport: supportOfEmploymentTransition.hasSupport,
      lastYearsResult: Number(supportOfEmploymentTransition.lastYearsResult)
    },
    createdBy: params.createdBy ? params.createdBy : memberId,
    updatedBy: memberId,
    createAt: createAtMillis ? firebase.firestore.Timestamp.fromMillis(createAtMillis) : firebase.firestore.Timestamp.now(),
    updateAt: firebase.firestore.Timestamp.now(),
  }
}

export const setFirestoreAddingRewardItem2024 = async (
  organizationId: string,
  doc: StateAddingRewardItem2024,
  memberId: string
) => {
  const addingRewardItem = setFirebaseAddingRewardItem2024Doc(doc, memberId);
  const addingRewardItemRef: DocumentReference = await firestore
    .collection('organizations')
    .doc(organizationId)
    .collection('settings')
    .doc('addingRewardItem2024');
  return await addingRewardItemRef.set(addingRewardItem, { merge: true });
};

const setFirebaseAddingRewardItem2024Doc = (doc: StateAddingRewardItem2024, memberId: string): AddingRewardItem2024 => {
  const { welfareSpecialist, instructorOfWageImprovement, supportOfEmploymentTransition, createAtMillis, updateAtMillis, ...params } = doc;
  return {
    ...params,
    welfareSpecialist: {
      placementType: welfareSpecialist.placementType,
      startAt: welfareSpecialist.startAtMillis ? firebase.firestore.Timestamp.fromMillis(welfareSpecialist.startAtMillis) : null,
      endAt: welfareSpecialist.endAtMillis ? firebase.firestore.Timestamp.fromMillis(welfareSpecialist.endAtMillis) : null
    },
    instructorOfWageImprovement: {
      startAt: instructorOfWageImprovement.startAtMillis ? firebase.firestore.Timestamp.fromMillis(instructorOfWageImprovement.startAtMillis) : null,
      endAt: instructorOfWageImprovement.endAtMillis ? firebase.firestore.Timestamp.fromMillis(instructorOfWageImprovement.endAtMillis) : null
    },
    supportOfEmploymentTransition : {
      hasSupport: supportOfEmploymentTransition.hasSupport,
      lastYearsResult: Number(supportOfEmploymentTransition.lastYearsResult)
    },
    createdBy: params.createdBy ? params.createdBy : memberId,
    updatedBy: memberId,
    createAt: createAtMillis ? firebase.firestore.Timestamp.fromMillis(createAtMillis) : firebase.firestore.Timestamp.now(),
    updateAt: firebase.firestore.Timestamp.now(),
  }
}

export const setFirestoreSubtractingRewardItem = async (
  organizationId: string,
  doc: StateSubtractingRewardItem,
  memberId: string
) => {
  const subtractingRewardItem = setFirebaseSubtractingRewardItemDoc(doc, memberId);
  const subtractingRewardItemRef: DocumentReference = await firestore
    .collection('organizations')
    .doc(organizationId)
    .collection('settings')
    .doc('subtractingRewardItem');
  return await subtractingRewardItemRef.set(subtractingRewardItem, { merge: true });
};

const setFirebaseSubtractingRewardItemDoc = (doc: StateSubtractingRewardItem, memberId: string): SubtractingRewardItem => {
  const { vacantPosition, createAtMillis, updateAtMillis, ...params } = doc;
  return {
    ...params,
    vacantPosition: {
      isVacancyOfStaff: vacantPosition.isVacancyOfStaff,
      position: vacantPosition.position,
      startAt: firebase.firestore.Timestamp.fromMillis(vacantPosition.startAtMillis),
    },
    createdBy: params.createdBy ? params.createdBy : memberId,
    updatedBy: memberId,
    createAt: createAtMillis ? firebase.firestore.Timestamp.fromMillis(createAtMillis) : firebase.firestore.Timestamp.now(),
    updateAt: firebase.firestore.Timestamp.now(),
  }
}

export const setFirestoreSubtractingRewardItem2024 = async (
  organizationId: string,
  doc: StateSubtractingRewardItem2024,
  memberId: string
) => {
  const subtractingRewardItem = setFirebaseSubtractingRewardItem2024Doc(doc, memberId);
  const subtractingRewardItemRef: DocumentReference = await firestore
    .collection('organizations')
    .doc(organizationId)
    .collection('settings')
    .doc('subtractingRewardItem2024');
  return await subtractingRewardItemRef.set(subtractingRewardItem, { merge: true });
};

const setFirebaseSubtractingRewardItem2024Doc = (doc: StateSubtractingRewardItem2024, memberId: string): SubtractingRewardItem2024 => {
  const { vacantPosition, createAtMillis, updateAtMillis, ...params } = doc;
  return {
    ...params,
    vacantPosition: {
      isVacancyOfStaff: vacantPosition.isVacancyOfStaff,
      position: vacantPosition.position,
      startAt: firebase.firestore.Timestamp.fromMillis(vacantPosition.startAtMillis),
    },
    createdBy: params.createdBy ? params.createdBy : memberId,
    updatedBy: memberId,
    createAt: createAtMillis ? firebase.firestore.Timestamp.fromMillis(createAtMillis) : firebase.firestore.Timestamp.now(),
    updateAt: firebase.firestore.Timestamp.now(),
  }
}


export const setFirestoreInitialSetting = async (
  organizationId: string,
  initialSettingState: StateInitialSetting,
  memberId: string,
  serviceType: ServiceType,
) => {
  const selectedOrganization = getSelectedOrganization(serviceType);
  const initialSettingData = setInitialSettingStateToInitialSettingData(initialSettingState, memberId);
  const initialSettingRef: firebase.firestore.DocumentReference = await firestore
    .collection(selectedOrganization)
    .doc(organizationId)
    .collection('settings')
    .doc('initialSetting');
  return await initialSettingRef.set(initialSettingData, { merge: true });
};

const setInitialSettingStateToInitialSettingData = (data:StateInitialSetting, memberId: string)  => {
  const {
    createAtMillis,
    updateAtMillis,
    initialClaimWork,
    numberOfUsage,
    openDays,
    ...params
  } = data;
  return {
    ...params,
    initialClaimWork: {
      startAt: firebase.firestore.Timestamp.fromMillis(data.initialClaimWork.startAtMillis),
    },
    numberOfUsage : {
      oneMonthAgo: Number(numberOfUsage.oneMonthAgo),
      twoMonthAgo: Number(numberOfUsage.twoMonthAgo),
      threeMonthAgo: Number(numberOfUsage.threeMonthAgo)
    },
    openDays : {
      oneMonthAgo: Number(openDays.oneMonthAgo),
      twoMonthAgo: Number(openDays.twoMonthAgo),
      threeMonthAgo: Number(openDays.threeMonthAgo)
    },
    createAt: data.createAtMillis ? firebase.firestore.Timestamp.fromMillis(data.createAtMillis) : firebase.firestore.Timestamp.now(),
    updateAt: firebase.firestore.Timestamp.now(),
    createdBy: data.createdBy ? data.createdBy : memberId,
    updatedBy: memberId,
  } as InitialSetting;
}
