import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusinessHours } from 'common-types/firebase/firestore/organizations/settings';

export type StateBusinessHours = Omit<BusinessHours, 'createAt' | 'updateAt'> &
{
  createAtMillis: number,
  updateAtMillis: number,
  createdBy: string,
  updatedBy: string,
};
const initialState: StateBusinessHours = {
  sunday: {
    isOpened: false,
    startTime: '00:00',
    endTime: '00:00'
  },
  monday: {
    isOpened: false,
    startTime: '00:00',
    endTime: '00:00'
  },
  tuesday: {
    isOpened: false,
    startTime: '00:00',
    endTime: '00:00'
  },
  wednesday: {
    isOpened: false,
    startTime: '00:00',
    endTime: '00:00'
  },
  thursday: {
    isOpened: false,
    startTime: '00:00',
    endTime: '00:00'
  },
  friday: {
    isOpened: false,
    startTime: '00:00',
    endTime: '00:00'
  },
  saturday: {
    isOpened: false,
    startTime: '00:00',
    endTime: '00:00'
  },
  createAtMillis: 0,
  updateAtMillis: 0,
  createdBy: '',
  updatedBy: ''
};

const businessHoursSlice = createSlice({
  name: 'businessHours',
  initialState,
  reducers: {
    setBusinessHours: (_state: StateBusinessHours, action: PayloadAction<StateBusinessHours>) => {
      return action.payload;
    },
    resetBusinessHours: () => {
      return initialState;
    }
  }
});

export const { setBusinessHours, resetBusinessHours } = businessHoursSlice.actions;
export const businessHoursReducer = businessHoursSlice.reducer;